// ------------ A C U E R D O S    I N E ------------


export const dataAcuerdosINE = {
  2024: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      numDoc: "INE/CG27/2024",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBAN LAS CONVOCATORIAS PARA LA SELECCIÓN Y DESIGNACIÓN DE LA CONSEJERA PRESIDENTA DEL OPL DE CHIAPAS ASÍ COMO DE LAS CONSEJERAS O CONSEJEROS ELECTORALES DE LOS OPL DE LAS ENTIDADES DE BAJA CALIFORNIA SUR, CAMPECHE, CIUDAD DE MÉXICO, COLIMA, ESTADO DE MÉXICO, GUANAJUATO, GUERRERO, JALISCO, MICHOACAN, MORELOS, NUEVO LEÓN, OAXACA, QUERÉTARO, SAN LUIS POTOSÍ, SONORA, TABASCO, TLAXCALA, VERACRUZ, YUCATAN Y ZACATECAS",
      titleAnexoPDF1: "Punto 4 Convocatoria Baja California Sur 2024",
      titleAnexoPDF2: "Punto 4 Convocatoria Campeche 2024",
      titleAnexoPDF3: "Punto 4 Convocatoria CDMX 2024",
      titleAnexoPDF4: "Punto 4 Convocatoria Chiapas 2024",
      titleAnexoPDF5: "Punto 4 Convocatoria Colima 2024",
      titleAnexoPDF6: "Punto 4 Convocatoria Estado de México 2024",
      titleAnexoPDF7: "Punto 4 Convocatoria Guanajuato 2024",
      titleAnexoPDF8: "Punto 4 Convocatoria Guerrero 2024",
      titleAnexoPDF9: "Punto 4 Convocatoria Jalisco 2024",
      titleAnexoPDF10: "Punto 4 Convocatoria Michoacán 2024",
      titleAnexoPDF11: "Punto 4 Convocatoria Morelos 2024",
      titleAnexoPDF12: "Punto 4 Convocatoria Nuevo León 2024",
      titleAnexoPDF13: "Punto 4 Convocatoria Oaxaca 2024",
      titleAnexoPDF14: "Punto 4 Convocatoria Querétaro 2024",
      titleAnexoPDF15: "Punto 4 Convocatoria San Luis Potosí 2024",
      titleAnexoPDF16: "Punto 4 Convocatoria Sonora 2024",
      titleAnexoPDF17: "Punto 4 Convocatoria Tabasco 2024",
      titleAnexoPDF18: "Punto 4 Convocatoria Tlaxcala 2024",
      titleAnexoPDF19: "Punto 4 Convocatoria Veracruz 2024",
      titleAnexoPDF20: "Punto 4 Convocatoria Yucatán 2024",
      titleAnexoPDF21: "Punto 4 Convocatoria Zacatecas 2024",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      numDoc: "A09/INE/TLAX/JD03/13-05-2024",
      nameDoc: "SE APRUEBAN LOS AJUSTES A LA UBICACIÓN DE CASILLA ELECTORAL",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      numDoc: "INE/CG2243/2024",
      nameDoc:
        "DESIGNACION DE LAS PRESIDENCIAS  DE LOS OPL DE CAMPECHE Y CHIAPAS Y DESIGNACION DE CONSEJERÍAS DE LOS OPL DE BAJA CALIFORNIA SUR, CAMPECHE, CIUDAD DE MÉXICO, COLIMA, ESTADO DE MÉXICO, GUANAJUATO, GUERRERO, JALISCO, MICHOACÁN, MORELOS, NUEVO LEÓN, OAXACA, QUERÉTARO, SAN LUIS POTOSÍ, SONORA, TABASCO, TLAXCALA, VERACRUZ, YUCATÁN Y ZACATECAS",
      titleAnexoPDF1: "DICTAMEN BCS 2024",
      titleAnexoPDF2: "DICTAMEN CAM 2024",
      titleAnexoPDF3: "DICTAMEN CDMX 2024",
      titleAnexoPDF4: "DICTAMEN CHIA 2024",
      titleAnexoPDF5: "DICTAMEN COL 2024",
      titleAnexoPDF6: "DICTAMEN GRO 2024",
      titleAnexoPDF7: "DICTAMEN GTO 2024",
      titleAnexoPDF8: "DICTAMEN JAL 2024",
      titleAnexoPDF9: "DICTAMEN MEX 2024",
      titleAnexoPDF10: "DICTAMEN MICH 2024",
      titleAnexoPDF11: "DICTAMEN MOR 2024",
      titleAnexoPDF12: "DICTAMEN NL 2024",
      titleAnexoPDF13: "DICTAMEN OAX 2024",
      titleAnexoPDF14: "DICTAMEN QRO 2024",
      titleAnexoPDF15: "DICTAMEN SLP 2024",
      titleAnexoPDF16: "DICTAMEN SON 2024",
      titleAnexoPDF17: "DICTAMEN TAB 2024",
      titleAnexoPDF18: "DICTAMEN TLAX 2024",
      titleAnexoPDF19: "DICTAMEN VER 2024",
      titleAnexoPDF20: "DICTAMEN YUC 2024",
      titleAnexoPDF21: "DICTAMEN ZAC 2024",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      numDoc: "INE/CG2267/2024",
      nameDoc:
        "PLAN INTEGRAL Y CALENDARIO DE COORDINACIÓN PARA LOS PELE 2024 EN LOS ESTADOS DE CHIHUAHUA, HIDALGO, MICHOACÁN, TLACALA Y YUCATÁN",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      numDoc: "INE/CG2269/2024",
      nameDoc:
        "SE ORDENA LA PUBLICACIÓN DEL CATÁLOGO DE EMISORAS PARA EL PEE Y SE MODIFICAN LOS ACUERDOS INE/JGE75/2024 E INE/ACRT/30/2024",
      titleAnexoEXCEL1: "Anexo 1 Catálogo PEX San Lucas Tecopilco Tlaxcala 2024",
      titleAnexoEXCEL2: "Modelo de autoridades PEX Tlaxcala 2024",
      titleAnexoEXCEL3: "1 Precampaña PEX TLAXCALA 2024",
      titleAnexoEXCEL4: "2 Intercampaña PEX TLAXCALA 2024",
      titleAnexoEXCEL5: "A 3.1 Campaña PEX TLAXCALA 2024-0 CI",
      titleAnexoEXCEL6: "B 3.2 Campaña PEX TLAXCALA 2024-1 CI",
      titleAnexoEXCEL7: "C 3.3 Campaña PEX TLAXCALA 2024-2 CI",
      titleAnexoEXCEL8: "TLAX 24 01 PEX A Radio",
      titleAnexoEXCEL9: "TLAX 24 01 PEX A Televisión",
      titleAnexoEXCEL10: "TLAX 24 01 PEX B Radio",
      titleAnexoEXCEL11: "TLAX 24 01 PEX B Televisión",
      titleAnexoEXCEL12: "TLAX 24 01 PEX C Radio",
      titleAnexoEXCEL13: "TLAX 24 01 PEX C Televisión",
    },
  ],

  2023: [
    {
      id: 1,
      numDoc: "ACUERDO INE CG-03-2023",
      nameDoc:
        "POR EL QUE SE APRUEBA LA DESIGNACIÓN DEL CONSEJERO PRESIDENTE DEL OPL DE TLAXCALA",
      link: "1",
      titleAnexoPDF1: "ANEXO DICTAMEN TLAX 2022",
    },
    {
      id: 2,
      numDoc: " RESOLUCIÓN INE CG-439-2023",
      nameDoc:
        "Aprueba ejercer la facultad de atracción para fechas homologadas para conclusión del periodo de precampañas y recabar apoyo de CI 2023-2024",
      link: "2",
    },
    {
      id: 3,
      numDoc: "ACUERDO INE CG-437-2023",
      nameDoc:
        "CONVOCATORIAS A CIUDADANÍA PARA OBSERVADORA ELECTORAL PEC 2023-2024 Y PEE QUE DERIVEN, Y ANEXOS",
      link: "3",
      titleAnexoPDF1: "CONVOCATORIA INE",
      titleAnexoPDF2: "CONVOCATORIA OPLE",
      titleAnexoPDF3: "ANEXO 1",
      titleAnexoPDF4: "ANEXO 2",
      titleAnexoPDF5: "ANEXO 3",
      titleAnexoPDF6: "ANEXO 4",
      titleAnexoPDF7: "ANEXO 5",
    },
    {
      id: 4,
      numDoc: "ACUERDO INE CG-537-2023",
      nameDoc: "PERSONAS VISITANTES EXTRANJERAS",
      link: "4",
      titleAnexoPDF1: "(Anexo 1) Convocatoria VE 23-24",
      titleAnexoPDF2: "(Anexo 2) Solicitud acreditación VE 23-24",
      titleAnexoPDF3: "(Anexo 3) Prog. Trab. Visit. Extranjera 23-24",
      titleAnexoPDF4: "(Anexo 4) Gafete de acreditación VE 23-24",
    },
  ],

  2022: [
    {
      id: 1,
      numDoc: "INE CG-598-2022",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA DESIGNACIÓN DE LAS PRESIDENCIAS DE LOS OPLES DE LAS ENTIDADES DE AGUASCALIENTES, COAHUILA, NAYARIT, PUEBLA, QUINTANA ROO, SINALOA Y TLAXCALA",
      link: "1",
    },
    {
      id: 2,
      numDoc: "INE CG-633-2022",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA DESIGNACION DEL CONSEJERO PRESIDENTE PROVISIONAL DEL ITE",
      link: "2",
    },
  ],

  2021: [
    {
      id: 1,
      numDoc: "INE JGE-50-2021",
      nameDoc: "ACUERDO INCORPORACIÓN TEMPORAL",
      link: "1",
      titleAnexoPDF1: "LINEAMIENTOS OPLE INCORPORACIÓN TEMPORAL",
    },
    {
      id: 2,
      numDoc: "INE JGE-51-2021",
      nameDoc: "ACUERDO OCUPACIÓN DE ENCARGOS",
      link: "2",
      titleAnexoPDF1: "LINEAMIENTOS OPLE OCUPACIÓN E ENCARGOS",
    },
    {
      id: 3,
      numDoc: "INE CG-1600-2021",
      nameDoc:
        "SE APRUEBA EL PLAN INTEGRAL Y LOS CALENDARIOS DE COORDINACIÓN PARA LOS PROCESOS ELECTORALES LOCALES EXTRAORDINARIOS 2021",
      link: "3",
      titleAnexoPDF1: "ANEXO 1 PLAN INTEGRAL Y CALENDARIOS DE COORDINACIÓN",
      titleAnexoPDF3: "ANEXO 3 CONCENTRADO DE ACTIVIDADES",
    },
  ],

  2020: [
    {
      id: 1,
      numDoc: "INE CG-255-2020",
      nameDoc: "CONVOCATORIAS OBSERVADORES ELECTORALES",
      link: "1",
      titleAnexoPDF1: "ANEXO 1 (PUNTO 4)",
      titleAnexoPDF2: "ANEXO 2 (PUNTO 4)",
    },
    {
      id: 2,
      numDoc: "INE CG-270-2020",
      nameDoc:
        "BASES Y CRITERIOS PARA EXTRANJEROS QUE ACUDAN A CONOCER EL PROCESO ELECTORAL 2020-2021",
      link: "2",
      titleAnexoPDF1: "ANEXO 1",
      titleAnexoPDF2: "ANEXO 2",
    },
    {
      id: 3,
      numDoc: "INE CG-559-2020",
      nameDoc: "RESOLUCIÓN DEL CONSEJO GENERAL",
      link: "3",
    },
    {
      id: 4,
      numDoc: "INE CG-569-2020",
      nameDoc: "ACUERDO POR EL QUE SE DA RESPUESTA A CONSULTAS",
      link: "4",
    },
    {
      id: 5,
      numDoc: "INE JGE-160-2020",
      nameDoc: "ACUERDO DE LA JGE POR EL QUE SE APRUEBAN LINEAMIENTOS",
      link: "5",
      titleAnexoPDF1: "ANEXO 1",
    },
    {
      id: 6,
      numDoc: "INE CG-187-2020",
      nameDoc: "RESOLUCIÓN DEL CONSEJO GENERAL",
      link: "6",
    },
    {
      id: 7,
      numDoc: "INE CG-289-2020",
      nameDoc: "RESOLUCIÓN DEL CONSEJO GENERAL",
      link: "7",
    },
    {
      id: 8,
      numDoc: "INE CG-694-2020",
      nameDoc: "RESOLUCIÓN DEL CONSEJO GENERAL",
      link: "8",
    },
  ],

  2016: [
    {
      id: 1,
      idDoc: "000062",
      numDoc: "ACUERDOS",
      nameDoc: "RESPUESTAS DE CONSULTAS DE OPLES",
      link: "",
      titleAnexoPDF1: "ACUERDO INE CVOPL-001-2016",
      titleAnexoPDF2: "ACUERDO INE CVOPL-002-2016",
      titleAnexoPDF3: "ACUERDO INE CVOPL-003-2016",
      titleAnexoPDF4: "ACUERDO INE CVOPL-004-2016",
      titleAnexoPDF5: "ACUERDO INE CVOPL-005-2016",
      titleAnexoPDF6: "ACUERDO INE CVOPL-006-2016",
      titleAnexoPDF7: "ACUERDO INE CVOPL-012-2016",
    },
    {
      id: 2,
      idDoc: "000072",
      numDoc: "ACUERDOS",
      nameDoc: "RESPUESTAS DE CONSULTAS DE OPLES",
      link: "",
      titleAnexoPDF1: "ACUERDO INE CVOPL-007-2016-CG865",
      titleAnexoPDF2: "ACUERDO INE CVOPL-008-2016-CG865",
      titleAnexoPDF3: "ACUERDO INE CVOPL-010-2016-CG865",
      titleAnexoPDF4: "ACUERDO INE CVOPL-011-2016-CG865",
    },
    {
      id: 3,
      idDoc: "000080",
      numDoc: "INE CG-1047-2016",
      nameDoc:
        "ACUERDO DONDE SE REFORMAN Y ADICIONAN DIVERSAS DISPOSICIONES DEL REGLAMENTO DE FISCALIZACIÓN",
      link: "3",
    },
    {
      id: 4,
      idDoc: "000118",
      numDoc: "ESTATUTO",
      nameDoc: "DEL SERVICIO PROFESIONAL ELECTORAL NACIONAL DOF",
      link: "4",
    },
    {
      id: 5,
      idDoc: "000191",
      numDoc: "CF-001-2016",
      nameDoc:
        "MANUAL DE USUARIO PARA PP, COALICIONES, CI PARA LA OPERACIÓN DEL SISTEMA INTEGRAL DE FISCALIZACIÓN",
      link: "5",
    },
    {
      id: 6,
      idDoc: "000236",
      numDoc: "INE CG-47-2016",
      nameDoc:
        "ACUERDO PARA LA INTEGRACIÓN DEL CATÁLOGO DE CARGOS Y PUESTOS DEL SERVICIO PROFESIONAL",
      link: "6",
    },
    {
      id: 7,
      idDoc: "000244",
      numDoc: "INE CG-38-2016",
      nameDoc: "LINEAMIENTOS DE PADRÓN ELECTORAL Y LISTA DE ELECTORES",
      link: "7",
    },
    {
      id: 8,
      idDoc: "000256",
      numDoc: "INE CF-004-2016",
      nameDoc:
        "LINEAMIENTOS DE REALIZACIÓN DE VISITAS DE VERIFICACIÓN, PROPAGANDA EN VÍA PÚBLICA Y ETC",
      link: "8",
      titleAnexoPDF1: "INE_UTVOPL_033_2016-OPLES_004",
    },
    {
      id: 9,
      idDoc: "000314",
      numDoc: "INE CG-61-2016",
      nameDoc:
        "CRITERIOS INSTITUCIONALES PARA CONTESTAR CONSULTAS REALIZADAS POR LOS OPLES",
      link: "9",
    },
    {
      id: 10,
      idDoc: "000315",
      numDoc: "INE CG-63-2016",
      nameDoc:
        "CRITERIOS PARA GARANTIZAR LA PARIDAD DE GÉNERO EN LA POSTULACIÓN DE CANDIDATURAS",
      link: "10",
    },
    {
      id: 11,
      idDoc: "000427",
      numDoc: "ACUERDOS",
      nameDoc:
        "INE-CG1012-2015, INE-CG948-2015 E INE-CG950-2015 LINEAMIENTOS JE",
      link: "",
      titleAnexoPDF1: "ANEXO_2-CG1012",
      titleAnexoPDF2: "ANEXO_4-CG1012",
      titleAnexoPDF3: "RESUMEN-CG948",
    },
  ],

  2015: [
    {
      id: 1,
      idDoc: "000029",
      numDoc: "INE CG-824-2015",
      nameDoc: "APRUEBA DEMARCACIÓN TERRITORIAL DE LOS DISTRITOS UNINOMINALES",
      link: "1",
      titleAnexoPDF1: "OFICIO TLAXCALA",
    },
    {
      id: 2,
      idDoc: "000036",
      numDoc: "INE CG-843-2015",
      nameDoc: "SE DETERMINA LA INSTANCIA DE PARTIDOS",
      link: "2",
      titleAnexoPDF1: "OFICIO TLAXCALA",
    },
    {
      id: 3,
      idDoc: "000048",
      numDoc: "INE ACRT-33-2015",
      nameDoc:
        "SE MODIFICA ACUERDO INE-ACRT-32-2015 POR PÉRDIDA DE REGISTRO DE PT Y PH",
      link: "3",
    },
    {
      id: 4,
      idDoc: "000065",
      numDoc: "INE CG-861-2015",
      nameDoc:
        "SE CREA LA COMISIÓN TEMPORAL PARA EL SEGUIMIENTO DE LOS PROCESOS ELECTORALES 2015-2016",
      link: "4",
      titleAnexoPDF1: "TLAXCALA-1",
    },
    {
      id: 5,
      idDoc: "000084",
      numDoc: "INE CG-896-2015",
      nameDoc:
        "SE RATIFICA Y DESIGNAN A LOS CONSEJEROS ELECTORALES DE LOS PROCESOS ELECTORALES 2015-2016",
      link: "5",
    },
    {
      id: 6,
      idDoc: "000139",
      numDoc: "INE ACRT-34-2015",
      nameDoc:
        "SE ESTABLECEN LOS TÉRMINOS Y CONDICIONES SATELITAL… DE LA TRANSMISIÓN DE LOS PROCESOS ELECTORALES",
      link: "6",
    },
    {
      id: 7,
      idDoc: "000146",
      numDoc: "INE CG-927-2015",
      nameDoc:
        "CRITERIOS GENERALES A EFECTO DE GARANTIZAR EL CUMPLIMIENTO DE PRINCIPIO DE PARIDAD DE GÉNERO",
      link: "7",
    },
    {
      id: 8,
      idDoc: "000149",
      numDoc: "INE CG-861-2015",
      nameDoc: "ESTRATEGIA DE CAPACITACIÓN Y ASISTENCIA ELECTORAL 2015-2016",
      link: "8",
      titleAnexoPDF1: "ANEXO 1",
      titleAnexoPDF2: "ANEXO 2",
      titleAnexoPDF3: "ANEXO 3",
      titleAnexoPDF4: "OFICIO INE_UTVOPL_4725_2015_TLAX",
    },
    {
      id: 9,
      idDoc: "000150",
      numDoc: "ACUERDOS",
      nameDoc:
        "ACATAMIENTOS DE LAS SENTENCIAS DE LA SALA SUPERIOR DE LOS PARTIDOS DEL TRABAJO Y HUMANISTA",
      link: "",
      titleAnexoPDF1: "ACUERDO INE-CG936-2015",
      titleAnexoPDF2: "ACUERDO INE-CG937-2015",
      titleAnexoPDF3: "CIRCULAR INE-UTVOPL-133-15",
    },
    {
      id: 10,
      idDoc: "000154",
      numDoc: "INE CG-921-2015",
      nameDoc:
        "LINEAMIENTOS GENERALES PARA EL DISEÑO, IMPLEMENTACIÓN Y OPERACIÓN DEL CONTEO RÁPIDO",
      link: "10",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-145-15",
    },
    {
      id: 11,
      idDoc: "000155",
      numDoc: "INE CG-909-2015",
      nameDoc:
        "ESTATUTO DEL SERVICIO PROFESIONAL ELECTORAL Y RAMA ADMINISTRATIVA",
      link: "11",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-135-2015",
    },
    {
      id: 12,
      idDoc: "000170",
      numDoc: "INE CG-928-2015",
      nameDoc:
        "LINEAMIENTOS QUE OBSERVARÁN LOS OPLES PARA EL REGISTRO DE COALICIONES",
      link: "12",
    },
    {
      id: 13,
      idDoc: "000171",
      numDoc: "INE CG-935-2015",
      nameDoc:
        "LINEAMIENTOS PARA EL PROGRAMA DE RESULTADOS ELECTORALES PRELIMINARES",
      link: "13",
    },
    {
      id: 14,
      idDoc: "000172",
      numDoc: "INE CG-953-2015",
      nameDoc:
        "MEDIDAS ESPECÍFICAS PARA LOS PRESIDENTES DE LA MDC PARA EVITAR LA COMPRA DEL VOTO COACCIÓN E INDUCIÓN",
      link: "14",
    },
    {
      id: 15,
      idDoc: "000179",
      numDoc: "INE CG-951-2015",
      nameDoc:
        "CRITERIOS QUE DEBERÁN OBSERVAR LOS OPLES DEL SISTEMA DE SEGUIMIENTO Y DESARROLLO DE LA JORNADA ELECTORAL",
      link: "",
      titleAnexoPDF1: "ACUERDO INE-CG-951-2015",
      titleAnexoPDF2: "ACUERDO1 INE-CG-951-2015",
      titleAnexoPDF3: "CIRCULAR INE_UTVOPL_154_2015_CG951",
    },
    {
      id: 16,
      idDoc: "000180",
      numDoc: "INE CG-9502015",
      nameDoc:
        "SE EMITEN LINEAMIENTOS PARA IMPRESIÓN DE DOCUMENTOS Y ELABORACIÓN DE MATERIALES ELECTORALES",
      link: "16",
      titleAnexoPDF1:
        "PARA IMPRESIÓN DE DOCUMENTOS Y ELABORACIÓN DE MATERIALES ELECTORALES",
      titleAnexoPDF2: "INE_UTVOPL_169_2015_CG950",
    },
    {
      id: 17,
      idDoc: "000181",
      numDoc: "INE CG-938-2015",
      nameDoc:
        "PROCEDIMIENTO DE LIQUIDACIÓN DE LOS PARTIDOS POLÍTICOS NACIONALES QUE NO ALCANZARON EL 3%",
      link: "17",
    },
    {
      id: 18,
      idDoc: "000182",
      numDoc: "INE CG-948-2015",
      nameDoc: "LINEAMIENTOS DE PARTICIPACIÓN CIUDADANA",
      link: "18",
      titleAnexoPDF1: "INE-UTVOPL-174-2015",
    },
    {
      id: 19,
      idDoc: "000183",
      numDoc: "INE CG-949-2015",
      nameDoc:
        "SE PRECISAN LOS ALCANCES DE LAS ATRIBUCIONES DE LAS COMISIÓN TEMPORAL",
      link: "19",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-167-2015",
    },
    {
      id: 20,
      idDoc: "000184",
      numDoc: "INE CG-934-2015",
      nameDoc:
        "SE PRECISAN LOS ALCANCES DE LAS ATRIBUCIONES DE LAS COMISIÓN TEMPORAL",
      link: "20",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-167-2015",
    },
    {
      id: 21,
      idDoc: "000185",
      numDoc: "INE CG-939-2015",
      nameDoc:
        "LINEAMIENTOS PARA EL REGISTRO DE LOS OTRORA PARTIDOS NACIONALES PARA OPTAR COMO PARTIDO LOCAL",
      link: "",
      titleAnexoPDF1: "INE-UTVOPL-165-2015",
    },
    {
      id: 22,
      idDoc: "000185",
      numDoc: "INE CG-1000-2015",
      nameDoc:
        "MODIFICACIÓN DEL REGLAMENTO DE SESIONES DE LOS CONSEJOS LOCALES Y DISTRTITALES DEL INE",
      link: "",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-185-2015",
    },
    {
      id: 23,
      idDoc: "000292",
      numDoc: "INE DERFE-1434-2015",
      nameDoc:
        "ESTRATEGIA DE INFORMACIÓN EN APOYO A LA ACTUALIZACIÓN DEL PADRÓN ELECTORAL",
      link: "23",
    },
    {
      id: 24,
      idDoc: "000295",
      numDoc: "INE ACRT-37-2015",
      nameDoc:
        "MODIFICAN ACUERDO INE-ACRT-32-2015 CON MOTIVO DE LA PÉRDIDA DE REGISTRO DEL PT Y PH",
      link: "",
    },
    {
      id: 25,
      idDoc: "000332",
      numDoc: "RAP-756-2015",
      nameDoc:
        "RESOLUCIÓN DEL PT DERIVADA DEL RECURSO DE REVISIÓN SUP-RAP-756-2015",
      link: "25",
      titleAnexoPDF1: "INE_UTVOPL_197_2015_CG936",
    },
    {
      id: 26,
      idDoc: "000348",
      numDoc: "ACUERDOS",
      nameDoc: "",
      link: "",
      titleAnexoPDF1: "INE ACRT-40-2015 (CATÁLOGO NACIONAL)",
      titleAnexoPDF2: "INE CG-980-2015 (PUBLICACIÓN DEL CAT)",
      titleAnexoPDF3: "INE ACRT-45-2015 (APROB MODELOS DE PAUTAS)",
      titleAnexoPDF4: "INE JGE-160-2015",
    },
    {
      id: 27,
      idDoc: "000366",
      numDoc: "INE VRFE-439-2015",
      nameDoc:
        "PLANOS URBANOS, CARTA ELECTORAL MUNICIPAL, PLANOS POR SECCIÓN INDIVIDUAL",
      link: "",
    },

    {
      id: 28,
      idDoc: "000371",
      numDoc: "INE CG-824-",
      nameDoc: "NUEVA DEMARCACIÓN TERRITORIAL",
      link: "28",
      titleAnexoPDF1: "DESCRIPTIVO DE TLAXCALA",
      titleAnexoPDF2: "ESCENARIO FINAL TLAXCALA",
      titleAnexoPDF3: "MAPA TLAXCALA",
    },
    {
      id: 29,
      idDoc: "000391",
      numDoc: "RAP-1049-2015",
      nameDoc: "RESOLUCIÓN DEL INE RELATIVO AL REGISTRO DEL PT",
      link: "29",
      titleAnexoPDF1: "CIRCULAR INE-UTVOPL-210-2015",
    },
    {
      id: 30,
      idDoc: "000392",
      numDoc: "ACUERDOS",
      nameDoc: "ACUERDOS DONDE INCLUYEN AL PT EN LAS PAUTAS DE RADIO Y TV",
      link: "",
      titleAnexoPDF1: "ACUERDO INE ACRT-49-2015",
      titleAnexoPDF2: "ACUERDO INE-ACRT-51-2015",
    },
    {
      id: 33,
      idDoc: "000543",
      numDoc: "INE CG-813-2015",
      nameDoc: "DESIGNACIÓN DEL CONSEJO GENERAL DE TLAXCALA",
      link: "33",
      titleAnexoPDF1: "DICTAMEN TLAXCALA",
    },
  ],
};

// ------------ A C U E R D O S    I T E ------------
export const dataAcuerdosITE = {
  2024: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBA PROCESO TÉCNICO OPERATIVO DEL PREP PARA PELO 2023-2024",
      titleAnexo1: "PROCESO TÉCNICO OPERATIVO DEL PREP",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-071-2023 Y ACUMULADOS",
      titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
      titleAnexo2: "ANEXO UNO",
      titleAnexo3: "ANEXO DOS",
      titleAnexo4: "ANEXO TRES",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE DESIGNA TITULAR DE LA DPAyF",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "POR EL QUE SE APRUEBA EL INFORME DE OTORGAMIENTO DE INCENTIVOS 2023 PERSONAL SPEN",
      titleAnexo1: "Informe otorgamiento incentivos",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE ORDENA DIFUNDIR GASTO CALENDARIZADO PARA FINANCIAMIENTO DE PP 2024",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE ACTUALIZA CALENDARIZACIÓN DE CANTIDADES A RETENER PRI, MC Y PT",
      titleAnexo1: "ANEXO UNICO",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "CALENDARIZACIÓN DE REMANENTES DE FINANCIAMIENTO PÚBLICO DE CAMPAÑA NO EJERCIDOS 2020-2021, ACUERDO INE-CG232-2023",
      titleAnexo1: "ANEXO UNICO",
    },
    {
      id: 8,
      typeDoc: "RESOLUCION",
      monthDoc: "ENE",
      nameDoc:
        "SE DECLARA LA PROCEDENCIA RESPECTO DE MODIFICACIÓN DE DOCUMENTOS BÁSICOS NUEVA ALIANZA TLAXCALA",
    },
    {
      id: 9,
      typeDoc: "RESOLUCION",
      monthDoc: "ENE",
      nameDoc:
        "SE NOMBRA A LA COORDINADORA DE GÉNERO Y NO DISCRIMINACIÓN DE ESTE INSTITUTO.",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "CUMPLIMIENTO DE SENTENCIA DIPUTACIÓN MIGRANTE, CUMPLIMIENTO SENTENCIA TET-JDC-049-2023",
      titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
      titleAnexo2: "ANEXO UNO",
      titleAnexo3: "ANEXO DOS",
      titleAnexo4: "ANEXO TRES",
    },
    {
      id: 11,
      typeDoc: "RESOLUCION",
      monthDoc: "ENE",
      nameDoc: "REGISTRO CONVENIO DE COALICIÓN FUERZA Y CORAZON POR TLAXCALA",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CALENDARIZA LA RETENCIÓN DE MULTAS A LOS PARTIDOS POLÍTICOS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "UBICACIÓN DE LOS CENTROS DE ACOPIO Y TRANSMISIÓN DE DATOS",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE APRUEBA PROGRAMA DE SEMINARIOS, CONFERENCIAS, COLOQUIOS",
      titleAnexo1: "ANEXO ÚNICO. PROGRAMA",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "CUMPLIMIENTO A LA SENTENCIA TET-JDC-30-2020 Y ACUMULADO TET-JDC-32-2020",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "INFORME FINAL DEL INTERVENTOR DEL OTRORA PARTIDO POLÍTICO LOCAL ENCUENTRO SOCIAL TLAXCALA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 17,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-023-2023",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-071-2023",
      titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS",
      titleAnexo2: "ANEXO UNO",
      titleAnexo3: "ANEXO DOS",
      titleAnexo4: "ANEXO TRES",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "AJUSTE FRL PLAZO DE VERIFICACIÓN DEL APOYO CIUDADANO PELO 23-24",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE DESIGNA ENTE AUDITOR DEL PREP PELO 23-24",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "POR EL QUE SE APRUEBA EL DICTAMEN DE DOCUMENTACIÓN CON EMBLEMAS",
      titleAnexo1: "ANEXO UNICO",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE DA RESPUESTA A CONSULTAS DE ACCIONES A GRUPOS DE ATENCIÓN PRIORITARIA",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE APRUEBA EL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
      titleAnexo1:
        "ANEXO ÚNICO PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
      titleAnexo2:
        "ANEXO UNO DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
      titleAnexo3:
        "ANEXO DOS DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
      titleAnexo4:
        "ANEXO TRES DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
      titleAnexo5:
        "ANEXO CUATRO DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE APRUEBA LA DESIGNACIÓN DE LOS LUGARES QUE OCUPARAN LAS BODEGAS ELECTORALES PELO 2023-2024",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE APRUEBAN LINEAMIENTOS DE DESARROLLO DE CÓMPUTO Y CUADERNILLO DE VOTOS PELO 2023-2024",
      titleAnexo1:
        "ANEXCO UNO LINEAMIENTOS PARA EL DESARROLLO DE LOS CÓMPUTOS ELECTORALES PELO 2023-2024",
      titleAnexo2:
        "ANEXO DOS CUADERNILLO DE CONSULTA DE VOTOS VÁLIDOS Y NULOES PELO 2023-2024",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE CALENDARIZA MULTA PENDIENTE POR PAGAR AL PARTIDO MORENA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "POR EL QUE SE DETERMINAN LOS TOPES DE GASTO DE CAMPAÑA EN EL PELO 2023-2024",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES",
    },
    //ACUERDO 28 MEDIDAS DE PROTECCION DE DATOS PERSONALES
  
    // {
    //   id: 29,
    //   typeDoc: "ACUERDO",
    //   monthDoc: "MAR",
    //   nameDoc:
    //     "POR EL SE APREUBA EL MANUAL DE REGISTRO DE CANDIDATURAS PARA EL PELO 2023-2024",
    //   titleAnexo1:
    //     "ANEXO ÚNICO MANUAL PARA EL REGISTRO DE CANDIDATURAS PELO 2023-2024",
    //   titleAnexo2: "ANEXO 1.1 DEL MANUAL DE REGISTRO",
    //   titleAnexo3: "ANEXO 1.2 DEL MANUAL DE REGISTRO",
    //   titleAnexo4: "ANEXO 1.3 DEL MANUAL DE REGISTRO",
    //   titleAnexo5: "ANEXO 1.4 DEL MANUAL DE REGISTRO",
    //   titleAnexo6: "ANEXO 2.1 DEL MANUAL DE REGISTRO",
    //   titleAnexo7: "ANEXO 2.2 DEL MANUAL DE REGISTRO",
    //   titleAnexo8: "ANEXO 2.3 DEL MANUAL DE REGISTRO",
    //   titleAnexo9: "ANEXO 2.4 DEL MANUAL DE REGISTRO",
    //   titleAnexo10: "ANEXO 3.1 DEL MANUAL DE REGISTRO",
    //   titleAnexo11: "ANEXO 3.2 DEL MANUAL DE REGISTRO",
    //   titleAnexo12: "ANEXO 3.3 DEL MANUAL DE REGISTRO",
    //   titleAnexo13: "ANEXO 4.1 DEL MANUAL DE REGISTRO",
    //   titleAnexo14: "ANEXO 4.2 DEL MANUAL DE REGISTRO",
    //   titleAnexo15: "ANEXO 4.3 DEL MANUAL DE REGISTRO",
    //   titleAnexo16: "ANEXO 5 DEL MANUAL DE REGISTRO",
    //   titleAnexo17: "ANEXO 6.1 DEL MANUAL DE REGISTRO",
    //   titleAnexo18: "ANEXO 6.2 DEL MANUAL DE REGISTRO",
    //   titleAnexo19: "ANEXO 6.3 DEL MANUAL DE REGISTRO",
    // },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "POR EL QUE SE RESUELVE RESPECTO DEL CUMPLIMIENTO DE PORCENTAJE DE APOYO DE LA CIUDADANIA A LAS C.I.",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE APRUEBA LA CONVOCATORIA CAES Y SE",
      titleAnexo1: "ANEXO ÚNICO CONVOCATORIA SE Y CAE",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "POR EL QUE SE DA RESPUESTA A DIVERSAS COMUNIDADES NAHUAS Y EQUIPARABLES",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "POR EL QUE SE APRUEBA LA INTEGRACIÓN DE CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo1: "ANEXO UNO INTEGRACIÓN CONSEJOS DISTRITALES",
      titleAnexo2: "ANEXO DOS INTEGRACIÓN CONSEJOS MUNICIPALES",
      titleAnexo3: "ANEXO TRES LISTA DE RESERVA",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "POR EL QUE SE APRUEBA LA SUSTICIONES DE CONSEJOS DISTRITALES ELECTORALES",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc:
        "RESPECTO A LA SOLICITUD DE REGISTRO DEL CONVENIO DE CANDIDATURA COMÚN SIGAMOS HACIENDO HISTORIA EN TLAXCALA",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE APRUEBA LA DISTRIBUCIÓN DEL FINANCIAMIENTO PUBLICO",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DE RESULTADOS SPEN",
      titleAnexo1: "DICTAMEN",
    }, //
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE DESIGNA ENCARGADA DE LA COORDINACIÓN DE ARCHIVOS",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "INTEGRACIÓN COMISIÓN DE DEBATES",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE APRUEBA EL PROGRAMA OPERATIVO DE RED DE CANDIDATAS",
      titleAnexo1: "PROGRAMA OPERATIVO DE LA RED DE CANDIDATAS",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "FECHA  PUBLICACIÓN DEL SISTEMA CONÓCELES",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SUSTITUCIÓN DE INTEGRANTES DE CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE DESIGNA AL PERSONAL BODEGAS ELECTORALES",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "POR EL QUE SE APRUEBA LA DISTRIBUCIÓN DE FINANCIAMIENTO PÚBLICO",
      titleAnexo1: "ANEXO UNICO INFORME MATERIALES DIDÁCTICOS",
      titleAnexo2: "ANEXO DEL INFORME PASO A PASO 4 DE JUNIO",
      titleAnexo3: "ANEXO DEL INFORME PASO A PASO PAQ",
      titleAnexo4: "ANEXO DEL INFORME PASO A PASO REUNION TRABAJO",
      titleAnexo5: "ANEXO DEL INFORME MATERIAL DIDACTICO CUADERNILLO",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PRD",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PT",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PVEM",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL MC",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PAC",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL MORENA",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PNAT",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL RSPT",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL FXMT",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PAN",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PRI",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PAN",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PRI",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PRD",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PT",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PVEM",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL MC",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PAC",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL MORENA",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL NAT",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL RSPT",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL FXMT",
    },
    {
      id: 67,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP PRD",
      titleAnexo1: "DICTAMEN PRD",
    },
    {
      id: 68,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP PT",
      titleAnexo1: "DICTAMEN PT",
    },
    {
      id: 69,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP MC",
      titleAnexo1: "DICTAMEN MC",
    },
    {
      id: 70,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP PAC",
      titleAnexo1: "DICTAMEN PAC",
    },
    {
      id: 71,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP PVEM",
      titleAnexo1: "DICTAMEN PVEM",
    },
    {
      id: 72,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP MORENA",
      titleAnexo1: "DICTAMEN MORENA",
    },
    {
      id: 73,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP PNAT",
      titleAnexo1: "DICTAMEN PNAT",
    },
    {
      id: 74,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP RSPT",
      titleAnexo1: "DICTAMEN RSPT",
    },
    {
      id: 75,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP FXMT",
      titleAnexo1: "DICTAMEN FXMT",
    },
    {
      id: 76,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO CANDIDATURA COMUN",
    },
    {
      id: 77,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO COALICION F y C",
      titleAnexo1: "DICTAMEN PAN",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 78,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO RP PAN",
      titleAnexo1: "DICTAMEN PAN",
    },
    {
      id: 79,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DP RP PRI",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN CONSEJOS MUNICIPALES",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AMPLIACION DE PLAZO CONVOCATORIA SEL Y CAEL",
      titleAnexo1: "CONVOCATORIA SEL Y CAEL",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA DE LA SALA REGIONAL",
      titleAnexo1: "MANUAL REGISTRO CANDIDATURAS",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SE DA RESPUESTA A PERSONAS",
    },
    {
      id: 85,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "MODIFICACIÓN CONVENIO COALICION F Y C",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MEDIDAS DE SEGURIDAD DE BOLETAS",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "VOTO ANTICIPADO Y BOLETA DUPLEX",
    },
    {
      id: 88,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP PRD",
      titleAnexo1: "DICTAMEN PRD",
    },
    {
      id: 89,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP PT",
    },
    {
      id: 90,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP PAC",
      titleAnexo1: "DICTAMEN PAC",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCION CONSEJOS MUNICIPALES",
    },
    {
      id: 92,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP MR COALICION F Y C",
    },
    {
      id: 93,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP RP PAN",
      titleAnexo1: "DICTAMEN PAN",
    },
    {
      id: 94,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP PVEM",
      titleAnexo1: "DICTAMEN PVEM",
    },
    {
      id: 95,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP MORENA",
      titleAnexo1: "DICTAMEN MORENA",
    },
    {
      id: 96,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP PNAT",
      titleAnexo1: "DICTAMEN PNAT",
    },
    {
      id: 97,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP RSPT",
      titleAnexo1: "DICTAMEN RSPT",
    },
    {
      id: 98,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO DIP FXMT",
      titleAnexo1: "DICTAMEN FXMT",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCION CONSEJOS MUNICIPALES",
    },
    {
      id: 100,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP FXMT",
      titleAnexo1: "DICTAMEN FXMT",
    },
    {
      id: 101,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP NAT",
    },
    {
      id: 102,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP RSPT",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "FE DE ERRATAS",
    },
    {
      id: 103,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP PVEM",
    },
    {
      id: 104,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP MORENA",
    },
    {
      id: 105,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO CANDIDATURA COMUN",
    },
    {
      id: 106,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP MR COALICION",
    },
    {
      id: 107,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIP RP PAN",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES CONSEJOS MUNICIPALES",
    },
    {
      id: 109,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO DIP PT",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CONVOCATORIA CAES Y SE",
      titleAnexo1: "CONVOCATORIA SE Y CAE",
    },
    {
      id: 111,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "INCLUSION DE SOBRENOMBRES",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CONVOCATORIA DIFUSORES DEL PREP",
      titleAnexo1: "CARTA DE INTENCIÓN PREP",
      titleAnexo2: "CONVOCATORIA DIFUSORES OFICIALES",
      titleAnexo3: "LISTA DE VERIFICACIÓN DE REQUISITOS DIFUSORES",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "READECUACIÓN DE PRESUPUESTO",
      titleAnexo1: "ADECUACIÓN PRESUPUESTAL",
    },
    {
      id: 114,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-043-2024",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-042-2024",
    },
    {
      id: 116,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD REGISTRO DIP COALICION F Y C",
      titleAnexo1: "ANEXO POSTULACIONES DIP COALICION F Y C",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REFORMA DE LINEAMIENTOS DE DEBATES 2024",
      titleAnexo1: "ANEXO UNICO REFORMA LINEAMIENTOS DE DEBATES 2024",
    },
    {
      id: 118,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DIP PT Y FXMT",
      titleAnexo1: "DICTAMEN SUST DIP PT Y FXMT",
    },
    {
      id: 119,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO CANDIDATURAS INDPENDIENTES",
    },
    {
      id: 120,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRD",
      titleAnexo1: "ANEXO UNO POSTULACIONES PRD",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PRD",
      titleAnexo3: "DICTAMEN PRD AYU",
    },
    {
      id: 121,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PT",
      titleAnexo3: "DICTAMEN PT AYU",
    },
    {
      id: 122,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PVEM",
      titleAnexo1: "ANEXO UNO POSTULACIONES PVEM",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  PVEM",
      titleAnexo3: "DICTAMEN PVEM AYU",
    },
    {
      id: 123,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MC",
      titleAnexo1: "ANEXO UNO POSTULACIONES MC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  MC",
      titleAnexo3: "DICTAMEN MC AYU",
    },
    {
      id: 124,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SE RESUELVE REGISTRO AYUNTAMIENTOS MORENA",
      titleAnexo1: "ANEXO UNO POSTULACIONES MORENA",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION MORENA",
      titleAnexo3: "DICTAMEN MORENA AYU",
    },
    {
      id: 125,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO NAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES NAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION NAT",
      titleAnexo3: "DICTAMEN  PNAT AYU",
    },
    {
      id: 126,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PAC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PAC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PAC",
      titleAnexo3: "DICTAMEN PAC AYU",
    },
    {
      id: 127,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO FMXT",
      titleAnexo1: "ANEXO UNO POSTULACION FMXT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION FMXT",
      titleAnexo3: "DICTAMEN FXM AYU",
    },
    {
      id: 128,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION RSPT",
      titleAnexo3: "DICTAMEN RSP AYU",
    },
    {
      id: 129,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PAN",
      titleAnexo1: "ANEXO UNO POSTULACIONES PAN",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  PAN",
      titleAnexo3: "DICTAMEN PAN AYU",
    },
    {
      id: 130,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PRI",
      titleAnexo3: "DICTAMEN PRI AYU",
    },
    {
      id: 131,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO COALICION F Y C",
      titleAnexo1: "ANEXO UNO POSTULACIONES COALICION",
    },
    {
      id: 132,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PRD",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
      titleAnexo3: "DICTAMEN PRD AYU",
    },
    {
      id: 133,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PVEM",
      titleAnexo3: "DICTAMEN PT COM",
    },
    {
      id: 134,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PVEM",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PVEM",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
      titleAnexo3: "DICTAMEN PVEM COM",
    },
    {
      id: 135,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC MC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MORENA",
      titleAnexo3: "DICTAMEN MC COM",
    },
    {
      id: 136,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC MORENA",
      titleAnexo1: "ANEXO UNO POSTULACION PC MORENA",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC NAT",
      titleAnexo3: "DICTAMEN MORENA COM",
    },
    {
      id: 137,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC NAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC NAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
      titleAnexo3: "DICTAMEN PNAT COM",
    },
    {
      id: 138,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PAC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
      titleAnexo3: "DICTAMEN PAC COM",
    },
    {
      id: 139,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC FMXT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
      titleAnexo3: "DICTAMEN FXM COM",
    },
    {
      id: 140,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE COTACION PC PAN",
      titleAnexo3: "DICTAMEN RSP COM",
    },
    {
      id: 141,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PAN",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
      titleAnexo3: "DICTAMEN PAN COM",
    },
    {
      id: 142,
      typeDoc: "RESOLUCION",
      monthDoc: "ABR",
      nameDoc: "SOLICITUD DE REGISTRO PC PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
      titleAnexo3: "DICTAMEN PRI COM",
    },
    // falta el acuerdo 143
    {
      id: 144,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO DIP RP PRI",
      titleAnexo1: "ANEXO ÚNICO DIP RP PRI",
    },
    {
      id: 145,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc:
        "SUSTITUCIONES DIP FXMT, PAC Y COALICIÓN FUERZA Y CORAZÓN POR TLAXCALA",
    },
    {
      id: 146,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOBRENOMBRES CANDIDATURAS",
    },
    {
      id: 147,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MC",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS MC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS MC",
      titleAnexo3: "DICTAMEN MC AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 148,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MORENA",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS MORENA",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS MORENA",
      titleAnexo3: "DICTAMEN MORENA AYUNTAMIENTOS",
      titleAnexo4: "ANEXO CUATRO SOBRENOMBRES MORENA",
      titleAnexo5: "FE DE ERRATAS",
    },
    {
      id: 149,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD DE REGISTRO AYUNTAMIENTOS PAC",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PAC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PAC",
      titleAnexo3: "DICTAMEN PAC AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 150,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PT",
      titleAnexo3: "DICTAMEN PT AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 151,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRD",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRD",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRD",
      titleAnexo3: "DICTAMEN PRD AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 152,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS RSPT",
      titleAnexo3: "DICTAMEN RSPT AYUNTAMIENTOS",
    },
    {
      id: 153,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS FXMT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS FXMT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS FXMT",
      titleAnexo3: "DICTAMEN FXMT AYUNTAMIENTOS",
    },
    {
      id: 154,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS NAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS NAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS NAT",
      titleAnexo3: "DICTAMEN NAT AYUNTAMIENTOS",
    },
    {
      id: 155,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRI",
      titleAnexo3: "DICTAMEN PRI AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 156,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PAN",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PAN",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PAN",
      titleAnexo3: "DICTAMEN PAN AYUNTAMIENTOS",
    },
    {
      id: 157,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO INDEPENDIENTES",
    },
    {
      id: 158,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PVEM",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PVEM",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PVEM",
      titleAnexo3: "DICTAMEN PVEM AYUNTAMIENTOS",
    },
    {
      id: 159,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLCITUD DE REGISTRO AYUNTAMIENTO COALICION",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA LICDA. ERIKA PERIAÑEZ RODRIGUEZ",
      titleAnexo2: "VOTO CONCURRENTE CONSEJERO MTRO. JUAN CARLOS MINOR MÁRQUEZ",
      titleAnexo3: "POSTULACIONES AYUNTAMIENTOS  COALICION",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 160,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS RSPT",
      titleAnexo3: "DICTAMEN RSPT AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 161,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRI",
      titleAnexo3: "DICTAMEN PRI AYUNTAMIENTOS",
    },
    {
      id: 162,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS FXMT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS FXMT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS FXMT",
      titleAnexo3: "DICTAMEN FXMT AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 163,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS NAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS NAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS NAT",
      titleAnexo3: "DICTAMEN NAT AYUNTAMIENTOS",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 164,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "FINANCIAMIENTO PÚBLICO PARA CI",
    },
    {
      id: 165,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES MC Y PT",
    },
    {
      id: 166,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
    },
    {
      id: 167,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PRD",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
      titleAnexo2: "ANEXO DOS POSTULACIONES PC PRD",
      titleAnexo3: "DICTAMEN PRD AYUNTAMIENTOS",
    },
    {
      id: 168,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC FXMT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
    },
    {
      id: 169,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PAC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
    },
    {
      id: 170,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC MC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
      titleAnexo3: "DICTAMEN MC PC",
    },
    {
      id: 171,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
      titleAnexo3: "DICTAMEN PRI PC",
    },
    {
      id: 172,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
    },
    {
      id: 173,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PVEM",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PVEM",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PVEM",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 174,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC MORENA",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC MORENA",
      titleAnexo2: "ANEXO DOS POSTULACIONES PC MORENA",
      titleAnexo3: "DICTAMEN MORENA AYUNTAMIENTOS",
    },
    {
      id: 175,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PAN",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAN",
    },
    {
      id: 176,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PNAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PNAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PNAT",
    },
    {
      id: 177,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLCITUD DE REGISTRO PC RSPT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 178,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC PAN",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAN",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 179,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC PAC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 180,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC PRD",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRD",
    },
    {
      id: 181,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD REGISTRO PC PT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
      titleAnexo3: "DICTAMEN PT PC",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 182,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "RESOLUCION SOLICITUD DE REGISTRO PC PRI",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 183,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC MC",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 184,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC MORENA",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC MORENA",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MORENA",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 185,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC FXMT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 186,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOLICITUD DE REGISTRO PC NAT",
      titleAnexo1: "ANEXO UNO POSTULACIONES PC NAT",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC NAT",
      titleAnexo3: "FE DE ERRATAS",
    },
    {
      id: 187,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CONVOCATORIA PERMANENTE SEL CAEL",
      titleAnexo1: "CONVOCATORIA",
    },
    {
      id: 188,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION CONSEJOS DISTRATALES Y MUNICIPALES",
    },
    {
      id: 189,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PERSONAL DESIGNADO PARA SELLADO",
      titleAnexo1: "ANEXO UNICO, PERSONAL PARA SELLADO",
    },
    {
      id: 190,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SOBRENOMBRES",
    },
    {
      id: 191,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION MC Y PRD",
    },
    {
      id: 192,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION MORENA",
    },
    {
      id: 193,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES MORENA Y MC",
    },
    {
      id: 194,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO SENTENCIA TET-JE-095-2024. PRD",
    },
    {
      id: 195,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRONUNCIAMIENTO ACCION AFIRMATIVA MIGRANTES",
    },
    {
      id: 196,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES PT Y PAC",
    },
    {
      id: 197,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOBRENOMBRES",
    },
    {
      id: 198,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION CONSEJOS D Y M",
    },
    {
      id: 199,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SE DA RESPUESTA AL CIUDADANO BERNARDO RODRIGUEZ",
    },
    {
      id: 200,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES FxMT Y PAN",
    },
    {
      id: 201,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SE MODIFICA PTO DEL PREP",
      titleAnexo1: "ANEXO ÚNICO PTO DEL PREP",
    },
    {
      id: 202,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DESIGNACION DE LAS CASILLAS Y PROCEDIMIENTOS DE VERIFICACIÓN",
      titleAnexo1: "ANEXO UNO SELECCIÓN DE CASILLAS",
      titleAnexo2:
        "ANEXO DOS PROCEDIMIENTOS PARA LA VERIFICACIÓN DE LAS MEDIDAS DE SEGURIDAD",
    },
    {
      id: 203,
      typeDoc: "RESOLUCION",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES FXMT PT Y PAC",
      titleAnexo1: "DICTAMEN PT 3RA REGIDURIA SAN JOSÉ TEACALCO",
    },
    {
      id: 205,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESPUESTA AL REPRESENTANTE SUPLENTE DE MORENA",
    },
    {
      id: 206,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DESIGNACION PERSONAL DEL ITE COMO CAEL",
      titleAnexo1: "DESIGNACION PERSONAL DEL ITE COMO CAEL",
    },
    {
      id: 207,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCION DE CONSEJOS MUNICIPALES",
    },
  
    {
      id: 208,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO DE SEDE CONSEJOS MUNICIPALES",
    },
  
    {
      id: 209,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASUME ATRIBUCIONES DEL CM SAN LUCAS TECOPILCO",
    },
  
    {
      id: 210,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCION DE CONSEJOS MUNICIPALES",
    },
  
    {
      id: 211,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO DE SEDE CONSEJO MUNICIPAL TOTOLAC",
    },
  
    {
      id: 212,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO DE SEDE DE CONSEJO MUNICIPAL CHIAUTEMPAN",
    },
  
    {
      id: 213,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO DE SEDE CONSEJO HUAMANTLA",
    },
  
    {
      id: 214,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCION DE INTEGRANTES CONSEJO MUNICIPAL HUAMANTLA",
    },
  
    {
      id: 215,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO SEDE CM SANTA CRUZ QUILEHTLA",
    },
  
    {
      id: 216,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CAMBIO DE SEDE CM CALPULALPAN",
    },
  
    {
      id: 217,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASUME ATRIBUCIONES DEL CM AXOCOMANITLA E IXTENCO",
    },
  
    {
      id: 218,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCION CM CALPULALPAN",
    },
  
    {
      id: 219,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMPUTO MUNICIPAL DE IXTENCO",
    },
    {
      id: 220,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMPUTO MUNICIPAL SAN LORENZO AXOCOMANITLA",
    },
    {
      id: 221,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMPUTO MUNICIPAL SAN LUCAS TECOPILCO",
    },
    {
      id: 222,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "OBTENCIÓN DEL 3 PORCIENTO DE LA VOTACIÓN",
    },
    {
      id: 223,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASIGNACION DIPTACIONES RP",
    },
    {
      id: 224,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASIGNACION REGIDURIAS",
      titleAnexo1: "1. Tlaxcala",
      titleAnexo2: "2. Huamantla",
      titleAnexo3: "3. San Pablo del Monte",
      titleAnexo4: "4. Apizaco",
      titleAnexo5: "5. Chiautempan",
      titleAnexo6: "6. Calpulalpan",
      titleAnexo7: "7. Zacatelco",
      titleAnexo8: "8. Tlaxco",
      titleAnexo9: "9. Yauhquemehcan",
      titleAnexo10: "10. Ixtacuixtla",
      titleAnexo11: "11. Contla de Juan Cuamatzi",
      titleAnexo12: "12. Tetla de la Solidaridad",
      titleAnexo13: "13. Papalotla de Xicohtencatl",
      titleAnexo14: "14. Panotla",
      titleAnexo15: "15. Nativitas",
      titleAnexo16: "16. Xaloztoc",
      titleAnexo17: "17. Teolocholco",
      titleAnexo18: "18. Santa Cruz Tlaxcala",
      titleAnexo19: "19. Totolac",
      titleAnexo20: "20. Tepetitla",
      titleAnexo21: "21. La Magdalena Tlatelulco",
      titleAnexo22: "22. Nanacamilpa de Mariano Matamoros",
      titleAnexo23: "23. Atltzayanca",
      titleAnexo24: "24. Tzompantepec",
      titleAnexo25: "25. El Carmen Tequextitla",
      titleAnexo26: "26. Cuapiaxtla",
      titleAnexo27: "27. Apetatitlán de Antonio Carvajal",
      titleAnexo28: "28. Terrenate",
      titleAnexo29: "29. Hueyotlipan",
      titleAnexo30: "30. Xicohtzinco",
      titleAnexo31: "31. Tetlatlahuca",
      titleAnexo32: "32. Tepeyanco",
      titleAnexo33: "33. Tenancigo",
      titleAnexo34: "34. San Francisco Tetlanohcan",
      titleAnexo35: "35. Mazatecochco de José María Morelos",
      titleAnexo36: "36. Amaxac de Guerrero",
      titleAnexo37: "37. Xaltocan",
      titleAnexo38: "38. Santa Catarina Ayometla",
      titleAnexo39: "39. Sanctorum de Lázaro Cardenas",
      titleAnexo40: "40. Españita",
      titleAnexo41: "41. Zitlaltepec",
      titleAnexo42: "42. Santa Ana Nopalucan",
      titleAnexo43: "43. Santa Cruz Quilehtla",
      titleAnexo44: "44. San Juan Huactzinco",
      titleAnexo45: "45. Ixtenco",
      titleAnexo46: "46. Atlangatepec",
      titleAnexo47: "47. San José Teacalco",
      titleAnexo48: "48. Acuamanala de Miguel Hidalgo",
      titleAnexo49: "49. Tocatlan",
      titleAnexo50: "50. Benito Juárez",
      titleAnexo51: "51. Cuaxomulco",
      titleAnexo52: "52. San Damian Texoloc",
      titleAnexo53: "53. San Lorenzo Axocomanitla",
      titleAnexo54: "54. Santa Isabel Xiloxoxtla",
      titleAnexo55: "55. Emiliano Zapata",
      titleAnexo56: "56. Muñoz de Domingo Arenas",
      titleAnexo57: "57. Santa Apolonia Teacalco",
      titleAnexo58: "58. San Jeronimo Zacualpan",
      titleAnexo59: "59. Lázaro Cárdenas",
      titleAnexo60: "60. San Lucas Tecopilco",
    },
    {
      id: 225,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "POR EL QUE SE CALENDARIZAN MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 226,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SE READECUA PRESUEPUESTO",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 227,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RETIRO DE PROPAGANDA",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "ANEXO ÚNICO",
    },
    {
      id: 228,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DESIGNACION PERSONAL RECUENTO SANTA CRUZ TECHACHALCO PANOTLA",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DESIGNACION PERSONAL RECUENTO EMILIANO ZAPATA PANOTLA",
    },
    {
      id: 230,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CUMPLIMIENTO SENTENCIA TET-JE-190-2024 ASIGNACION DIPUTACIONES",
    },
    {
      id: 231,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "DESIGNACION PERSONAL RECUENTO SAN BUENAVENTURA ATEMPAN",
    },
    {
      id: 232,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JDC-223-2024 Y ACUMULADOS",
      titleAnexo1: "ACUAMANALA DE MIGUEL HIDALGO",
      titleAnexo2: "APIZACO",
      titleAnexo3: "TOTOLAC",
      titleAnexo4: "YAUHQUEMEHCAN",
      titleAnexo5: "TZOMPANTEPEC",
      titleAnexo6: "SANCTORUM DE LAZARO CARDENAS",
    },
    {
      id: 233,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE ATIENDE ESCRITO DE REPRESENTANTE SUPLENTE PAC",
    },
    {
      id: 234,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JDC-324-2024",
      titleAnexo1: "ANEXO 1. APIZACO",
      titleAnexo2: "ANEXO 2. YAUHQUEMEHCAN",
    },
    {
      id: 235,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "CUMPLIMIENTO DE SENTENCIA SCM-JDC-2142-2024 TZOMPANTEPEC Y XICOHTZINCO",
      titleAnexo1: "ANEXO XICOHTZINCO",
    },
    {
      id: 236,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "INTEGRACION DE LA LXV LEGISLATURA DEL CONGRESO DEL ESTADO DE TLAXCALA",
    },
    {
      id: 237,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE MODIFICA PROCEDIMIENTO PLAZOS DE RETIRO PROPAGANDA",
      titleAnexo1: "ANEXO ÚNICO PROCEDIMIENTO AMPLIACIÓN PLAZOS",
    },
    {
      id: 238,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CALENDARIO PELE 2024",
      titleAnexo1: "ANEXO UNICO CALENDARIO LEGAL PELE 2024",
      titleAnexo2: "FE DE ERRATAS ANEXO ÚNICO ITE-CG 238-2024",
    },
    {
      id: 239,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CONVOCATORIA PARA OBSERVADORES ELECTORALES PELE 2024",
      titleAnexo1: "ANEXO UNICO CONVOCATORIA OE PELE 2024",
      titleAnexo2: "FE DE ERRATAS ANEXO ÚNICO ITE-CG 239-2024",
      titleAnexo3: "ANEXO UNICO ITE-CG 239-2024 FE DE ERRATAS",
    },
    {
      id: 240,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRORROGA DE ACUERDOS PELE 2024",
      titleAnexo1:
        "ANEXO UNO METODOLOGÍA PARA EL MONITOREO DE MEDIOS DE COMUNICACIÓN MASIVA IMPRESOS Y DIGITALES",
      titleAnexo2:
        "ANEXO DOS METODOLOGÍA PARA MONITOREO DE ESPACIOS QUE DIFUNDEN NOTICIAS EN RADIO Y TELEVISIÓN",
      titleAnexo3:
        "ANEXO TRES METODOLOGÍA PARA EL MONITOREO CON PERSPECTIVA DE GÉNERO PELE 2024",
    },
    {
      id: 241,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2025",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES",
    },
    {
      id: 242,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DICTAMEN INCENTIVOS SPEN 2024",
      titleAnexo1: "ANEXO UNICO DICTAMENTE OTORGAMIENTO INCENTIVOS",
    },
    {
      id: 243,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INFORME DEL SISTEMA CONÓCELES",
      titleAnexo1: "ANEXO UNICO INFORME DEL SISTEMA CONÓCELES",
      titleAnexo2: "FE DE ERRATAS ACUERDO ITE-CG 243-2024",
    },
    {
      id: 244,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PERDIDA DE REGISTRO PRD",
    },
    {
      id: 245,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "VIGENCIA DE ACUERDOS PARA PELE 2024",
      titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024 PELE",
    },
    {
      id: 246,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ATRIBUCIONES Y FACULTADES DE LOS C M PELE 2024",
    },
    {
      id: 247,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ADECUACION DE COMISIONES Y JGE",
    },
    {
      id: 248,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "APOYO CON UN TERCERO PARA EL PREP DEL PELE 2024",
    },
    {
      id: 249,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "EXP POS CQD-Q-GJBR-CG-005-2024",
    },
    {
      id: 250,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MANUAL DE REGISTRO PELE 2024",
      titleAnexo1: "ANEXO ÚNICO MANUAL DE REGISTRO PELE 2024",
      titleAnexo2: "FORMATO 1.1 SOLICITUD DE REGISTRO INTEGRANTES AYUNTAMIENTO",
      titleAnexo3: "FORMATO 1.2 SOLICITUD REGISTRO PRESIDENCIA DE COMUNIDAD",
      titleAnexo4: "FORMATO 2.1 CONSTANCIA DE ACEPTACIÓN POSTULACION  IA",
      titleAnexo5: "FORMATO 2.2 CONSTANCIA DE ACEPTACION PC",
      titleAnexo6: "FORMATO 3.1 MANIFESTACIÓN BAJO PROTESTA IA",
      titleAnexo7: "FORMATO 3.2 MANIFESTACION BAJO PROTESTA PC",
      titleAnexo8: "FORMATO 4.1 ESCRITO DE NORMA ESTATUTARIA IA",
      titleAnexo9: "FORMATO 4.2 ESCRITO NORMA ESTATUTARIA PC",
      titleAnexo10: "FORMATO 5.1 ESCRITO BAJO PROTESTA ART 38 F. VII IA",
      titleAnexo11: "FORMATO 5.2 ESCRITO BAJO PROTESTA ART 38 F. VII PC",
    },
    {
      id: 251,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "BODEGA ELECTORAL",
      titleAnexo1: "BODEGA ELECTORAL",
    },
    {
      id: 252,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RESPUESTA A SOLICITUD",
    },
    {
      id: 253,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DICTAMEN PREP PELE 2024 COTAPREP",
      titleAnexo1: "ANEXO ÚNICO DICTAMEN CSSI",
    },
    {
      id: 254,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRESUPUESTO PELE 2024",
    },
    {
      id: 255,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "FINANCIAMIENTO PÚBLICO DE LOS PARTIDOS POLÍTICOS PELE 2024",
      titleAnexo1: "ANEXO UNICO FINANCIAMIENTO PUBLICO PP 2024",
    },
    {
      id: 256,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO RSPT",
    },
    {
      id: 257,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO MORENA",
    },
    {
      id: 258,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO PAC",
    },
    {
      id: 259,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO PVEM",
    },
    {
      id: 260,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO PT",
    },
    {
      id: 261,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PROGRAMA DE GOBIERNO PRI",
    },
    {
      id: 262,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REFORMA LINEAMIENTOS DE DEBATES",
      titleAnexo1: "LINEAMIENTOS DEBATES PELE 2024",
    },
    {
      id: 263,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESIGNACION DE PERSONAL PARA BODEGAS Y SELLADO DE BOLETAS",
      titleAnexo1: "ANEXO UNO PERSONAL PARA ACCESO A BODEGAS ELECTORALES",
      titleAnexo2:
        "ANEXO DOS PERSONAL CONTEO SELLADO Y AGRUPAMIENTO DE BOLETAS ELECTORALES",
    },
    {
      id: 264,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "PROCESO TECNICO OPERATIVO Y UBICACION DE CENTROS DE ACOPIO PELE 2024",
      titleAnexo1: "PTO PELE 2024",
    },
    {
      id: 265,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DIFUSORES OFICIALES PREP PELE 2024",
      titleAnexo1: "ANEXO ÚNICO CARTA COMPROMISO",
      titleAnexo2: "CONVOCATORIA DIFUSORES",
    },
    {
      id: 266,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO A SENTENCIA TET-JE-346-2024 PRD",
    },
    {
      id: 267,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc:
        "REGISTRO DE COALICION SIGAMOS HACIENDO HISTORIA EN TLAXALA PELE 2024",
    },
    {
      id: 268,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "READECUACION DEL PRESUPUESTO DE EGRESOS 2024",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 269,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "DICTAMEN INFORME DEL DISEÑO Y MODELOS DEFINITIVOS DE LA DOCUMENTACIÓN ELECTORAL CON Y SIN EMBLEMAS",
      titleAnexo1:
        "ANEXO ÚNICO DICTAMEN DOC ELECTORAL CON Y SIN EMBLEMAS PELE 2024",
      titleAnexo2:
        "1. DICTAMEN INFORME DE DOCUMENTACIÓN CON Y SIN EMBLEMAS PELE 2024",
      titleAnexo3:
        "2. DISEÑOS DE DOCUMENTACIÓN ELECTORAL CON Y SIN EMBLEMAS PELE 2024",
    },
    {
      id: 270,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MEDIDAS DE SEGURIDAD BOLETAS PELE 2024",
    },
    {
      id: 271,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE DETERMINA FECHA DE INICIO DE PUBLICACIÓN SISTEMA CONÓCELES",
    },
    {
      id: 272,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TOPES DE GASTOS DE CAMPAÑA PELE 2024",
    },
    {
      id: 273,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS PVEM PELE 2024",
    },
    {
      id: 274,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS MORENA PELE 2024",
    },
    {
      id: 275,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS PAC PELE 2024",
    },
    {
      id: 276,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS PT PELE 2024",
    },
    {
      id: 277,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS COALICIÓN PELE 2024",
    },
    {
      id: 278,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO DE CANDIDATURAS PRI PELE 2024",
    },
    {
      id: 279,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "REGISTRO DE CANDIDATURAS PRI PELE 2024 RESERVADA POR RESOLUCIÓN ITE-CG 278/2024",
    },
    {
      id: 280,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "REGISTRO CANDIDATURA COALICIÓN DENOMINADA “SIGAMOS HACIENDO HISTORIA EN TLAXCALA” PELE",
    },
    {
      id: 281,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "REGISTRO CANDIDATURA PRI PELE 2024 RESERVADAS ITE-CG 279-2024",
    },
    {
      id: 282,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "MODIFICACION ESTATUTOS PAC 2024",
    },
    {
      id: 283,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "RESPUESTA A ESCRITO PRESENTADO POR MC",
    },
    {
      id: 284,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "MODELOS OPERATIVOS PARA RECEPCION DE PAQ ELECTORALES",
        titleAnexo1:
        "MODELO OPERATIVO SAN LUCAS TECOPILCO",
        titleAnexo2:
        "MODELO OPERATIVO SANTA MARIA CAPULAC",
    },
    {
      id: 285,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "INFORME DE OTORGAMIENTO DEL SPEN",
        titleAnexo1:
        "INFORME OTORGAMIENTO INCENTIVOS SPEN",
    },
    {
      id: 286,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "DESIGNACION DE CASILLAS PARA VERIFICACION DE MEDIDAS DE SEGURIDAD PELE 2024",
        titleAnexo1:
        "PROCEDIMIENTO DESIGNACION DE CASILLAS PELEL 2024",
    },
    {
      id: 287,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "CASILLAS OBJETO DE RECUENTO DE INTEGRANTES DE AYUNTAMIENTO SAN LUCAS TECOPILCO",
    },
    {
      id: 288,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "CASILLAS OBJETO DE RECUENTO DE TITULARIDAD DE COMUNIDAD SANTA MARIA CAPULAC",
    },
    {
      id: 289,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "DESIGNACION DE PERSONAL QUE AUXILIARA EN SESION PERMANENTE DE COMPUTO",
    },
    {
      id: 290,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "COMPUTO DE RESULTADOS Y DELACRACION DE VALIDEZ DE LA ELECCION DE SAN LUCAS TECOPILCO PELE 2024",
    },
    {
      id: 291,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "COMPUTO DE RESULTADOS Y DECLARACION DE VALIDEZ DE LA ELECCIÓN DE TITULAR DE PRESIDENCIA DE COMUNIDAD DE SANTA MARÍA CAPULAC PELE 2024",
    },
    {
      id: 292,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "DICTAMEN RESPECTO DE LA SOLICITUD DE REGISTRO DEL PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA TLAXCALA, COMO PARTIDO POLÍTICO LOCAL.",
        titleAnexo1:
        "SOLICITUD DE REGISTRO DEL PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA TLAXCALA, COMO PARTIDO POLÍTICO LOCAL.",
    },
    {
      id: 293,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "ACUERDO POR EL QUE SE READECÚA EL PRESUPUESTO DE EGRESOS DE ESTE INSTITUTO PARA EL EJERCICIO FISCAL DOS MIL VEINTICUATRO",
        titleAnexo1:
        "ANEXO UNICO",
    },
    {
      id: 294,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "ASIGNACION DE REGIDURIAS SAN LUCAS TECOPILCO PELE 2024",
    },
    {
      id: 295,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "CONVOCATORIA OBSERVADORES ELECTORALES PELEPJL 2024",
        titleAnexo1:
        "ANEXO UNICO",
    },
    {
      id: 296,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "PROCEDIMIENTO RETIRO DE PROPAGANDA PELE 2024",
        titleAnexo1:
        "ANEXO UNICO",
    },
    {
      id: 297,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "DESTRUCCION MATERIAL Y DOCUMENTACION PELO 2023 -2024 Y PELE 2024",
    },
    {
      id: 298,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "INTEGRACION DE COMISIONES  Y COMITÉS DEL ITE",
    },
    {
      id: 299,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc:
        "MEDIDAS CAUTELARES PES CQD-CA-090-2024",
    },
    {
      id: 300,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "INTEGRACION DE COMISIONES TEMPORALES PELEPJL 2024-2024",
    },
  ],
  
  2023: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE ADECUA EL PRESUPUESTO DE EGRESOS 2023",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE ACTUALZA CALENDARIO DE MULTAS 2023",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBA PROGRAMA PARA OTORGAMIENTO DE TITUTLARIDAD Y PROMOCIÓN DE RANGO SPEN",
      titleAnexo1: "PROGRAMA",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "REFORMA REGLAMENTO INTERIOR DEL ITE",
      titleAnexo1: "REGLAMENTO INTERIOR",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE DESIGNA ÓRGANO DE ENLACE CON LA DESPEN",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBA PROGRAMA DE SEMINARIOS, CONFERENCIAS, COLOQUIOS, DIPLOMADOS, ETC",
      titleAnexo1: "PROGRAMA DE IMPARTICIÓN",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE AUTORIZA AL CONSEJERO PRESIDENTE FIRMA DE TODO TIPO DE CONVENIOS",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DOCUMENTOS DEL PROCESO DE CONSULTA",
      titleAnexo1: "ANEXO UNO. GUÍA DE APOYO E INSTRUMENTOS DIDÁCTICOS",
      titleAnexo2: "ANEXO DOS. CUESTIONARIO CUADERNLLO",
      titleAnexo3: "ANEXO TRES. CARTEL DE PARTICIPACIÓN",
      titleAnexo4: "ANEXO CUATRO. ESPECIFICACIONES TÉCNICAS MATERIALES",
    },
    {
      id: 9,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-PT-CG-006-2022",
      titleAnexo1: "Voto Concurrente",
    },
    {
      id: 10,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-MCCG-007-2022",
      titleAnexo1: "Voto Concurrente",
    },
    {
      id: 11,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-MORENA-CG-008-2022",
      titleAnexo1: "Voto Concurrente",
    },
    {
      id: 12,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-PAC-CG-009-2022",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE APRUEBA CONVOCATORIA OBSERVADORES DE CONSULTA",
      titleAnexo1: "Convocatoria Observadores Fase Consultiva",
      titleAnexo2: "Solicitud de Registro Observadoras/es",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "INTEGRACIÓN COMISIONES, COMITÉS Y JGE",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE APRUEBA DICTAMEN GENERAL DE RESULTADOS EVALUACIÓN SPEN",
      titleAnexo1: "ANEXO ÚNICO. DICTAMEN",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "DESIGNACIÓN DEL PERSONAL QUE INTEGRARÁ LAS MESAS RECEPTORAS DE CONSULTA",
      titleAnexo1: "ANEXO ÚNICO. PERSONAL",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "SE APRUEBA DICTAMEN NO PRESENTACIÓN SOLICITUD DE REGISTRO ORGANIZACIONES CIUDADANAS",
      titleAnexo1:
        "ANEXO. DICTAMEN NO PRESENTACIÓN SOLICITUD DE REGISTRO ORGANIZACIONES CIUDADANAS",
      titleAnexo2: "VOTO CONCURRENTE DEL CONSEJERO HERMENEGILDO NERIA CARREÑO",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "NOMBRAMIENTO COORDINADORA DE GENERO Y NO DISCRIMINACIÓN",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE NOMBRA TITULAR DE LA DOECyEC",
    },
    {
      id: 20,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD-Q-EDT-CG-011-2022",
    },
    {
      id: 21,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD-Q-RT-CG-015-2022",
    },
    {
      id: 22,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD-Q-CCEST-CG-020-2022",
    },
    {
      id: 23,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD-Q-UE-CG-021-2022",
    },
    {
      id: 24,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD-Q-SISI-CG-022-2022",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE APRUEBA DESIGNACIÓN E INCORPORACIÓN PERSONAS SPEN",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "CUMPLIMIENTO SENTENCIA TET-JE-010-2023 Y SE MODIFICA RESOLUCIÓN ITE-CG 09-2023",
    },
    {
      id: 27,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO DE LA ORGANIZACIÓN CIUDADANA ESPACIO DEMOCRÁTICO DE TLAXCALA",
      titleAnexo1: "DICTAMEN FIRMADO",
      titleAnexo2: "Anexo uno de Dictamen. firmado",
      titleAnexo3: "Anexo dos de Dictamen. firmado",
    },
    {
      id: 28,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN ESPACIO DEMOCRÁTICO DE TLAXCALA",
      titleAnexo1: "DICTAMEN",
      titleAnexo2:
        "VOTO RAZONADO DEL CONSEJERO ELECTORAL HERMENEGILDO NERIA CARREÑO",
    },
    {
      id: 29,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO DE LA ORGANIZACIÓN CIUDADANA RENOVEMOS TLAXCALA",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "Anexo uno de Dictamen",
      titleAnexo3: "Anexo dos de Dictamen",
    },
    {
      id: 30,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN RENOVEMOS TLAXCALA A.C",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "Anexo de Dictamen",
      titleAnexo3:
        "VOTO RAZONADO DEL CONSEJERO ELECTORAL HERMENEGILDO NERIA CARREÑO",
    },
    {
      id: 31,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN “UNIFICACIÓN Y EVOLUCIÓN”",
      titleAnexo1: "DICTAMEN CONSOLIDADO",
      titleAnexo2: "Anexo uno de Dictamen",
      titleAnexo3: "Anexo dos de Dictamen",
    },
    {
      id: 32,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO DE LA ORGANIZACIÓN CIUDADANOS POR CONSTITUIRSE EN ENCUENTRO SOLIDARIO TLAXCALA A.C.",
      titleAnexo1: "DICTAMEN CONSOLIDADO",
      titleAnexo2: "Anexo uno del Dictamen",
      titleAnexo3: "Anexo dos del Dictamen",
    },
    {
      id: 33,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN UNIFICACIÓN Y EVOLUCIÓN",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "VOTO RAZONADO CONSEJERO HERMENEGILDO NERIA CARREÑO",
    },
    {
      id: 34,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN CIUDADANOS POR CONSTITUTIRSE EN ENCUENTRO SOLIDARIO TLAXCALA A.C.",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "Anexo de Dictamen",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO DE LA ORGANIZACIÓN CIUDADANA SOCIEDAD INDEPENDIENTE SI",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "Anexo de Dictamen",
    },
    {
      id: 36,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        " SE APRUEBA DICTAMEN RESPECTO DE SOLICITUD DE REGISTRO COMO PPL DE LA ORGANIZACIÓN CIUDANA DENOMINADA SOCIEDAD INDEPENDIENTE SI",
      titleAnexo1: "DICTAMEN",
      titleAnexo2: "Anexo de Dictamen",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SE READECÚA EL PRESUPUESTO DE EGRESOS 2023",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSPENSIÓN FASE DE EJECUCIÓN DEL PROCESO DE CONSULTA",
    },
    {
      id: 39,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "ITE-CG 39-2023 SE CALENDARIZA REMANENTES NO EJERCIDOS Y CANTIDADES A RETENER POR MULTAS",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "POR EL QUE SE CALENDARIZA LA RETENCIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "DICTAMEN CONSOLIDADO RESPECTO DE INFORMES MENSUALES DE RECURSOS ORGANIZACIÓN UNIFICACIÓN Y EVOLUCIÓN ENERO A ABRIL 2023",
      titleAnexo1: "ACUERDO ITE-CG 41-2023 DICTAMEN CONSOLIDADO",
    },
    {
      id: 42,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-AAC-CG-001-2023",
    },
    {
      id: 43,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-API-CG-002-2023",
    },
    {
      id: 44,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-HUA-CG-003-2023",
    },
    {
      id: 45,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-IMM-CG-004-2023",
      titleAnexo1: "FE DE ERRATAS",
    },
    {
      id: 46,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-SLZ-CG-005-2023",
    },
    {
      id: 47,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-NMA-CG-006-2023",
    },
    {
      id: 48,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-TEO-CG-007-2023",
    },
    {
      id: 49,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-TEP-CG-008-2023",
    },
    {
      id: 50,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-TER-CG-009-2023",
    },
    {
      id: 51,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-ZTSS-CG-010-2023",
    },
    {
      id: 52,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-XIC-CG-011-2023",
    },
    {
      id: 53,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-ZAC-CG-012-2023",
    },
    {
      id: 54,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-SJT-CG-013-2023",
    },
    {
      id: 55,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-LC-CG-014-2023",
    },
    {
      id: 56,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-SAN-CG-015-2023",
    },
    {
      id: 57,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-SLA-CG-016-2023",
    },
    {
      id: 58,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SE APRUEBA LA MODIFICACIÓN DE DOCUMENTOS BÁSICOS DEL PAC",
    },
    {
      id: 59,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-PRI-CG-033-2023",
    },
    {
      id: 60,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-PRD-CG-034-2023",
    },
    {
      id: 61,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "POS EXPEDIENTE CQD-Q-MC-CG-035-2023",
    },
    {
      id: 62,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc:
        "SE DECLARA LA PROCEDENCIA LEGAL Y CONSTITUCIONAL DE MODIFICACIÓN DE DOCUMENTOS BÁSICOS DE NUEVA ALIANZA TLAXCALA",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO INFORMES MENSUALES RECURSOS FEB-ABRIL CCEST A.C.",
      titleAnexo1: "DICTAMEN",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO INFORMES MENSUALES RECURSOS FEB-ABRIL EDT A.C.",
      titleAnexo1: "DICTAMEN",
    },
    {
      id: 65,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO INFORMES MENSUALES RECURSOS FEB-ABRIL 2023 RENOVEMOS TLAXCALA",
      titleAnexo1: "DICTAMEN",
    },
    {
      id: 66,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc:
        "SE APRUEBA DICTAMEN CONSOLIDADO INFORMES MENSUALES RECURSOS FEB-ABRIL 2023 SOCIEDAD INDEPENDIENTE SI",
      titleAnexo1: "DICTAMEN",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "RATIFICACIÓN DE LA INSTANCIA INTERNA Y COMISIÓN DEL PREP",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "INTEGRACIÓN COMISIONES TEMPORALES",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE DESIGNA INSTANCIA INTERNA RESPONSABLE DEL SISTEMA CANDIDATAS Y CANDIDATOS, CONÓCELES",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE APRUEBA INFORME RELATIVO A LA VERIFICACIÓN DE CUMPLIMIENTO DE NÚMERO MÍNIMO DE PERSONAS AFILIADAS A PPL",
      titleAnexo1: "Registros con inconsistencias Fuerza por México Tlaxcala",
      titleAnexo2: "Registros con inconsistencias Nueva Alianza Tlaxcala",
      titleAnexo3: "Registros con inconsistencias PAC",
      titleAnexo4: "Registros con inconsistencias Redes Sociales Progresistas",
      titleAnexo5: "Informe general verificación trianual final",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "POR EL QUE SE APRUEBA LA REALIZACIÓN DE LA CONSULTA A PERSONAS, PUEBLOS Y COMUNIDADES INDÍGENAS DEL ESTADO DE TLAXCALA",
      titleAnexo1: "ANEXO 1 Protocolo de la convocatoria",
      titleAnexo2: "ANEXO 2 Convocatoria de consulta",
      titleAnexo3: "ANEXO 3 Guía de preguntas",
      titleAnexo4: "ANEXO 4 Convocatoria Observación",
      titleAnexo5: "ANEXO 4.1 Solicitud de registro observación",
      titleAnexo6: "Anexo Protocolo 1 Instrumentos Jurídicos",
      titleAnexo7: "Anexo Protocolo 2 Comunidades indígenas",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "POR EL QUE SE DA RESPUESTA AL ESCRITO PRESENTADO POR AVELINO MEZA RODRÍGUEZ",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-020-023 EST",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "APROBACIÓN DE CONSULTA A PERSONAS CON DISCAPACIDAD",
      titleAnexo1:
        "ANEXO 1 Protocolo para la consulta a personas con discapacidad",
      titleAnexo2:
        "anexo 1.1 Fundamento Jurídico para la consulta a personas con discapacidad",
      titleAnexo3:
        "ANEXO 1.2 Cuestionario de Consulta a personas con discapacidad",
      titleAnexo4:
        "Anexo 2 Convocatoria de Consulta a personas con discapacidad",
      titleAnexo5:
        "ANEXO 3 Convocatoria Observador(a) de Consulta a personas con discapacidad",
      titleAnexo6:
        "ANEXO 3.1 Solicitud de acreditación para participar observador a personas con discapacidad",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "SE APRUEBA PROYECTO DE PRESUPUESTO DE EGRESOS DE ESTE INSTITUTO PARA EL EJERCICIO FISCAL 2024",
      titleAnexo1: "Anexo 1 Calendario presupuestal",
      titleAnexo2: "Anexo 2 Financiamiento público PP 2024 AO",
      titleAnexo3: "Anexo 3 Financiamiento público PP 2024 OV",
      titleAnexo4: "Anexo 4 Financiamiento público PP 2024 AE",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "SE DETERMINA LA OPERACIÓN DEL SISTEMA CONÓCELES Y SE APRUEBA PLAN DE TRABAJO",
      titleAnexo1: "PLAN DE TRABAJO",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "POR EL QUE SE REQUIERE A FXMT",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE READECÚA EL PRESUPUESTO DE EGRESOS DE ESTE INSTITUTO",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE CALENDARIZAN LOS REMANENTES DE CAMPAÑA 2020-2021",
      titleAnexo1: "Anexo Único remanentes de campaña 2020-2021",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE APRUEBA CALENDARIO ELECTORAL 2023-2024",
      titleAnexo1: "CALENDARIO_LEGAL_2023-2024",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE EMITE CONVOCATORIA A ELECCIONES ORDINARIAS 2024",
      titleAnexo1: "CONVOCATORIA PELO 2023-2024",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE APRUEBA CONVOCATORIA CANDIDATURAS INDEPENDIENTES PELO 2023-2024",
      titleAnexo1:
        "ANEXO UNO CONVOCATORIA CANDIDATURAS INDEPENDIENTES PELO 2023-2024",
      titleAnexo2: "1.1 EBP20232024_ESCRITO BP VPCMrG",
      titleAnexo3: "1.1.1 EBP120232024_ESCRITO BP NO DEUDOR ALIMENTARIO",
      titleAnexo4: "1.2 MC20232024_ESCRITO CONFORMIDAD FISCALIZACIÓN",
      titleAnexo5: "1.3 ME20232024_ESCRITO RECURSOS ILÍCITOS",
      titleAnexo6: "1.4 MI20232024_FORMATO DE MANIFESTACIÓN DE INTENCIÓN",
      titleAnexo7: "1.5 MV20232024_FORMATO DE MANIFESTACIÓN DE VOLUNTAD",
      titleAnexo8: "1.6 SR20232024_FORMATO DE SOLICITUD DE REGISTRO CI",
      titleAnexo9:
        "ANEXO DOS. MODELO ÚNICO DE ESTATUTO DE ASOCIACIÓN CIVIL CANDIDATURAS INDEPENDIENTES",
      titleAnexo10: "ANEXO TRES PORCENTAJE DE APOYO CIUDADANO PELO 2023-2024",
      titleAnexo11: "ANEXO CUATRO. Tope de gastos",
      titleAnexo12: "ACUERDO ITE-CG 82-2023 FE DE ERRATAS",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE APRUEBA METODOLOGÍA DE MONITOREO DE MEDIOS DE COMUNICACIÓN MASIVA, IMPRESOS Y DIGITALE",
      titleAnexo1: "METODOLOGÍA MIyD PELO 2023-2023",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "POR EL QUE SE APRUEBAN LOS LINEAMIENTOS GENERALES DE LIBERTAD DE EXPRESIÓN",
      titleAnexo1: "LINEAMIENTOS",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE APRUEBAN CONFERENCIAS MAGISTRALES LGBTTTIQ+ Y JUVENTUDES",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE APRUEBA DICTAMEN POR REPOSICIÓN DE EVALUACIÓN DE DESEMPEÑO SPEN",
      titleAnexo1: "DICTAMEN EVALUACIÓN 2021-2022_REPOSICIÓN",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE MODIFICA LOS PROGRAMAS TITULARIDAD Y PROMOCIÓN EN RANGO SPEN",
      titleAnexo1: "PROGRAMAS",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "CUMPLIMIENTO SENTENCIA EXP. TET-JE-042-2023, MODIFICA RESOLUCIÓN ITE-CG 43-2023 RESPECTO DEL POS CQD-Q-APÍ-CG-002-2023",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YEDTIH MARTINEZ PINILLO",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "CUMPLIMIENTO SENTENCIA EXP. TET-JE-043-2023, MODIFICA RESOLUCIÓN ITE-CG 56-2023 RESPECTO DEL POS CQD-Q-SAN-CG-015-2023",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YEDTIH MARTINEZ PINILLO",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "CUMPLIMIENTO SENTENCIA EXP. TET-JE-044-2023, MODIFICA RESOLUCIÓN ITE-CG 42-2023 RESPECTO DEL POS CQD-Q-AAC-CG-001-2023",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YEDTIH MARTINEZ PINILLO",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE INTEGRA COMITÉ TÉCNICO ASESOR PARA EL PROGRAMA DE DE RESULTADOS ELECTORALES PRELIMINARES (COTAPREP), PARA EL PELO 2023-2024",
      titleAnexo1:
        "Dictamen de Clasificación de Información Confidencial 06-2023",
      titleAnexo2:
        "Caratula ATI Dictamen de Clasificación de la Información 06-2023",
      titleAnexo3: "1. JOSÉ ALFONSO LIMA GUTIÉRREZ",
      titleAnexo4: "2. Luis Leal Delgado",
      titleAnexo5: "3. Blanca Leticia Dávila Gutiérrez",
      titleAnexo6: "4. Juan Felipe Meza López",
      titleAnexo7: "5. Norberto Sánchez Briones",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE DESIGNA TITULAR UTCE",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE CALENDARIZA CANTIDADES A RETENER POR REMANENTES NO EJERCIDOS EJERCICIOS 2020-2021 DE RESOLICIONES DE INE",
      titleAnexo1:
        "ANEXO ÚNICO. Calendarización remanentes act ord y esp 2020-2021",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE APRUEBA CATÁLOGO DE PROGRAMAS DE RADIO Y TELEVISIÓN QUE DIFUNDEN NOTICIAS A MONITOREAR DEL PELO 2023-2024",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE DECLARA LA PROCEDENCIA RESPECTO A LA MODIFICACIÓN DE DOCUMENTOS BÁSICOS DE RSPT",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBA INFORME FINAL DE CONSULTA EN MATERIA DE ACCIONES AFIRMATIVAS EN LA POSTULACIÓN DE CANDIDATURAS INDÍGENAS",
      titleAnexo1: "Informe final de la Consulta indígena",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBAN PROPUESTAS DE PAUTAS DE RADIO Y TELEVISIÓN DE PP Y CI; DURANTE PRECAMPAÑAS, INTERCAMPAÑAS Y CAMPAÑAS PELO 2023-2024",
      titleAnexo1: "ANEXO UNO TLAX_23-24_01_PEL_1_A_PRE_CC_17d",
      titleAnexo2: "ANEXO DOS TLAX_23-24_01_PEL_3_1_CAM_0CI_CC_30d",
      titleAnexo3: "ANEXO TRES TLAX_23-24_01_PEL_2_INT_CC_99d",
      titleAnexo4: "ANEXO CUATRO TLAX_23-24_01_PEL_3_1_CAM_0CI_CC_30d",
      titleAnexo5: "ANEXO CINCO TLAX_23-24_01_PEL_3_2_CAM_1CI_CC_30d",
      titleAnexo6: "ANEXO SEIS TLAX_23-24_01_PEL_3_3_CAM_2CI_CC_30d",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBA CONVOCATORIA PARA INTEGRACIÓN DE CONSEJOS DISTRITALES Y MUNICIPALES ELECTORALES LOCALES, PARA EL PELO 2023-2024",
      titleAnexo1: "ANEXO UNO CONVOCATORIA CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo2:
        "ANEXO DOS LINEAMIENTOS PARA LA INTEGRACIÓN DE LOS CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo3: "Anexo 1 Solicitud de registro",
      titleAnexo4: "Anexo 2 Resumen curricular",
      titleAnexo5: "Anexo 3 Declaración bajo protesta de decir verdad",
      titleAnexo6: "Anexo 4 Declaración art. 38",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-015-023 Y ACUMULADOS",
      titleAnexo1: "ANEXO NÚMERO TRES OCCCEST_AO",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE APRUEBA LA METODOLOGÍA PARA MONITOREO DE RADIO Y TELEVISIÓN",
      titleAnexo1: "Metodología RyT PELO 2023-2024",
    },
    {
      id: 101,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "POR EL QUE SE  READECUA EL PRESUPUESTO DE EGRESOS 2023",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 102,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "SE DECLARA LA PROCEDENCIA CONSTITUCIONAL Y LEGAL DE MODIFICACIÓN DE DOCUMENTOS BÁSICOS PAC",
    },
    {
      id: 103,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "SE DECLARA LA PROCEDENCIA CONSTITUCIONAL Y LEGAL DE MODIFICACIÓN DE DOCUMENTOS BÁSICOS FXMT",
    },
    {
      id: 104,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBA DICTAMEN DOCUMENTACIÓN SIN EMBLEMAS Y MATERIALES ELECTORALES",
      titleAnexo1: "DICTAMEN DOCUMENTACIÓN SIN EMBLEMAS Y MATERIALES",
      titleAnexo2: "INFORME DE DOCUMENTACIÓN SIN EMBLEMAS Y MATERIALES",
      titleAnexo3: "ANEXO 1_ DOCUMENTACIÓN SIN EMBLEMAS",
      titleAnexo4: "ANEXO 2_ MATERIALES ELECTORALES",
    },
    {
      id: 105,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JDC-056-2023 Y ACUMULADOS",
      titleAnexo1:
        "CONVOCATORIA POSTULACIONES A CANDIDATURAS INDEPENDIENTES ACTUALIZADO 2024 16112023",
      titleAnexo2: "ANEXO 3 PORCENTAJE APOYO CIUDADANO",
    },
    {
      id: 106,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE DETERMINA QUE PREP SE REALIZARÁ CON APOYO DE UN TERCERO",
    },
    {
      id: 107,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE APRUEBAN LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
      titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
      titleAnexo2: "ANEXO UNO",
      titleAnexo3: "ANEXO DOS",
      titleAnexo4: "ANEXO TRES",
      titleAnexo5: "FE DE ERRATAS",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE APRUEBAN LINEAMIENTOS DE PARIDAD DE GÉNERO",
      titleAnexo1:
        "ANEXO ÚNICO LINEAMIENTOS DE PARIDAD DE GÉNERO 2023 CON MODIFICACIONES",
      titleAnexo2: "ANEXO 1 DIP_LOC_2021",
      titleAnexo3: "ANEXO 2  DE LINEAMIENTOS DE PARIDAD",
    },

    {
      id: 109,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROCEDENCIA DE MANIFESTACIONES DE INTENCIÓN DE ASPIRANTES A CI",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE EMITE CONVOCATORIA PARA OBSERVADOR U OBSERVADORA PARA PELO 2023-2024",
      titleAnexo1: "SE EMITE CONVOCATORIA OPLE _ OBSERVADORES",
    },
    {
      id: 111,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc:
        "PROCEDENCIA DE MANIFESTACIONES RESERVADAS EN LA RESOLUCIÓN ITE-CG 109-20213 DE CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE REANUDA LA FASE DE EJECUCIÓN DE CONSULTA A COMUNIDADES POR USOS Y COSTUMBRES",
      titleAnexo1: "Protocolo para el proceso de Consulta UyC",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE OTORGA ATRIBUCIONES A LA DPAyF DEL ITE",
    },
    {
      id: 114,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE DETERMINA EL NÚMERO DE REGIDURÍAS A ELEGIR EN CADA AYUNTAMIENTO EN PELO 2023-2024",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE DETERMINA LÍMITES DE FINANCIAMIENTO PRIVADO DE PARTIDOS POLÍTICOS",
    },
    {
      id: 116,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE APRUEBAN LOS TOPES DE GASTOS DE PRECAMPAÑA",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "POR EL QUE SE ACTUALIZA CALENDARIZACIÓN DE MULTAS DE PT Y SE DA RESPUESTA A PP",
      titleAnexo1: "Calendarización",
    },
    {
      id: 118,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE APRUEBAN DICTÁMENES PARA OTORGAMIENTO DE INCENTIVOS CORRESPONDIENTES AL EJERCICIO 2022 DEL PERSONAL SPEN",
      titleAnexo1: "ANEXO UNO. DICTAMEN OTORGAMIENTO INCENTIVOS MIGUEL",
      titleAnexo2:
        "ANEXO DOS. DICTAMEN OTORGAMIENTO INCENTIVOS EXCELENCIA MARITZA",
    },
    {
      id: 119,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SUSTITUCIÓN INTEGRANTE DEL COTAPREP",
      titleAnexo1: "RESUMEN CURRICULAR GILBERTO VARELA CARMONA",
    },
    {
      id: 120,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE APRUEBA EL PROCESO TÉCNICO OPERATIVO DEL SISTEMA CONÓCELES",
      titleAnexo1: "PROCESO TÉCNICO OPERATIVO",
    },
    {
      id: 121,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE APRUEBA METODOLOGÍA PARA MONITOREO CON PERSPECTIVA DE GÉNERO A MEDIOS DE COMUNICACIÓN MASIVA",
      titleAnexo1: "METODOLOGÍA",
    },
    {
      id: 122,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE APRUEBAN LINEAMIENTOS PARA GARANTIZAR IMPARCIALIDAD, NEUTRALIDAD Y EQUIDAD EN CONTIENDA ELECTORAL DE PERSONAS SERVIDORAS PÚBLICAS",
      titleAnexo1: "ANEXO ÚNICO. LINEAMIENTOS",
    },
    {
      id: 123,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "POS CQD-Q-CDPM-CG-041-2023",
    },
    {
      id: 124,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "POS CQD-Q-ACR-CG-032-2023",
    },
    {
      id: 124,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "POS CQD-Q-ACR-CG-032-2023",
    },
  ],

  2022: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN DE PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO UNO CALENDARIZADO",
      titleAnexo2: "ANEXO DOS REMANENTE CONVENIO INE",
      titleAnexo3: "ANEXO TRES ISR",
      titleAnexo4: "FE DE ERRATAS",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACTUALIZACIÓN CALENDARIZACIÓN DE MULTAS 2022",
      titleAnexo1: "CALENDARIZACIÓN DE MULTAS",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "APROBACIÓN DE PROGRAMA DE IMPARTICIÓN DE CURSOS",
      titleAnexo1: "PROGRAMA DE CAPACITACIÓN 2022",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO REGISTRO REDES SOCIALES PROGRESISTAS TLAXCALA",
      titleAnexo1: "DICTAMEN CPPPAyF RESPECTO REGISTRO RSP TLAXCALA",
    },
    {
      id: 5,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc:
        "SE APRUEBA DICTAMEN RESPECTO REGISTRO FUERZA POR MÉXICO TLAXCALA",
      titleAnexo1: "DICTAMEN CPPPAyF FUERZA POR MÉXICO TLAXCALA",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "REDISTRIBUCIÓN DE PRERROGATIVAS DE PARTIDOS POLÍTICOS",
      titleAnexo1: "ANEXO 1 ORDINARIAS",
      titleAnexo2: "ANEXO 2 ESPECÍFICAS",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACTUALIZA CALENDARIZACIÓN MULTAS",
      titleAnexo1: "ANEXO ÚNICO. CALENDARIZACIÓN",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ÁREA COORDINADORA DE ARCHIVOS",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "APROBACIÓN DE FORMATOS PARA ORGANIZACIONES INTERESADAS PARA CONSTITUIRSE COMO PPL",
      titleAnexo1: "1. PROPUESTA DE CALENDARIO DE ASAMBLEAS CONSTITUTIVAS",
      titleAnexo2: "2. LISTA DE PERSONAS AFILIADAS",
      titleAnexo3: "3. SOLICITUD DE ASAMBLEA DISTRITAL-MUNICIPAL",
      titleAnexo4: "4. Solicitud de Asamblea Estatal Constitutiva",
      titleAnexo5:
        "5. Aviso de cancelación y reprogramación de asamblea estatal constitutiva",
      titleAnexo6:
        "6. Aviso de cancelación y reprogramación de asamblea (municipal o distrital)",
      titleAnexo7: "7. FORMATO DE SOLICITUD DE REGISTRO",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "RESPUESTA SOLICITUD PRESIDENTE COMUNIDAD CUAUHTENCO",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "ACATAMIENTO A SENTENCIA DENTRO DEL EXP. TET-JDC-20-2020 Y ACUMULADO TET-JDC-32-2020",
    },
    {
      id: 12,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-MCH-CG-025-2020 Y ACUM. PNAT",
    },
    {
      id: 13,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "POS CQD-Q-RCGA-CG-02-2021 Y ACUM. PAC",
      titleAnexo1: "VOTO PARTICULAR CONSEJERA PRESIDENTA ELIZABETH P. M.",
      titleAnexo2: "VOTO PARTICULAR CONSEJERA YEDITH M. P.",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE APRUEBA PROGRAMA ANUAL DE TRABAJO RED DE MUJERES ELECTAS",
      titleAnexo1: "PROGRAMA RED DE MUJERES ELECTAS DE TLAXCALA 2022",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "SE APRUEBAN LINEAMIENTOS DE CONCILIACIÓN Y CONFLICTOS LABORALES DE LA RAMA ADMINISTRATIVA",
      titleAnexo1: "ANEXO LINEAMIENTOS RAMA ADMINISTRATIVA",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE APRUEBA DESTRUCCIÓN DE DOCUMENTACIÓN DEL PEE 2021",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "SE APRUEBA DICTAMEN SPEN DEL PERIODO SEPTIEMBRE 2020 A AGOSTO 2021",
      titleAnexo1: "RESULTADOS DE LA EVALUACIÓN SPEN-6",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "SE APRUEBAN CRITERIOS PARA CALENDARIZACIÓN DE ASAMBLEAS CONSTITUTIVAS",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE ADMITEN ESCRITOS DE INTENCIÓN DE ORGANIZACIONES",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
      titleAnexo3: "ANEXO 3",
      titleAnexo4: "ANEXO 4",
      titleAnexo5: "ANEXO 5",
      titleAnexo6: "ANEXO 6",
      titleAnexo7: "ANEXO 7",
      titleAnexo8: "ANEXO 8",
      titleAnexo9: "ANEXO 9",
      titleAnexo10: "ANEXO 10",
      titleAnexo11: "ANEXO 11",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE ADMITEN ESCRITOS DE INTENCIÓN DE ORGANIZACIONES",
      titleAnexo1:
        "ANEXO 1 ESTUDIO Y ANÁLISIS DE LA ORGANIZACIÓN PARTIDO PROGRESISTA POPULAR",
      titleAnexo2:
        "ANEXO 2 ESTUDIO Y ANÁLISIS DE LA ORGANIZACIÓN CIUDADANOS EN CONSTITUIRSE EN ENCUENTRO SOLIDARIO TLAXCALA A.C.",
      titleAnexo3:
        "ANEXO 3 ESTUDIO Y ANÁLISIS DE LA ORGANIZACIÓN UNIFICACIÓN Y EVOLUCIÓN",
      titleAnexo4: "ANEXO 4",
      titleAnexo5: "ANEXO 5",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "SE APRUEBAN DICTÁMENES Y SE DESECHAN ESCRITOS DE INTENCIÓN DE ORGANIZACIONES",
      titleAnexo1: "ANEXO 1 DICTAMEN CONVICCIÓN MEXICANA",
      titleAnexo2: "ANEXO 2 DICTAMEN VÍA DEMOCRÁTICA ACTIVA TLAXCALA",
      titleAnexo3: "ANEXO 3 DICTAMEN ORGANIZACIÓN METAMORFOSIS UNIDOS",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DESIGNACIÓN TITULAR ÁREA TÉCNICA DE INFORMÁTICA",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE APRUEBA EL REGLAMENTO DE ARCHIVOS",
      titleAnexo1: "REGLAMENTO",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CALENDARIZACIÓN MULTAS",
      titleAnexo1: "ANEXO ÚNICO. CALENDARIZADO",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO ÚNICO. ADECUACIÓN.",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "CREACIÓN PROVISIONAL DE COORDINACIÓN DE GÉNERO Y NO DISCRIMINACIÓN[1670]",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DESIGNACION DE TITULAR DEL ÁREA TÉCNICA DE CONSULTA CIUDADANA",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ANEXO ÙNICO LINEAMIENTOS ASAMBLEAS",
      titleAnexo1: "SE APRUEBAN LINEAMIENTOS QUE REGULAN ASAMBLEAS",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "ACUERDO DE REQUERIMIENTO A ORGANIZACIONES EN MATERIA DE FISCALIZACIÓN",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DICTAMEN DE DESINCORPORACIÓN DE BIENES MUEBLES",
      titleAnexo1:
        "SE APRUEBA DICTAMEN RESPECTO DE DESINCORPORACIÓN DE BIENES MUEBLES",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "APROBACIÓN DEL PROTOCOLO DE CONSULTA COMUNIDADES USOS U COSTUMBRES",
      titleAnexo1: "ANEXO 1 PROTOCOLO",
      titleAnexo2: "ANEXO 2 INFORME DE FASE PREVIA",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESPUESTA A ESCRITO DE MARCO ANTONIO RODRÍGUEZ PÉREZ",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "APROBACIÓN PROTOCOLO SANITARIO Y MANUAL OPERATIVO CONSULTA USOS Y COSTUMBRES",
      titleAnexo1: "MANUAL OPERATIVO PARA EL DESARROLLO DE CONSULTAS",
      titleAnexo2: "PROTOCOLO DE SEGURIDAD SANITARIA CONSULTA",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "VERSIÓN PUBLICA_MC",
      titleAnexo1:
        "DICTAMEN DE CLASIFICACIÓN DE INFORMACIÓN CONFIDENCIAL 01-2022",
      titleAnexo2: "FE DE ERRATAS MEDIDAS CAUTELARES Y DE PROTECCION",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SE DA RESPUESTA A SOLICITUDES DE ORGANIZACIONES",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SE DA CUMPLIMIENTO A LA SENTENCIA DENTRO DEL EXPEDIENTE TET-JDC-27-2022",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "NUEVO REQUERIMIENTO ORGANIZACIONES EN MATERIA DE FISCALIZACIÓN",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "READECUACIÓN PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO UNO DISTRIBUCIÒN ISR",
      titleAnexo2: "ANEXO DOS ADECUACIÓN PRESUPUESTO 2022",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "CREACIÓN DE LA PLAZA DEL CARGO DE COORDINACIÓN FORTALECIMIENTO DE SISTEMAS NORMATIVOS PLURICULTURALES",
      titleAnexo1: "ANEXO ÚNICO. ORGANIGRAMA",
      titleAnexo2: "FE DE ERRATAS",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "AJUSTES AL PROTOCOLO PARA EL PROCESO DE CONSULTA A COMUNIDADES POR USOS Y COSTUMBRES",
      titleAnexo1: "ANEXO ÚNICO. PROTOCOLO-AJUSTE PLAZOS",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "READECUACIÓN DE PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CALENDARIZACIÓN RETENCIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "PROCEDENCIA MODIFICACIÓN ESTATUTOS RSPT",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "",
      nameDoc:
        "SE APRUEBA EL DICTAMEN DE LA DPAYF RESPECTO DE DESINCORPORACIÓN EQUIPO DE CÓMPUTO Y TI",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "SE APRUEBAN DICTÁMENES PARA OTORGAMIENTO DE INCENTIVOS DEL PERSONAL DEL SPEN",
      titleAnexo1:
        "ANEXO 1 DICTAMEN PARA EL OTORGAMIENTO DE INCENTIVOS MIGUEL PEREZ CASTILLA",
      titleAnexo2:
        "ANEXO 2 DICTAMEN PARA EL OTORGAMIENTO DE INCENTIVOS MARITZA_VERA_DELGADILLO",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "RESPUESTA A CONSULTA DE ORGANIZACIÓN CIUDADANA UNIFICACIÓN Y EVOLUCIÓN",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PONDERACIONES EVALUACIÓN TRIANUAL DE DESEMPEÑO MSPEN",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "RESPUESTA A OFICIO PRESENTADO POR EL C. JUAN MELÉNDEZ BELLO, PRESIDENTE INTERINO DE SAN PEDRO TLALCUAPAN, CHIAUTEMPAN.",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE READECÚA EL PRESUPUESTO DE EGRESOS DE ESTE INSTITUTO EJERCICIO 2022",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "RESPUESTA A SOLICITUD DE LA ORGANIZACIÓN UNIFICACIÓN Y EVOLUCIÓN",
      titleAnexo1: "ANEXO ÚNICO.",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "CANTIDADES A RETENER POR CONCEPTO DE RETIRO DE PROPAGANDA A PP Y CI",
      titleAnexo1: "ANEXO 1 INFORME RETIRO DE PROPAGANDA PELO 2020-2021",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "DESIGNA A LA O EL CONSEJERO ELECTORAL QUE OCUPARÁ DE MANERA TEMPORAL LA PRESIDENCIA DEL INSTITUTO",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE ADECUAN COMISIONES, COMITÉS Y JGE",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE APRUEBA PROYECTO DE PRESUPUESTO DE EGRESOS 2023",
      titleAnexo1: "ANEXO 1 FINANCIAMIENTO PÚBLICO PP AO 2023",
      titleAnexo2: "ANEXO 2 FINANCIAMIENTO PÚBLICO PP AE 2023",
      titleAnexo3: "ANEXO 3 CALENDARIZADO",
    },
    {
      id: 56,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "SEP",
      nameDoc: "MODIFICACIÓN DOCUMENTOS BÁSICOS FXMT",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE APRUEBA INFORME DE OTORGAMIENTO DE INCENTIVOS 2022 SPEN",
      titleAnexo1: "INFORME DE OTORGAMIENTO DE INCENTIVOS 2022 PERSONAL SPEN",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE APRUEBA TABLA DE EQUIVALENCIAS MSPEN",
      titleAnexo1:
        "TABLA EQUIVALENCIAS VIABILIDAD CAMBIO DE ADSCRIPCION O ROTACION PMSPEN",
    },
    {
      id: 60,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "SEP",
      nameDoc: "VERSIÓN PÚBLICA",
      titleAnexo1:
        "DICTAMEN DE CLASIFICACIÓN DE INFORMACIÓN CONFIDENCIAL 05-2022",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "RESPUESTA A ESCRITO DE DIVERSAS PERSONAS TITULARES DE PRESIDENCIAS DE COMUNIDAD",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE DA RESPUESTA A PRESIDENCIAS DE COMUNIDAD RESPECTO A COORDINACIÓN PARA EL FORTALECIMIENTO DE SISTEMAS NORMATIVOS PLURICULTURALES",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SE APRUEBA GUÍA OPERATIVA Y CONVOCATORIA PARA FASE INFORMATIVA, DELIBERATIVA Y CONSULTIVA DE PROCESO DE CONSULTA A COMUNIDADES POR USOS Y COSTUMBRES",
      titleAnexo1: "ANEXO UNO. GUÍA OPERATIVA",
      titleAnexo2: "ANEXO DOS. CONVOCATORIA FASES INFORMATIVA Y CONSULTIVA",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "ACTUALIZACIÓN DE GUÍA DE ACTUACIÓN PARA LA PREVENCIÓN, ATENCIÓN Y ERRADICACIÓN DE VPCMRG",
      titleAnexo1: "GUÍA DE ACTUACIÓN",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBAN CONVENIOS Y SE AUTORIZA FIRMA DE PRESIDENTE PROVISIONAL",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE CALENDARIZA RETENCIÓN DE MULTAS PREVISTAS EN RESOLUCIÓN INE-CG113-2022",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DESIGNACIÓN PERSONA TITULAR DE LA SECRETARÍA EJECUTIVA DEL ITE",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DESIGNACIÓN TITULAR DEL ÁREA TÉCNICA DE CONSULTA CIUDADANA",
    },
  ],

  2021: [
    {
      id: 1,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "COALICIONES UNIDOS POR TLAXCALA PELO 2020-2021",
    },
    {
      id: 2,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "COALICIÓN JUNTOS HAREMOS HISTORIA EN TLAXCALA PELO 2020-2021",
    },
    {
      id: 3,
      typeDoc: "",
      monthDoc: "ENE",
      nameDoc: "APLICACIÓN MÓVIL PARA CAPTACIÓN DE APOYO",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "POR EL QUE SE DA RESPUESTA A SOLICITUD DE JOSÉ JORGE MORENO DURÁN",
      titleAnexo1: "ANEXO FOLIO 1852-2020",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PROCESO TÉCNICO OPERATIVO PREP",
      titleAnexo1: "ANEXO PROCESO TÉCNICO OPERATIVO PREP",
    },
    {
      id: 6,
      typeDoc: "",
      monthDoc: "ENE",
      nameDoc:
        "MODIFICACIÓN DE CONVOCATORIA DE CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo1: "ANEXO UNO ADENDA A LA CONVOCATORIA",
      titleAnexo2: "ANEXO DE LA CONVOCATORIA. SOLICITUD",
      titleAnexo3: "ANEXO DOS. CD Y CM",
    },
    {
      id: 7,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "MEDIDAS CAUTELARES PES CQD-PE-JFSM-CG-001-2021",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN AL PRESUPUESTO 2021",
      titleAnexo1: "ANEXO 1 ADECUACIÓN AL PRESUPUESTO 2021",
      titleAnexo2: "ANEXO 2 ACTIVIDADES ORDINARIAS",
      titleAnexo3: "ANEXO 3 OBTENCIÓN DEL VOTO",
      titleAnexo4: "ANEXO 4 ACTIVIDADES ESPECÍFICAS",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CALENDARIZACIÓN MULTAS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PROGRAMA DE CAPACITACIÓN",
      titleAnexo1: "ANEXO ÚNICO PROGRAMA DE CAPACITACIÓN 2021",
      titleAnexo2: "ITE-CG 10-2021 VOTO CONCURRENTE",
    },
    {
      id: 11,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-PRD-CG-009-2020",
    },
    {
      id: 12,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-PRD-CG-010-2020",
    },
    {
      id: 13,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-CG-017-2020",
    },
    {
      id: 14,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-CG-018-2020",
    },
    {
      id: 15,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-CG-019-2020",
    },
    {
      id: 16,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "POS CQD-Q-CG-020-2020",
    },
    {
      id: 17,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "MEDIDAS CAUTELARES",
    },
    {
      id: 18,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "MEDIDAS CAUTELARES",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "POR EL QUE SE DA RESPUESTA A ASPIRANTE A CANDIDATO INDEPENDIENTE",
    },
    {
      id: 20,
      typeDoc: "",
      monthDoc: "ENE",
      nameDoc: "REFORMA EL REGLAMENTO DE QUEJAS Y DENUNCIAS DEL ITE",
      titleAnexo1: "ANEXO UNO REFORMA",
      titleAnexo2: "ANEXO DOS LINEAMIENTOS REGISTRO PERSONAS SANCIONADAS",
      titleAnexo3: "ANEXO 1 DE LINEAMIENTOS",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "POR EL QUE SE APRUEBA GUÍA DE ACTUACIÓN PARA PREVENCIÓN, A TENCIÓN Y ERRADICACIÓN DE LA VIOLENCIA POLÍTICA CONTRA LAS MUJERES EN RAZÓN DE GÉNERO",
      titleAnexo1:
        "ANEXO ÚNICO GUÍA DE ACTUACIÓN PARA LA PREVENCIÓN Y ATENCIÓN DE LA VIOLENCIA POLÍTICA CONTRA LAS MUJERES EN RAZÓN DE GÉNERO",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "POR EL QUE SE MODIFICAN LINEAMIENTOS DE REGISTRO DE CANDIDATURAS",
      titleAnexo1: "ANEXO 1 LINEAMIENTOS",
    },
    {
      id: 23,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES CQD PE PES CG 008 2021",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE APRUEBA UBICACIÓN DE CATD 2021",
    },
    {
      id: 25,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES CQD PE PES CG 009 2021",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE DESIGNA ENTE AUDITOR PARA EL PREP",
      titleAnexo1: "ANEXO. INFORME ENTE AUDITOR_IIPREP",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "RESPUESTA A INDEPENDIENTES",
    },
    {
      id: 28,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG  010 2021",
    },
    {
      id: 29,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 012 2021",
    },
    {
      id: 30,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG  013 2021",
    },
    {
      id: 31,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG  014 2021",
    },
    {
      id: 32,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS PES CQD PE PES CG 017 2021",
    },
    {
      id: 33,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS PES CQD PE PES CG 018 2021",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "RESPUESTA A ESCRITOS DIVERSIDAD SEXUAL",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SE AJUSTA PLAZO PARA VERIFICACIÓN DE APOYO CIUDADANO",
    },
    {
      id: 36,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "RESPUESTA A JOSÉ JORGE MORENO DURÁN",
    },
    {
      id: 37,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 021 2021",
    },
    {
      id: 38,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 022 2021",
    },
    {
      id: 39,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG  025 2021",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DESIGNACIÓN E INCORPORACIÓN SPEN MARIANA MONTIEL SOSA",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DOCUMENTACIÓN SIN EMBLEMAS Y MATERIALES ELECTORALES.",
      titleAnexo1: "DICTAMEN DOCUMENTACIÓN SIN EMBLEMAS Y MATERIAL ELECTORAL",
      titleAnexo2: "INFORME DOCUMENTACIÓN SIN EMBLEMAS Y MATERIAL ELECTORAL",
      titleAnexo3: "DOCUMENTACIÓN SIN EMBLEMAS Y MATERIALES ELECTORALES 2021",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "APRUEBAN LINEAMIENTOS PARA DESARROLLO DE CÓMPUTOS ELECTORALES Y CUADERNILLO DE VOTOS V Y N.",
      titleAnexo1: "LINEAMIENTOS",
      titleAnexo2: "CUADERNILLO",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "MANUAL Y PROTOCOLO REGISTRO DE CANDIDATURAS",
      titleAnexo1:
        "MANUAL DE REGISTRO DE CANDIDATURAS Y ANEXOS_ PELO 2020 2021",
      titleAnexo2: "PROTOCOLO SANITARIO PARA EL REGISTRO DE CANDIDATURAS",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "RESPUESTA A DIPUTADOS",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "READECUACIÓN PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "LINEAMIENTOS PARA REGULAR EL ACTUAR DE SERVIDORES PÚBLICOS QUE NO SE SEPAREN DEL CARGO Y CONTIENDAN EN EL PELO 2020 2021",
    },
    {
      id: 47,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 030 2021",
    },
    {
      id: 48,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 031 2021",
    },
    {
      id: 49,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 032 2021",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "MODIFICACIÓN DE INTEGRACIÓN DEL COTAPREP",
      titleAnexo1: "CV JUANA COCOLETZI COCOLETZI",
      titleAnexo2: "FORMATO JUANA COCOLETZI COCOLETZI",
      titleAnexo3: "CV MARÍA FABIOLA CAMARILLO SÁNCHEZ",
      titleAnexo4: "FORMATO MARÍA FABIOLA CAMARILLO SÁNCHEZ",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "CUMPLIMIENTO DE PORCENTAJE DE APOYO CIUDADANO CANDIDATURAS INDEPENDIENTES",
      titleAnexo1: "REPORTE FINAL APOYO CIUDADANO",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "REFORMA AL REGLAMENTO DE CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "INTEGRACIÓN DE CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES LISTA DE RESERVA",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "TOPES DE GASTO DE CAMPAÑA 2020 2021",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
      titleAnexo3: "ANEXO TRES",
    },
    {
      id: 55,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 035 2021",
    },
    {
      id: 56,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 036 2021",
    },
    {
      id: 57,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE PES CG 037 2021",
    },
    {
      id: 58,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD Q JPNS CG 011 2020",
    },
    {
      id: 59,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD Q CG 012 2020",
    },
    {
      id: 60,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD Q RACF CG 014 2020",
    },
    {
      id: 61,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD Q MLSP CG 016 2020",
    },
    {
      id: 62,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "POS CQD Q MLSP CG 016 2020",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE APRUEBA CONVOCATORIA SE Y CAES",
      titleAnexo1: "CONVOCATORIA DE SE Y CAE",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CRITERIOS ESPECÍFICOS DEBATES GUBERNATURA 20 21",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "MEDIDAS CAUTELARES PES CQD PE NCS CG 034 2021",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "DESIGNACIÓN PERSONAL AUTORIZADO PARA ACCEDER A BODEGAS ELECTORALES",
      titleAnexo1: "ANEXO ÚNICO PERSONAL AUTORIZADO PARA INGRESAR A LA BODEGA",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CONVOCATORIA DE CAE Y SE AMPLIACIÓN PLAZO",
      titleAnexo1: "AMPLIACIÓN DE PLAZO RECLUTAMIENTO SE Y CAE LOCALES",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PAN",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PRI",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PRD",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PT",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PVEM",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL MC",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PAC",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL PS",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL MORENA",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL NUEVA ALIANZA TLAXCALA",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL ENCUENTRO SOCIAL TLAXCALA",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL IMPACTO SOCIAL",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL ENCUENTRO SOLIDARIO",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL RSP",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ELECTORAL FUERZA POR MÉXICO",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PAN",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PRI",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PRD",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PT",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PVEM",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN MC",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PAC",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PS",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN MORENA",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN NUEVA ALIANZA TLAXCALA",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN ENCUENTRO SOCIAL TLAXCALA",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN IMPACTO SOCIAL",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN ENCUENTRO SOLIDARIO",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN RSP",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA GOBIERNO COMÚN FUERZA POR MÉXICO",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SUSTITUCIONES CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "SE CALENDARIZA RETENCIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO MULTAS",
    },
    {
      id: 100,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "REGISTRO DE CANDIDATURA GUBERNATURA COALICIÓN UNIDOS POR TLAXCALA",
    },
    {
      id: 101,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "REGISTRO DE CANDIDATURA GUBERNATURA COALICIÓN JUNTOS HAREMOS HISTORIA",
    },
    {
      id: 102,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATURA GUBERNATURA MOVIMIENTO CIUDADANO",
    },
    {
      id: 103,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATURA GUBERNATURA IMPACTO SOCIAL SI",
    },
    {
      id: 104,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "REGISTRO DE CANDIDATURA GUBERNATURA PARTIDO ENCUENTRO SOLIDARIO",
    },
    {
      id: 105,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATURA GUBERNATURA RSP",
    },
    {
      id: 106,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATURA GUBERNATURA FUERZA POR MÉXICO",
    },
    {
      id: 107,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PAN",
      titleAnexo1: "DICTAMEN PARTIDO ACCIÓN NACIONAL",
    },
    {
      id: 108,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PRI",
      titleAnexo1: "DICTAMEN PARTIDO REVOLUCIONARIO INSTITUCIONAL",
    },
    {
      id: 109,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN DIPUTACIONES PRD",
      titleAnexo1: "DICTAMEN PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA",
    },
    {
      id: 110,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PAC",
      titleAnexo1: "DICTAMEN ALIANZA CIUDADANA",
    },
    {
      id: 111,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PS",
      titleAnexo1: "DICTAMEN PARTIDO SOCIALISTA",
    },
    {
      id: 112,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIÓN COALICIÓN UNIDOS POR TLAXCALA",
    },
    {
      id: 113,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES MC",
      titleAnexo1: "DICTAMEN MOVIMIENTO CIUDADANO",
    },
    {
      id: 114,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES ENCUENTRO SOLIDARIO",
      titleAnexo1: "DICTAMEN ENCUENTRO SOLIDARIO",
    },
    {
      id: 115,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES IMPACTO SOCIAL",
      titleAnexo1: "DICTAMEN IMPACTO SOCIAL",
      titleAnexo2: "FE DE ERRATAS",
    },
    {
      id: 116,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES INDEPENDIENTES",
    },
    {
      id: 117,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES MORENA",
      titleAnexo1: "DICTAMEN MORENA",
    },
    {
      id: 118,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PT",
      titleAnexo1: "DICTAMEN PARTIDO DEL TRABAJO",
    },
    {
      id: 119,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO PANALTLAX",
      titleAnexo1: "DICTAMEN NUEVA ALIANZA",
    },
    {
      id: 120,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIÓN COALICIÓN JUNTOS HAREMOS HISTORIA TLAXCALA",
    },
    {
      id: 121,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PVEM",
      titleAnexo1: "DICTAMEN PARTIDO VERDE ECOLOGISTA DE MÉXICO",
    },
    {
      id: 122,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PESTLAX",
      titleAnexo1: "DICTAMEN ENCUENTRO SOCIAL",
    },
    {
      id: 123,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES RSP",
      titleAnexo1: "DICTAMEN REDES SOCIALES PROGRESISTAS",
    },
    {
      id: 124,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DIPUTACIONES PFXM",
      titleAnexo1: "DICTAMEN FUERZA POR MÉXICO",
    },
    {
      id: 125,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "RECOMENDACIONES DE SEGURIDAD SANITARIA PARA CAMPAÑAS ELECTORALES",
      titleAnexo1: "RECOMENDACIONES SANITARIAS",
    },
    {
      id: 126,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DE CONSEJOS MUNICIPALES 2021",
    },
    {
      id: 127,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DE CONSEJOS MUNICIPALES 2021",
    },
    {
      id: 128,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ADECUACIÓN DE COMISIÓN DE DEBATES",
    },
    {
      id: 129,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DOCUMENTACIÓN CON EMBLEMAS",
      titleAnexo1: "DICTAMEN DOCUMENTACIÓN CON EMBLEMAS",
      titleAnexo2: "INFORME DOCUMENTACIÓN CON EMBLEMAS",
      titleAnexo3: "ANEXO DEL DICTAMEN",
    },
    {
      id: 130,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "INICIO, CIERRE Y ACTUALIZACIONES DE DATOS PREP 2021",
    },
    {
      id: 131,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SE DESIGNAN MODERADORES DE DEBATES GUBERNATURA",
    },
    {
      id: 132,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AUTO ADSCRIPCIÓN DE GÉNERO",
      titleAnexo1: "LINEAMIENTOS",
      titleAnexo2: "FORMATO DE MANIFESTACIÓN DE AUTO ADSCRIPCIÓN DE GÉNERO",
      titleAnexo3: "VOTO CONCURRENTE",
    },
    {
      id: 133,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DE CONSEJOS DTOS Y MUNICIPALES 2021",
    },
    {
      id: 134,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES MORENA",
      titleAnexo1: "DICTAMEN MORENA",
    },
    {
      id: 135,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES PT",
      titleAnexo1: "DICTAMEN DIPUTACIONES PT",
    },
    {
      id: 136,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES PANATLAX",
      titleAnexo1: "DICTAMEN NUEVA ALIANZA",
    },
    {
      id: 137,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES PVEM",
    },
    {
      id: 138,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES PESTLAX",
    },
    {
      id: 139,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIÓN COALICIÓN JUNTOS HAREMOS HISTORIA TLAXCALA",
    },
    {
      id: 140,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES RSP",
      titleAnexo1: "DICTAMEN REDES SOCIALES PROGRESISTAS 1",
      titleAnexo2: "DICTAMEN REDES SOCIALES PROGRESISTAS 2",
    },
    {
      id: 141,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTACIONES FUERZA POR MÉXICO",
      titleAnexo1: "DICTAMEN FUERZA POR MÉXICO",
    },
    {
      id: 142,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "FINANCIAMIENTO ACT. ORDINARIAS Y OBTENCIÓN DEL VOTO DIPUTACIONES",
    },
    {
      id: 143,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REFORMA LINEAMIENTOS DE DEBATES",
    },
    {
      id: 144,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "POS CQD Q ETG CG 013 2020",
    },
    {
      id: 145,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FORMATO DE DEBATES",
      titleAnexo1: "SE APRUEBA FORMATO DE DEBATES",
    },
    {
      id: 146,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PAN",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 147,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PRI",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 148,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PRD",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 149,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PT",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 150,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PVEM",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 151,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO MC",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 152,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PAC",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 153,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PS",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 154,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO MORENA",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 155,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO NA TLAXCALA",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 156,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PES TLAXCALA",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 157,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 158,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PES",
      titleAnexo1: "PES ANEXO ÚNICO",
    },
    {
      id: 159,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO RSP",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 160,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO FUERZA POR MÉXICO",
      titleAnexo1: "ANEXO",
    },
    {
      id: 161,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PAN",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 162,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PRI",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 163,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PRD",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 164,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PT",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 165,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO COMUNIDADES PVEM",
      titleAnexo1: "ANEXO ÚNICO PVEM",
    },
    {
      id: 166,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES MC",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 167,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PAC",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 168,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 169,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES MORENA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 170,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PNAT",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 171,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO PES TLAXCALA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 172,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 173,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES PES",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 174,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REQUERIMIENTO COMUNIDADES RSP",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 175,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES FXM",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 176,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTROS DE INDEPENDIENTES",
      titleAnexo1: "ANEXO ÚNICO INDEPENDIENTES",
      titleAnexo2: "REGISTROS DE INDEPENDIENTES FE DE ERRATAS",
    },
    {
      id: 177,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "READECUACIÓN DEL PRESUPUESTO DE EGRESOS",
      titleAnexo1: "ANEXO",
    },
    {
      id: 178,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DIPUTACIONES LOCALES",
    },
    {
      id: 179,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTO MC",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 180,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PAN",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 181,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PRI",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 182,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PRD",
      titleAnexo1: "ANEXO UNO",
    },
    {
      id: 183,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PVEM",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 184,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PS",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 185,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PES",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 186,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc:
        "REGISTRO SUSTITUCIONES DIPUTADOS MC, SI, FXM Y COALICIÓN JUNTOS HAREMOS HISTORIA POR TLAXCALA",
    },
    {
      id: 187,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PAC",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 188,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS MORENA",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "FE DE ERRATAS REGISTROS DE CANDIDATURAS MORENA",
    },
    {
      id: 189,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS NUEVA ALIANZA",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 190,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PEST",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "FE DE ERRATAS REGISTROS DE CANDIDATURAS AYUNTAMIENTOS PEST",
    },
    {
      id: 191,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 192,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PT",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 193,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS RSP",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 194,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS FXM",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 195,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS AYUNTAMIENTOS PIS SI",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2:
        "FE DE ERRATAS REGISTROS DE CANDIDATURAS AYUNTAMIENTOS PISSI",
    },
    {
      id: 196,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PAN",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO PARTICULAR PAN",
    },
    {
      id: 197,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PRI",
    },
    {
      id: 198,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PRD",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 199,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES MC",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "ANEXO ÚNICO",
      titleAnexo3: "VOTO PARTICULAR",
    },
    {
      id: 200,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PAC",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 201,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES MORENA",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 202,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES NA TLAXCALA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 203,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 204,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES RSP",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 205,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 206,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PES",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 207,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PEST",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 208,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "REGISTRO COMUNIDADES PT",
    },
    {
      id: 209,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MEDIDAS DE SEGURIDAD DE BOLETAS",
    },
    {
      id: 210,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO A SENTENCIA TET JDC 050 2021",
    },
    {
      id: 211,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DISTRIBUCIÓN DE FINANCIAMIENTO AYUNTAMIENTOS",
      titleAnexo1: "ANEXO ÚNICO FINANCIAMIENTO AYUNTAMIENTOS",
    },
    {
      id: 212,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES DIPUTACIONES Y AYUNTAMIENTOS",
    },
    {
      id: 213,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOBRENOMBRES PELO 2020 2021",
    },
    {
      id: 214,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES DE CONSEJOS MUNICIPALES",
    },
    {
      id: 215,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CRITERIOS PARA CELEBRACIÓN DE DEBATES A TRAVÉS DE TICS",
    },
    {
      id: 216,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 217,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CONVOCATORIA",
      titleAnexo1: "SEGUNDA CONVOCATORIA SE Y CAES",
    },
    {
      id: 218,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SE READECUA EL PRESUPUESTO DE EGRESOS",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 219,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "DESIGNACIÓN DEL PERSONAL PARA CONTEO, SELLADO Y AGRUPAMIENTO DE BOLETAS",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO CONCURRENTE",
    },
    {
      id: 220,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "SUSTITUCIONES DIPUTADOS LOCALES, AYUNTAMIENTOS Y PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 221,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 222,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES DIPUTACIONES, AYUNTAMIENTOS Y PRESIDENCIAS",
    },
    {
      id: 223,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROCESO TÉCNICO OPERATIVO PREP Y CATD",
      titleAnexo1: "ANEXO PROCESO TÉCNICO OPERATIVO",
    },
    {
      id: 224,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA SCM JRC 84 2021 PT",
    },
    {
      id: 225,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "PES MEDIDAS CAUTELARES CQD PE PES CG 082 2021",
    },
    {
      id: 226,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 227,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc:
        "SUSTITUCIONES DIPUTACIONES, AYUNTAMIENTOS Y PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 228,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIONES AYUNTAMIENTOS Y PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CRITERIOS CIERRE DE CAMPAÑA 2021",
    },
    {
      id: 230,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET JE 066 2021",
      titleAnexo1: "CUMPLIMIENTO DE SENTENCIA TET JE 066 2021 VOTO CONCURRENTE",
    },
    {
      id: 231,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "DESIGNACIÓN DE CASILLAS PARA VERIFICAR MEDIDAS DE SEGURIDAD EN BOLETAS",
    },
    {
      id: 232,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIONES CANDIDATURAS AYUNTAMIENTOS Y PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 233,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SE DA RESPUESTA A SOLICITUD PES",
    },
    {
      id: 234,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "EXHORTO AL ELECTORADO",
    },
    {
      id: 235,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "FXM CUMPLIMIENTO DE REQUERIMIENTO ITE CG 230 2021",
      titleAnexo1: "VOTO CONCURRENTE",
    },
    {
      id: 236,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "POR EL QUE SE HABILITA AL PERSONAL PARA EL MODELO OPERATIVO DE RECEPCIÓN DE PAQUETES",
      titleAnexo1: "VOTO CONCURRENTE",
    },
    {
      id: 237,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIONES DIPUTACIONES, AYUNTAMIENTOS Y PRESIDENTES DE COMUNIDAD",
    },
    {
      id: 238,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "POR EL QUE SE DESIGNA AL PERSONAL COMO ENLACES DE COMUNICACIÓN",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIONES DE CONSEJOS MUNICIPALES",
    },
    {
      id: 240,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIONES AYUNTAMIENTOS Y PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 241,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "MODIFICA INTEGRACIÓN CONSEJO MUNICIPAL TEQUEXQUITLA",
    },
    {
      id: 242,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN CONSEJO MUNICIPAL TOTOLAC",
    },
    {
      id: 243,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASUME ATRIBUCIONES Y FUNCIONES CONSEJO XICOHTZINCO",
    },
    {
      id: 244,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN CONSEJO MUNICIPAL ZACATELCO",
    },
    {
      id: 245,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN DE CONSEJOS MUNICIPALES",
    },
    {
      id: 246,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN CONSEJO MUNICIPAL DE ESPAÑITA",
    },
    {
      id: 247,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMPUTO XICOHTZINCO",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO CONCURRENTE",
      titleAnexo3: "VOTO PARTICULAR",
      titleAnexo4: "FE DE ERRATAS COMPUTO XICOHTZINCO",
    },
    {
      id: 248,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMPUTO GUBERNATURA",
    },
    {
      id: 249,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PORCENTAJE PARTIDOS POLÍTICOS LOCALES Y NACIONALES",
    },
    {
      id: 250,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DIPUTACIONES RP",
    },
    {
      id: 251,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASIGNACIÓN REGIDURÍAS",
      titleAnexo1: "VOTO PARTICULAR Y ANEXOS",
      titleAnexo2: "FE DE ERRATAS REGIDURÍAS",
      titleAnexo3: "NUEVA VERSIÓN EN CUMPLIMIENTO A TET JDC 327 2021 Y ACUM",
      titleAnexo4:
        "VERSIÓN EN CUMPLIMIENTO A SENTENCIA EXPEDIENTE SCM JDC 1859 2021",
    },
    {
      id: 252,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RETIRO DE PROPAGANDA",
      titleAnexo1: "PROCEDIMIENTO PARA RETIRO DE PROPAGANDA",
    },
    {
      id: 253,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CALENDARIZA RETENCIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "FE DE ERRATAS",
    },
    {
      id: 254,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DESIGNACIÓN DE INTERVENTORA O INTERVENTOR",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 255,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "PERSONAL RECUENTO SAN ANDRÉS AHUASHUASTEPEC",
    },
    {
      id: 256,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CUMPLIMIENTO DE ACUERDO PLENARIO AHUASHUATEPEC, TZOMPANTEPEC",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 257,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "PERSONAL RECUENTO YAUHQUEMEHCAN",
    },
    {
      id: 258,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CUMPLIMIENTO DE ACUERDO PLENARIO YAUHQUEMEHCAN",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 259,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CUMPLIMIENTO DE CÓMPUTO DISTRITOS 12 Y 15",
      titleAnexo1: "ANEXO",
    },
    {
      id: 260,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "PERSONAL RECUENTO AYUNTAMIENTO AYOMETLA",
    },
    {
      id: 261,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PERSONAL RECUENTO XOCOYUCAN, SAN BUENAVENTURA Y TEXCACOAC",
    },
    {
      id: 262,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "CUMPLIMIENTO SENTENCIAS YAUHQUEMEHCAN, MAZATECOCHCO, SANTA CRUZ QUILEHTLA Y XILOXOXTLA",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 263,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET JE 120 2021",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 264,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "CUMPLIMIENTO DE SENTENCIA TET JE 176 2021",
    },
    {
      id: 265,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "INTEGRACIÓN LXIV LEGISLATURA DEL CONGRESO TLAXCALA",
    },
    {
      id: 266,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE REFORMA REGLAMENTO INTERIOR DE ESTE INSTITUTO",
    },
    {
      id: 267,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 268,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ADECUACIÓN DE COMISIONES, COMITÉS Y JGE",
    },
    {
      id: 269,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "RESPUESTA A ESCRITO ALEJANDRO MARTÍNEZ LÓPEZ REPRESENTANTE PES",
    },
    {
      id: 270,
      typeDoc: "DICTAMEN",
      monthDoc: "SEP",
      nameDoc: "PÉRDIDA DE REGISTRO PS",
    },
    {
      id: 271,
      typeDoc: "DICTAMEN",
      monthDoc: "SEP",
      nameDoc: "PÉRDIDA DE REGISTRO PEST",
    },
    {
      id: 272,
      typeDoc: "DICTAMEN",
      monthDoc: "SEP",
      nameDoc: "PÉRDIDA DE REGISTRO IMPACTO SOCIAL SI",
    },
    {
      id: 273,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PROYECTO PRESUPUESTO DE EGRESOS 2022",
      titleAnexo1: "ANEXO UNO. ACT. ORDINARIAS",
      titleAnexo2: "ANEXO DOS. ACT. ESPECÍFICAS",
      titleAnexo3: "ANEXO TRES. CALENDARIZADO",
    },
    {
      id: 274,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "READECUACIÓN PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO. DISTRIBUCIÓN ISR",
    },
    {
      id: 275,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "RESPUESTA A ESCRITO PAC",
    },
    {
      id: 276,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "LINEAMIENTOS SPEN",
      titleAnexo1: "ANEXO ÚNICO. LINEAMIENTOS",
    },
    {
      id: 277,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RESPUESTA A ESCRITO COMUNIDAD ÁLVARO OBREGÓN",
    },
    {
      id: 278,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "POR EL QUE SE ASUMEN FUNCIONES DE CONSEJOS MUNICIPALES ELECTORALES PARA PEE 2021",
    },
    {
      id: 279,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE APRUEBA CALENDARIO ELECTORAL PARA EL PEE 2021",
      titleAnexo1: "CALENDARIO ELECTORAL PEE 2021",
    },
    {
      id: 280,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE APRUEBA INTEGRACIÓN DE COMISIONES TEMPORALES",
    },
    {
      id: 281,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "PRÓRROGA DE VIGENCIA DE ACUERDOS PARA SU APLICACIÓN EN PEE 2021",
    },
    {
      id: 282,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PÉRDIDA DE REGISTRO PES, RSP Y FXM",
    },
    {
      id: 283,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESTRUCCIÓN DOCUMENTACIÓN ELECTORAL PELO 2020 2021",
    },
    {
      id: 284,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "MANUAL PARA EL REGISTRO DE CANDIDATURAS Y PROTOCOLO SANITARIO PEE 2021",
      titleAnexo1: "ANEXO 1 MANUAL DE REGISTRO DE CANDIDATURAS_PEE 2021",
      titleAnexo2:
        "ANEXO 2 PROTOCOLO SANITARIO PARA EL REGISTRO DE CANDIDATURAS",
    },
    {
      id: 285,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "POR EL QUE SE APRUEBA EL PROGRAMA DE INCENTIVOS PARA MSPEN",
      titleAnexo1: "ANEXO 1 PROGRAMA DE INCENTIVOS PARA MSPEN DE ITE",
    },
    {
      id: 286,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RESPUESTA A ESCRITO DE FUERZA POR MÉXICO",
    },
    {
      id: 287,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "PERSONAL AUTORIZADO PARA BODEGAS Y RESPONSABLE ASIGNACIÓN DE FOLIOS PEE 2021",
      titleAnexo1:
        "ANEXO 1 PERSONAL AUTORIZADO PARA EL ACCESO A LA BODEGA ELECTORAL",
    },
    {
      id: 288,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "INTEGRACIÓN DE COMISIONES Y ADECUACIÓN DE COMISIÓN TEMPORAL, COMITÉS Y JGE",
    },
    {
      id: 289,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "DISTRIBUCIÓN ISR",
    },
    {
      id: 290,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE APRUEBA EL DICTAMEN RELATIVO DOCUMENTACIÓN CON EMBLEMAS PEE 2021",
      titleAnexo1: "DICTAMEN DOCUMENTACIÓN CON EMBLEMAS",
      titleAnexo2: "INFORME DOCUMENTACIÓN CON EMBLEMAS.",
      titleAnexo3: "ANEXO DEL DICTAMEN",
    },
    {
      id: 291,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PAN PEE 2021",
    },
    {
      id: 292,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PRI PEE 2021",
    },
    {
      id: 293,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PRD PEE 2021",
    },
    {
      id: 294,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PT PEE 2021",
    },
    {
      id: 295,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PVEM PEE 2021",
    },
    {
      id: 296,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN MC PEE 2021",
    },
    {
      id: 297,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PAC PEE 2021",
    },
    {
      id: 298,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN MORENA PEE 2021",
    },
    {
      id: 299,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROGRAMA GOBIERNO COMÚN PNAT PEE 2021",
    },
    {
      id: 300,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SE DESIGNA PERSONAL PARA CONTEO, SELLADO Y AGRUPAMIENTO DE BOLETAS Y PAQUETES ELECTORALES PEE 2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 301,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "UBICACIÓN Y TRANSMISIÓN CATD, FECHA Y HORA DE INICIO Y CIERRE PREP PEE 2021",
      titleAnexo1: "DICTAMEN CSSI PREP PEE 2021 (1)",
      titleAnexo2: "PROCESO TÉCNICO OPERATIVO PREP 2021",
      titleAnexo3: "PLAN DE SEGURIDAD Y CONTINUIDAD PELE 2021",
    },
    {
      id: 302,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PAN PEE 2021",
    },
    {
      id: 303,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PRI PEE 2021",
    },
    {
      id: 304,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PRD PEE 2021",
      titleAnexo1: "FE DE ERRATAS",
    },
    {
      id: 305,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PT PEE 2021",
    },
    {
      id: 306,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PVEM PEE 2021",
    },
    {
      id: 307,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES MC PEE 2021",
    },
    {
      id: 308,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PAC PEE 2021",
    },
    {
      id: 309,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES MORENA PEE 2021",
    },
    {
      id: 310,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REGISTRO CANDIDATURAS COMUNIDADES PNAT PEE 2021",
    },
    {
      id: 311,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MEDIDAS DE SEGURIDAD BOLETAS PEE 2021",
    },
    {
      id: 312,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DESIGNACIÓN TITULARES DE DOECYEC Y UTC",
    },
    {
      id: 313,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc:
        "SUSTITUCIÓN CANDIDATURA PRESIDENCIA DE COMUNIDAD PRD PEE 2021 (1)",
    },
    {
      id: 314,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "DESIGNACIÓN DE CASILLAS PARA VERIFICAR MEDIDAS DE SEGURIDAD EN BOLETAS DEL PEE 2021",
    },
    {
      id: 315,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MODELO OPERATIVO DE RECEPCIÓN DE PAQUETES ELECTORALES",
      titleAnexo1: "MODELO OPERATIVO",
      titleAnexo2: "PERSONAL MODELO OPERATIVO",
    },
    {
      id: 316,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "REDISTRIBUCIÓN FINANCIAMIENTO PÚBLICO 2021",
      titleAnexo1: "ANEXO 1 ACTIVIDADES ORDINARIAS",
      titleAnexo2: "ANEXO 2 ACTIVIDADES ESPECÍFICAS",
    },
    {
      id: 317,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ACTUALIZA CALENDARIZACIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 318,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "READECÚA PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 319,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE APRUEBA POLÍTICA DE IGUALDAD LABORAL",
      titleAnexo1: "POLÍTICA DE IGUALDAD LABORAL Y NO DISCRIMINACIÓN DEL ITE",
    },
    {
      id: 320,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "REFORMAS AL REGLAMENTO PARA LA CONSTITUCIÓN Y REGISTRO DE LOS PPL ANTE EL ITE",
      titleAnexo1:
        "REGLAMENTO PARA LA CONSTITUCIÓN Y REGISTRO DE LOS PPL ANTE EL ITE",
    },
    {
      id: 321,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PERSONAL CÓMPUTOS ELECCIÓN EXTRAORDINARIA",
    },
    {
      id: 322,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ELECCIÓN COLONIA AGRÍCOLA SAN LUIS",
    },
    {
      id: 323,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ELECCIÓN GUADALUPE VICTORIA",
    },
    {
      id: 324,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ELECCIÓN LA CANDELARIA TEOTLALPAN",
    },
    {
      id: 325,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ELECCIÓN SANTA CRUZ GUADALUPE",
    },
    {
      id: 326,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ELECCIÓN TEPUENTE",
    },
    {
      id: 327,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "MEDIDAS CAUTELARES EXP. CQD PE SIG CG 166 2021",
    },
    {
      id: 328,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE DESIGNA ÁREA COORDINADORA Y GRUPO INTERDISCIPLINARIO DE ARCHIVOS",
    },
    {
      id: 329,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE READECÚA PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 330,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO A SENTENCIA EXP. TET JDC 39 2020",
    },
  ],

  2020: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN DE PRESUPUESTO 2020",
      titleAnexo1: "ANEXO 1 ADECUACIÓN DE PRESUPUESTO 2020",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACTUALIZACIÓN DE MULTAS",
      titleAnexo1: "ANEXO 1 ACTUALIZACIÓN DE MULTAS",
      titleAnexo2: "ANEXO 2 ACTUALIZACIÓN DE MULTAS",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "RESPUESTA A FRANCISCO SOSA HERNÁNDEZ",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "FORMA DE EJECUTAR MULTAS",
      titleAnexo1: "ANEXO ÚNICO MULTAS",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "APROBACIÓN DE PROGRAMA DE IMPARTICIÓN DE CURSOS",
      titleAnexo1: "ANEXO ÚNICO PROGRAMA DE CAPACITACIÓN 2020",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PLAN ANUAL DE DESARROLLO ARCHIVÍSTICO",
      titleAnexo1: "ANEXO ÚNICO PLAN ANUAL DE DESARROLLO ARCHIVÍSTICO",
    },
    {
      id: 7,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 001 2019",
    },
    {
      id: 8,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 002 2019",
    },
    {
      id: 9,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 003 2019",
    },
    {
      id: 10,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 004 2019",
    },
    {
      id: 11,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 005 2019",
    },
    {
      id: 12,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 006 2019",
    },
    {
      id: 13,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "CQD Q CG 007 2019",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "ÓRGANO ENLACE",
    },
    {
      id: 15,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "PS",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "COVID-19",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AMPLIACIÓN DE MEDIDAS COVID-19",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESPUESTA OFICIO DEL PVEM",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CRITERIOS PARA RENUNCIA DE FINANCIAMIENTO PÚBLICO",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MODIFICACIÓN AL PROGRAMA DE CAPACITACIÓN 2020",
      titleAnexo1: "ANEXO 1 PROGRAMA DE CAPACITACIÓN 2020",
    },
    {
      id: 21,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "MEDIDAS CAUTELARES CQD-Q-PRD-CG-009-2020",
    },
    {
      id: 22,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "MEDIDAS CAUTELARES CQD-Q-PRD-CG-010-2020",
    },
    {
      id: 23,
      typeDoc: "",
      monthDoc: "MAY",
      nameDoc:
        "SE ACTUALIZAN LAS MEDIDAS CON LAS QUE CUENTA EL INSTITUTO CON MOTIVO DE LA PANDEMIA COVID-19, PARA EL REGRESO DE ACTIVIDADES PRESENCIALES DEL ITE",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "READECUACIÓN AL PRESUPUESTO",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESULTADOS DE EVALUACIÓN SPEN",
      titleAnexo1: "ANEXO ÚNICO RESULTADOS DE EVALUACIÓN SPEN",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "APROBACIÓN DE LINEAMIENTOS PARA EL DESARROLLO DE AUDIENCIAS POR VIDEOCONFERENCIA",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "READECUACIÓN DEL PRESUPUESTO DE EGRESOS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "MANUAL DE ELECCIONES ESCOLARES",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "RATIFICACIÓN DE LA INSTANCIA INTERNA PREP",
    },
    {
      id: 30,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "SEP",
      nameDoc: "MEDIDAS CAUTELARES CQD-Q-RACF-CG-014-2020",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REFORMAS REGLAMENTO USOS Y COSTUMBRES",
      titleAnexo1: "ANEXO 1 REGLAMENTO DE USOS Y COSTUMBRES",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DESIGNACIÓN DEL TITULAR DE LA UTCE",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PROYECTO DE PRESUPUESTO DE EGRESOS 2021",
      titleAnexo1: "ANEXO ÚNICO PRESUPUESTO DE EGRESOS 2021",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PROPUESTA DE PAQUETE ELECTORAL DISTRITAL Y MUNICIPAL",
      titleAnexo1:
        "DICTAMEN COECyEC_PROPUESTA DE PAQUETE ELECTORAL Y DISTRITAL",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ESTATUTO CANDIDATURAS INDEPENDIENTES",
      titleAnexo1: "ESTATUTO DE CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRACIÓN Y ADECUACIÓN COMISIONES",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "INFORME VERIFICACIÓN DE CUMPLIMIENTO DE NÚMERO MÍNIMO DE AFILIADOS PPL",
      titleAnexo1:
        "ANEXO ÚNICO VERIFICACIÓN DE CUMPLIMIENTO DE NÚMERO MÍNIMO DE AFILIADOS PARTIDOS LOCALES",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGLAMENTO DE TRANSPARENCIA",
      titleAnexo1:
        "ANEXO ACUERDO ITE-CG 38-2020 12-OCTUBRE-2020 REGLAMENTO DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESIGNACIÓN DE TITULAR CONSULTA CIUDADANA",
    },
    {
      id: 40,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "ACREDITACIÓN ENCUENTRO SOLIDARIO",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "READECUACIÓN DE PRERROGATIVAS PARTIDOS",
      titleAnexo1:
        "ANEXO 1 ACUERDO ITE-CG 41-2020 15-OCTUBRE-2020 READECUACIÓN DE PRERROGATIVAS PARTIDOS 2020",
      titleAnexo2:
        "ANEXO 2 ACUERDO ITE-CG 41-2020 15-OCTUBRE-2020 READECUACIÓN DE PRERROGATIVAS PARTIDOS 2020",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ACTUALIZACIÓN DE MULTAS",
      titleAnexo1:
        "ANEXO ÚNICO ACUERDO ITE-CG 42-2020 15-OCTUBRE-2020 ACTUALIZACIÓN DE MULTAS 2020",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "APROBACIÓN DE CALENDARIO ELECTORAL 2020-2021",
      titleAnexo1:
        "ANEXO ÚNICO ACUERDO ITE-CG 43-2020 15-OCTUBRE-2020 CALENDARIO ELECTORAL LEGAL 2020-2021",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "APROBACIÓN DE METODOLOGÍA PARA MONITOREO DE MEDIOS DE COMUNICACIÓN",
      titleAnexo1:
        "ANEXO ÚNICO ACUERDO ITE-CG 44-2020 15 DE OCTUBRE-2020 METODOLOGÍA PARA MONITOREO",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVOCATORIA A ELECCIONES PELO 2021",
      titleAnexo1:
        "ANEXO ÚNICO ACUERDO ITE-CG 45-2020 CONVOCATORIA PROCESO ELECTORAL LOCAL ORDINARIO 2020-2021",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVOCATORIA CANDIDATURAS INDEPENDIENTES PELO 2020-2021",
      titleAnexo1: "ANEXO UNO CONVOCATORIA",
      titleAnexo2:
        "ANEXO 1 DE CONVOCATORIA FORMATO DE MANIFESTACIÓN DE INTENCIÓN",
      titleAnexo3:
        "ANEXO 2 DE CONVOCATORIA FORMATO DE SOLICITUD DE REGISTRO DE CANDIDATURA INDEPENDIENTE",
      titleAnexo4:
        "ANEXO 3 DE CONVOCATORIA FORMATO DE MANIFESTACIÓN DE VOLUNTAD",
      titleAnexo5:
        "ANEXO 4 DE CONVOCATORIA FORMATO DE NO ACEPTACIÓN DE RECURSOS ILÍCITOS",
      titleAnexo6:
        "ANEXO 5 DE CONVOCATORIA FORMATO DE ESCRITO DE CONFORMIDAD PARA LA FISCALIZACIÓN",
      titleAnexo7:
        "ANEXO 6 DE CONVOCATORIA FORMATO ESCRITO NO CONDENADO POR VIOLENCIA POLÍTICA EN CONTRA DE LAS MUJERES",
      titleAnexo8: "ANEXO DOS PORCENTAJE DE APOYO CIUDADANO",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS DE PARIDAD DE GÉNERO",
      titleAnexo1: "ANEXO 1 LINEAMIENTOS DE PARIDAD DE GÉNERO",
      titleAnexo2: "ANEXO ÚNICO DE LOS LINEAMIENTOS DE PARIDAD DE GÉNERO",
      titleAnexo3: "VOTO CONCURRENTE",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "READECUACIÓN DEL PRESUPUESTO",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ROTACIÓN JGE",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RESPUESTA OFICIO IXCOTLA",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INCENTIVOS DEL SPEN",
      titleAnexo1:
        "ANEXO 1 DICTAMEN OTORGAMIENTO DE INCENTIVOS DIANA RÍOS HERNÁNDEZ",
      titleAnexo2:
        "ANEXO 2 DICTAMEN OTORGAMIENTO DE INCENTIVOS MIGUEL PÉREZ CASTILLA",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PONDERACIONES SPEN",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PONDERACIONES SPEN",
    },
    {
      id: 53,
      typeDoc: "",
      monthDoc: "NOV",
      nameDoc: "INTEGRACIÓN COTAPREP 2020",
      titleAnexo1: "ANEXO ACUERDO ITE-CG 53-2020 CV JUAN FELIPE M.L.",
      titleAnexo2:
        "ANEXO ACUERDO ITE-CG 53-2020 CV AVECITA ALEJANDRA FRAGOSO SANCHEZ",
      titleAnexo3: "ANEXO ACUERDO ITE-CG 53-2020 CV CANDY ATONAL NOLASCO",
      titleAnexo4: "ANEXO ACUERDO ITE-CG 53-2020 CV ENRIQUE HERRERA FERNÁNDEZ",
      titleAnexo5: "ANEXO ACUERDO ITE-CG 53-2020 CV GERARDO GRACIA RODRÍGUEZ",
      titleAnexo6: "ANEXO ACUERDO ITE-CG 53-2020 CV AUGUSTO MELÉNDEZ",
      titleAnexo7: "ANEXO ACUERDO ITE-CG 53-2020 CV CRISTÓBAL MEDINA",
      titleAnexo8: "ANEXO ACUERDO ITE-CG 53-2020 CV JORGE EDUARDO XALTENO",
    },
    {
      id: 54,
      typeDoc: "",
      monthDoc: "NOV",
      nameDoc: "REFORMA AL REGLAMENTO DE ADQUISICIONES",
      titleAnexo1: "ANEXO 1 REGLAMENTO DE ADQUISICIONES",
      titleAnexo2: "VOTO PARTICULAR",
    },
    {
      id: 55,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "ACREDITACIÓN REDES SOCIALES PROGRESISTAS",
    },
    {
      id: 56,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "ACREDITACIÓN FUERZA SOCIAL POR MÉXICO",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PAUTAS DE RADIO Y TV",
      titleAnexo1: "ANEXO 1 ",
      titleAnexo2: "ANEXO 2 ",
      titleAnexo3: "ANEXO 3 ",
      titleAnexo4: "ANEXO 4 ",
      titleAnexo5: "ANEXO 5 ",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "LINEAMIENTOS LIBERTAD DE EXPRESIÓN",
      titleAnexo1: "ANEXO ÚNICO LINEAMIENTOS",
      titleAnexo2: "VOTO CONCURRENTE",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CATALOGO DE PROGRAMAS DE RADIO Y TELEVISIÓN",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SENTENCIA TET-JE-43-2020 Y ACUMULADOS",
      titleAnexo1: "ANEXO UNO CONVOCATORIA CANDIDATURAS INDEPENDIENTES",
      titleAnexo2: "ANEXO DOS PORCENTAJE DE APOYO CIUDADANO",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "READECUACIÓN PRERROGATIVAS PARTIDOS 2020",
      titleAnexo1:
        "ANEXO 1 ACUERDO ITE-CG 61-2020 READECUACIÓN PRERROGATIVAS PARTIDOS 2020",
      titleAnexo2:
        "ANEXO 2 ACUERDO ITE-CG 61-2020 READECUACIÓN PRERROGATIVAS PARTIDOS 2020",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ACTUALIZACIÓN DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO ACUERDO ITE-CG 62-2020 ACTUALIZACIÓN DE MULTAS",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ACCIÓN AFIRMATIVA",
      titleAnexo1: "ANEXO 1 ADSCRIPCIÓN INDÍGENA POR MUNICIPIOS",
      titleAnexo2: "ANEXO 2 ESTUDIO DE POBLACIÓN INDÍGENA EN TLAXCALA",
      titleAnexo3: "ANEXO 3 CATALOGO DE COMUNIDADES INDÍGENAS IMPI",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "LINEAMIENTOS DE REGISTRO",
      titleAnexo1: "ANEXO 1 LINEAMIENTOS DE REGISTRO DE CANDIDATURAS",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CONVOCATORIA OBSERVADORES ELECTORALES",
      titleAnexo1: "ANEXO UNO CONVOCATORIA OBSERVADORES ELECTORALES",
      titleAnexo2: "ANEXO DOS SOLICITUD DE ACREDITACIÓN",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "READECUACIÓN AL PRESUPUESTO",
      titleAnexo1: "ANEXO ÚNICO READECUACIÓN AL PRESUPUESTO",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO TOPES DE PRECAMPAÑA 2020-2021",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 69,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "MODIFICACIONES ESTATUTOS PAC",
      titleAnexo1: "VOTO CONCURRENTE",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CONVOCATORIA A CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo1: "ANEXO UNO CONVOCATORIA",
      titleAnexo2: "ANEXO DOS PROTOCOLO DE SEGURIDAD SANITARIA",
      titleAnexo3: "VOTO CONCURRENTE",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE APRUEBA LA METODOLOGÍA PARA EL MONITOREO CON PERSPECTIVA DE GÉNERO",
      titleAnexo1:
        "ANEXO ÚNICO METODOLOGÍA PARA MONITOREAR CON PERSPECTIVA DE GÉNERO",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "SE APRUEBA LA METODOLOGÍA PARA EL MONITOREO ESPACIOS RADIO Y TELEVISIÓN",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 73,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "MEDIDAS CAUTELARES CQD-PE-MRR-CG-002-2020",
    },
    {
      id: 74,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "ESTATUTOS PES TLAXCALA",
      titleAnexo1: "VOTO CONCURRENTE",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ASIGNACIÓN REGIDURÍAS",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "REGLAS BÁSICAS DE DEBATES",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "REFORMA EL REGLAMENTO DE QUEJAS Y DENUNCIAS DEL ITE",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2: "VOTO CONCURRENTE",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DESIGNACIÓN E INCORPORACIÓN GANADORAS SPEN 2020",
    },
    {
      id: 79,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "PROCEDENCIA MANIFESTACIONES INDEPENDIENTES",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "ACUERDO RECOMENDACIONES SANITARIAS PRECAMPAÑAS Y CAPTACIÓN APOYO CIUDADANO",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "READECUACIÓN AL PRESUPUESTO",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 82,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-001-2020 PAN",
    },
    {
      id: 83,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-002-2020 PRI",
    },
    {
      id: 84,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-003-2020 PRD",
    },
    {
      id: 85,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-004-2020 PT",
    },
    {
      id: 86,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-005-2020 PVEM",
    },
    {
      id: 87,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-006-2020 NA TLAXCALA",
    },
    {
      id: 88,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-007-2020 MORENA",
    },
    {
      id: 89,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "CQD-Q-CG-008-2020 PES TLAXCALA",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE DA CUMPLIMIENTO A TET-JE-038-2020",
      titleAnexo1: "ANEXO ÚNICO LINEAMIENTOS DE PARIDAD DE GÉNERO",
      titleAnexo2: "ANEXO A LOS LINEAMIENTOS DE PARIDAD RESULTADOS ELECTORALES",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE DA CUMPLIMIENTO A LA SENTENCIA TET-JE-055-2020",
    },
  ],

  2019: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "READECUACIÓN DE PRERROGATIVAS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "MULTAS PENDIENTES A PARTIDOS POLÍTICOS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "ADECUACIÓN PRESUPUESTO EJERCICIO FISCAL 2019",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DELEGACIÓN DE ESTUDIOS DOCUMENTACIÓN ELECTORAL",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "APROBACIÓN DE PROGRAMA DE IMPARTICIÓN DE CURSOS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DESIGNACIÓN TITULARES ÁREA TÉCNICA",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "FORMA DE EJECUTAR LAS MULTAS",
      titleAnexo1: "RETENCIÓN DE MULTAS. ANEXO",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "INTEGRACIÓN COMISIONES",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "COMITÉ DE ADQUISICIONES",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CUMPLIMIENTO SENTENCIA SALA REG PTE COMUNIDAD IXCOTLA",
      titleAnexo1:
        "VOTO PARTICULAR CUMPLIMIENTO SENT SALA REG PTE COMUNIDAD IXCOTLA",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "RETENCIÓN POR RETIRO DE PROPAGANDA ELECTORAL",
    },
    {
      id: 12,
      typeDoc: "",
      monthDoc: "MAR",
      nameDoc: "COMISIÓN DE GOBIERNO INTERNO",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 13,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO",
    },
    {
      id: 14,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DICTAMEN ENCUENTRO SOCIAL TLAXCALA",
      titleAnexo1: "ANEXO RESOLUCIÓN",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "READECUACIÓN PRERROGATIVAS PARTIDOS",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MULTAS PENDIENTES",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ACTUALIZACIÓN CANTIDADES A RETENER RESPECTO DE MULTAS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 18,
      typeDoc: "",
      monthDoc: "MAY",
      nameDoc: "RESULTADOS DE EVALUACIÓN",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SE ADECÚAN COMISIONES",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "REFORMA REGLAMENTO INTERIOR DEL ITE",
      titleAnexo1: "ANEXO REGLAMENTO INTERIOR DEL ITE",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "REFORMA REGLAMENTO DE LA JGE",
      titleAnexo1: "ANEXO REGLAMENTO JUNTA GENERAL",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "MANUAL DE CONTROL DE BIENES",
      titleAnexo1: "ANEXO MANUAL DE CONTROL DE BIENES",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "MANUAL DE ORGANIZACIÓN DEL ITE",
      titleAnexo1: "ANEXO MANUAL DE ORGANIZACIÓN",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "INTEGRACIÓN DE COMISIÓN SPEN",
    },
    {
      id: 25,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "DICTÁMENES DE IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO 1 INFORMES MENSUALES SOLICITUD DE REGISTRO",
      titleAnexo2: "ANEXO 2 INFORMES MENSUALES PROCEDENCIA DE REGISTRO",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "INCENTIVOS SPEN",
      titleAnexo1: "ANEXO 1 DICTAMEN PARA EL OTORGAMIENTO DE INCENTIVOS 2019",
      titleAnexo2: "ANEXO 2 DICTAMEN ACTUALIZADO DRH",
      titleAnexo3: "ANEXO 3 DICTAMEN ACTUALIZADO JFPT",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ACUERDO DEL PRESUPUESTO DE EGRESOS 2020",
      titleAnexo1: "ANEXO PRESUPUESTO DE EGRESOS",
      titleAnexo2: "VOTO CONCURRENTE",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "COMITÉ DE IGUALDAD LABORAL",
    },
    {
      id: 29,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "RESOLUCIÓN DE LAS MODIFICACIONES DE LOS ESTATUTOS DEL PAC",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RENDIMIENTOS Y RECURSO NO EROGADO",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "FORMA DE RETENER MONTOS REMANENTES NO EJERCIDOS",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "FORMA DE EJECUTAR MULTAS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "COMISIÓN DE QUEJAS Y DENUNCIAS",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "JGE",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGLAMENTO DE ADQUISICIONES",
      titleAnexo1: "ANEXO 1 REGLAMENTO DE ADQUISICIONES ITE",
      titleAnexo2: "VOTO CONCURRENTE",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESIGNACIÓN TITULAR ÁREA TÉCNICA DE TRANSPARENCIA",
      titleAnexo1: "VOTO PARTICULAR",
    },
    {
      id: 37,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "NUEVA ALIANZA",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SERVICIOS ESPECIALIZADOS",
      titleAnexo1: "VOTO CONCURRENTE",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "RENDIMIENTOS FINANCIEROS",
      titleAnexo1: "VOTO CONCURRENTE",
      titleAnexo2: "ANEXO 1 RENDIMIENTOS",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "REGLAMENTO COMITÉ DE IGUALDAD",
      titleAnexo1:
        "ANEXO 1 REGLAMENTO DEL COMITÉ DE IGUALDAD DE GÉNERO Y LABORAL",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SISTEMA INSTITUCIONAL DE ARCHIVOS",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE EMITE CÓDIGO DE CONDUCTA",
      titleAnexo1:
        "ANEXO 1 CÓDIGO DE CONDUCTA DE LAS Y LOS SERVIDORES PÚBLICOS",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "READECUACIÓN DEL PRESUPUESTO",
      titleAnexo1: "ANEXO 1 READECUACIÓN DEL PRESUPUESTO",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "OFICIO DE REQUERIMIENTO",
    },
  ],

  2018: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACUERDO CONVOCATORIA OBSERVADORES PROCESO ELECTORAL LOCAL 2018",
      titleAnexo1: "ANEXO CONVOCATORIA OBSERVADORES ELECTORALES",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACUERDO CATALOGO DE PROGRAMAS DE RADIO Y TELEVISIÓN PELO 2018",
      titleAnexo1: "ANEXO LISTADO DE NOTICIARIOS",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "ACUERDO POR EL QUE SE RESUELVE LA PROCEDENCIA DE MANIFESTACIONES DE INTENCIÓN CI PELO 2018",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "ACUERDO DE ADECUACIÓN AL PRESUPUESTO DE EGRESOS DEL ITE CUMPLIMIENTO A LA SENTENCIA SCM JRC 21 2017 Y AMPLIACIÓN PRESUPUESTAL",
      titleAnexo1: "ANEXO ÚNICO",
      titleAnexo2:
        "VOTO CONCURRENTE CONSEJERA ELECTORAL DORA RODRÍGUEZ SORIANO 3",
      titleAnexo3:
        "VOTO CONCURRENTE CONSEJERO ELECTORAL JUAN CARLOS MINOR MÁRQUEZ",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "ACUERDO POR EL QUE SE ESTABLECE LA FORMA DE EJECUTAR LAS MULTAS PREVISTAS EN LA RESOLUCIÓN INE CG309 2017 Y ACUERDO INE CG461 2017",
      titleAnexo1: "ANEXO UNO",
      titleAnexo2: "ANEXO DOS",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "ACUERDO POR EL QUE SE REFORMAN LOS LINEAMIENTOS PARA REGULAR LOS CÓMPUTOS DISTRITALES Y ESTATAL DE DIPUTADOS LOCALES, PELO 2018",
      titleAnexo1:
        "ANEXO ÚNICO LINEAMIENTOS CÓMPUTOS DISTRITALES Y ESTATAL DE LA ELECCIÓN DE DIPUTADOS LOCALES PELO 2018",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc:
        "ACUERDO POR EL QUE SE DA CUMPLIMIENTO A LA SENTENCIA EMITIDA POR EL TRIBUNAL ELECTORAL DE TLAXCALA DICTADA DENTRO DEL EXPEDIENTE TET JE 060 2017",
      titleAnexo1: "ANEXO ÚNICO LINEAMIENTOS PARIDAD DE GÉNERO PELO 2018",
    },
    {
      id: 8,
      typeDoc: "",
      monthDoc: "ENE",
      nameDoc: "",
      titleAnexo1: "CONVOCATORIA CONSEJOS DISTRITALES",
    },
    {
      id: 9,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc:
        "RESOLUCIÓN A LA SOLICITUD DE REGISTRO DE CONVENIO DE COALICIÓN PAN PRD PAC PARA LA ELECCIÓN DE DIPUTADOS PELO 2018",
    },
    {
      id: 10,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc:
        "RESOLUCIÓN DEL CONSEJO GENERAL RESPECTO A LA SOLICITUD DE REGISTRO DEL CONVENIO DE COALICIÓN PARCIAL JUNTOS HAREMOS HISTORIA, MORENA, PT Y PES",
      titleAnexo1: "VOTO CONCURRENTE DRA. DORA RODRÍGUEZ SORIANO",
      titleAnexo2: "VOTO PARTICULAR MTRA. YARELI ÁLVAREZ MEZA",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA EL PROCESO TÉCNICO OPERATIVO PREP",
      titleAnexo1: "PROCESO TÉCNICO OPERATIVO PREP 2018",
      titleAnexo2: "VOTO RAZONADO MTRA. YARELI ÁLVAREZ MEZA",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "CUMPLIMIENTO SENTENCIA DICTADA DENTRO DEL EXPEDIENTE TET JDC 003 2018",
    },
    {
      id: 13,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "FEB",
      nameDoc: "RESOLUCIÓN CANDIDATURA COMÚN PRI PVEM PANAL Y PS",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "ACUERDO AJUSTE DE PLAZO PARA VERIFICACIÓN DE APOYO CIUDADANO ASPIRANTES A CANDIDATOS INDEPENDIENTES",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "ACUERDO POR EL QUE SE DA RESPUESTA A CIUDADANO MOISÉS PALACIOS PAREDES IMPACTO SOCIAL",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO CUMPLIMIENTO SENTENCIA TET JE 002 2018",
      titleAnexo1:
        "VOTO PARTICULAR CONSEJERO ELECTORAL JUAN CARLOS MINOR MÁRQUEZ",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO LINEAMIENTOS REGISTRO DE CANDIDATOS",
      titleAnexo1: "MANUAL DE REGISTRO DE CANDIDATOS ITE 2018",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO LINEAMIENTOS DE DEBATES",
      titleAnexo1: "LINEAMIENTOS DEBATES",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "ACUERDO REGLAMENTO EN MATERIA DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PUBLICA.",
      titleAnexo1: "REGLAMENTO TRANSPARENCIA",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO DE CLASIFICACIÓN Y DESCLASIFICACIÓN DE LA INFORMACIÓN",
      titleAnexo1:
        "REGLAMENTO PARA LA CLASIFICACIÓN Y DESCLASIFICACIÓN DE LA INFORMACIÓN DEL INSTITUTO TLAXCALTECA DE ELECCIONES FINAL",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO DE LINEAMIENTOS PARA LA PROTECCIÓN DE DATOS PERSONALES",
      titleAnexo1:
        "LINEAMIENTOS PARA LA PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DEL INSTITUTO TLAXCALTECA DE ELECCIONES",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO INTEGRACIÓN CONSEJOS DISTRITALES",
      titleAnexo1:
        "VOTO CONCURRENTE CONSEJERO ELECTORAL JUAN CARLOS MINOR MÁRQUEZ",
      titleAnexo2: "VOTO PARTICULAR CONSEJERA ELECTORAL YARELI ÁLVAREZ MEZA",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO DE UBICACIÓN DE LOS CATD",
      titleAnexo1:
        "VOTO CONCURRENTE CONSEJERO ELECTORAL JUAN CARLOS MINOR MÁRQUEZ",
      titleAnexo2: "VOTO PARTICULAR CONSEJERA ELECTORAL YARELI ÁLVAREZ MEZA",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO DE TOPES DE GASTO DE CAMPAÑA",
      titleAnexo1: "VOTO RAZONADO CONSEJERA ELECTORAL YARELI ÁLVAREZ MEZA",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO PLATAFORMA ELECTORAL PT",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO PLATAFORMA ELECTORAL MC",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO PLATAFORMA ELECTORAL PAC",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO PLATAFORMA ELECTORAL MORENA",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO PLATAFORMA ELECTORAL PES",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO SOBRE CUMPLIMIENTO DEL PORCENTAJE APOYO CIUDADANO",
    },
    {
      id: 31,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "RESOLUCIÓN MODIFICACIÓN AL CONVENIO DE COALICIÓN",
      titleAnexo1: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "ACUERDO POR EL QUE SE DESIGNA AL PERSONAL AUTORIZADO PARA ACCEDER A LA BODEGA ELECTORAL",
    },
    {
      id: 33,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "RESOLUCIÓN REGISTRO DE CANDIDATOS COALICIÓN POR TLAXCALA AL FRENTE",
      titleAnexo1:
        "VOTO CONCURRENTE CONSEJERO ELECTORAL JUAN CARLOS MINOR MÁRQUEZ",
      titleAnexo2: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 34,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "RESOLUCIÓN REGISTRO DE CANDIDATOS COALICIÓN JUNTOS HAREMOS HISTORIA",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERO JUAN CARLOS MINOR MÁRQUEZ",
      titleAnexo2: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "RESOLUCIÓN REGISTRO DE CANDIDATOS MAYORÍA RELATIVA CANDIDATURA COMÚN PRI, PVEM, PANAL Y PS",
    },
    {
      id: 36,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc:
        "RESOLUCIÓN REGISTRO DE CANDIDATURAS INDEPENDIENTES A DIPUTADOS LOCALES",
    },
    {
      id: 37,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PT MAYORÍA Y RP",
    },
    {
      id: 38,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS MC MAYORÍA RELATIVA Y RP",
    },
    {
      id: 39,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PANAL MAYORÍA RELATIVA Y RP",
    },
    {
      id: 40,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS MORENA MAYORÍA Y RP",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERO JUAN CARLOS MINOR MÁRQUEZ",
    },
    {
      id: 41,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PES MAYORÍA Y RP",
    },
    {
      id: 42,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PAN RP",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YARELI ÁLVAREZ MEZA",
      titleAnexo2: "VOTO CONCURRENTE CONSEJERO JUAN CARLOS MINOR MÁRQUEZ",
    },
    {
      id: 43,
      typeDoc: "",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PRI RP",
    },
    {
      id: 44,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "RESOLUCIÓN REGISTRO DE CANDIDATOS PRD RP",
      titleAnexo1: "VOTO CONCURRENTE CONSEJERA YARELI ÁLVAREZ MEZA",
      titleAnexo2:
        "VOTO CONCURRENTE CONSEJERO JUAN CARLOS MINOR MÁRQUEZ PROYECTO DE RESOLUCIÓN PRD RP",
    },
    {
      id: 45,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATOS PVEM",
    },
    {
      id: 46,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATOS PAC RP",
    },
    {
      id: 47,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "REGISTRO DE CANDIDATOS PS RP",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "READECUACIÓN AL PRESUPUESTO 2018 ISR",
      titleAnexo1: "ANEXO 1 READECUACIÓN AL PRESUPUESTO 2018 ISR",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "READECUACIÓN AL PRESUPUESTO 2018",
      titleAnexo1: "ANEXO 1 READECUACIÓN AL PRESUPUESTO 2018",
      titleAnexo2: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DESIGNACIÓN E INCORPORACIÓN SPEN SISTEMA OPLE",
    },
    {
      id: 51,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS DE MAYORÍA RELATIVA Y RP PARTIDO MC",
      titleAnexo1: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 52,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ABR",
      nameDoc: "POR LOS PRINCIPIOS DE MAYORÍA RELATIVA Y RP PES",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "HORA DE INICIO Y CIERRE PREP",
      titleAnexo1: "VOTO RAZONADO CONSEJERA YARELI ÁLVAREZ MEZA",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "DISEÑO Y MODELOS DEFINITIVOS DE DOCUMENTACIÓN Y MATERIAL ELECTORAL",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES DE CONSEJOS DISTRITALES 10 Y 15",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE APRUEBA EL CÁLCULO DEL MONTO DE FINANCIAMIENTO PÚBLICO PARA LA OBTENCIÓN DEL VOTO",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBAN LOS CRITERIOS RELATIVOS A LOS CIERRES DE CAMPAÑA",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA EL MODELO OPERATIVO DE REMISIÓN Y RECEPCIÓN DE LOS PAQUETES ELECTORALES",
      titleAnexo1: "MODELO OPERATIVO RECEPCIÓN DE PAQUETES ELECTORALES 2018",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBAN LAS MEDIDAS DE SEGURIDAD QUE CONTENDRÁN LAS BOLETAS ELECTORALES, ASÍ COMO LA DETERMINACIÓN DE FECHA LÍMITE EN QUE SE PODRÁN MODIFICAR",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE DA RESPUESTA AL ESCRITO PRESENTADO POR LA LICENCIADA LAURA YAMILI FLORES LOZANO",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE DA RESPUESTA AL OFICIO PRESENTADO POR LA LICENCIADA ROSALÍA PEREDO AGUILAR",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE DA CUMPLIMIENTO A LA SENTENCIA DICTADA POR EL TET EXPEDIENTE TET JDC 023 2018 Y ACUMULADO TET JDC 024 2018.",
    },
    {
      id: 67,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc:
        "RESOLUCIÓN RESPECTO DE LAS SUSTITUCIONES DE CANDIDATAS Y CANDIDATOS AL CARGO DE DIPUTADAS Y DIPUTADOS LOCALES",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA EL CAMBIO DE EMBLEMA DEL CANDIDATO INDEPENDIENTE",
    },
    {
      id: 69,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc:
        "RESOLUCIÓN SUSTITUCIONES DE CANDIDATAS Y CANDIDATOS AL CARGO DE DIPUTADAS Y DIPUTADOS LOCALES",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ACUERDO NO INCLUSIÓN DE NOMBRES E INCLUSIÓN DE SOBRENOMBRES",
    },
    {
      id: 71,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DE SUSTITUCIÓN MOVIMIENTO CIUDADANO DISTRITO 06",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE DESIGNA AL PERSONAL AUTORIZADO PARA EL CONTEO, SELLADO Y AGRUPAMIENTO DE LAS BOLETAS ELECTORALES",
      titleAnexo1:
        "ANEXO POR EL QUE SE DESIGNA AL PERSONAL AUTORIZADO PARA EL CONTEO, SELLADO Y AGRUPAMIENTO DE LAS BOLETAS ELECTORALES",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA REUBICACIÓN DEL CATD DISTRITO 08",
    },
    {
      id: 74,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc:
        "RESOLUCIÓN RESPECTO DE LA SUSTITUCIÓN DE LA CANDIDATA PROPIETARIA AL CARGO DE DIPUTADA LOCAL POR EL PRINCIPIO DE RP CON ORDEN DE PRELACIÓN UNO PANAL",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA SUSTITUCIÓN DE CONSEJEROS SUPLENTES DE LOS C D 04, 08 Y 13",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA SUSTITUCIÓN DE INTEGRANTES DEL CONSEJO DISTRITAL 04, CON CABECERA EN APIZACO",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE APRUEBA LA DESIGNACIÓN DE LAS MEDIDAS DE SEGURIDAD EN LAS BOLETAS ELECTORALES",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "POR EL QUE SE APRUEBA LA REIMPRESIÓN Y MEDIDAS DE SEGURIDAD DE LAS BOLETAS ELECTORALES QUE SE UTILIZARÁN EN LA SECCIÓN 79, CASILLA BÁSICA 1 CALPULALPAN",
    },
    {
      id: 79,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc:
        "RESOLUCIÓN RESPECTO DE LAS SUSTITUCIONES DE LOS CANDIDATOS A DIPUTADOS SUPLENTES POR EL PRD",
    },
    {
      id: 80,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc:
        "RESOLUCIÓN RESPECTO DE LA SUSTITUCIÓN DE LA CANDIDATA SUPLENTE AL CARGO DE DIPUTADA LOCAL POR EL PRINCIPIO DE MR EN EL DISTRITO 02 CON CABECERA EN TLAXCO DE MORELOS",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE HABILITA AL PERSONAL PARA LA IMPLEMENTACIÓN Y OPERACIÓN, DEL “MODELO OPERATIVO DE RECEPCIÓN DE LOS PAQUETES ELECTORALES",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "ACUERDO POR EL QUE SE DA RESPUESTA A LA SOLICITUD REALIZADA POR LA CIUDADANA MA. BEATRIZ MUÑOZ AGUILAR, INTEGRANTE DEL COMITÉ DIRECTIVO NACIONAL DE ENCUENTRO SOCIAL",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "",
      titleAnexo1:
        "ANEXO POR EL QUE SE DESIGNA PERSONAL QUE FUNGIRÁ COMO ENLACES DE COMUNICACIÓN Y RESPONSABLES DE TRASLADO, ENTREGA, RECEPCIÓN E INTERCAMBIO DE PAQUETES ELECTORALES",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "RETIRO DE LA PROPAGANDA",
      titleAnexo1: "ANEXO PROCEDIMIENTO RETIRO DE PROPAGANDA 2018",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE ESTABLECE LA FORMA DE EJECUTAR LAS MULTAS PREVISTAS EN LAS RESOLUCIONES INE-CG528-2017",
      titleAnexo1: "ANEXO",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE ESTABLECE LA FORMA DE EJECUTAR LAS MULTAS PREVISTAS EN LA RESOLUCIÓN INECG355-2018 DEL INE",
      titleAnexo1: "ANEXO",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "SE DECLARA LA INTEGRACIÓN DE LA LXIII LEGISLATURA, DEL CONGRESO DEL ESTADO LIBRE Y SOBERANO DE TLAXCALA",
    },
    {
      id: 89,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc:
        "PROCEDIMIENTO ORDINARIO SANCIONADOR CON NÚMERO DE EXPEDIENTE CQDCACG0012018",
    },
    {
      id: 90,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc:
        "PROCEDIMIENTO ORDINARIO SANCIONADOR CON NÚMERO DE EXPEDIENTE CQDQNSPHCG0022018",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INTEGRACIÓN DE LA JUNTA GENERAL",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INTEGRACIÓN DE COMISIONES",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE DECLARA LA CANCELACIÓN DE LA ACREDITACIÓN DE PANAL Y PES",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PROYECTO DE ACUERDO PRESUPUESTO DE EGRESOS 2019",
      titleAnexo1: "ANEXO 1 AO",
      titleAnexo2: "ANEXO 2 AE",
      titleAnexo3: "ANEXO 3 PRESUPUESTO 2019",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "READECUACIÓN AL PRESUPUESTO 2018 ISR",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESTRUCCIÓN DE MATERIAL ELECTORAL",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "OFICIO PRESENTADO POR NUEVA ALIANZA",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ESCRITO PRESENTADO POR ENCUENTRO SOCIAL",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ADECUACIÓN DE COMISIONES",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DESIGNACIÓN DE DIRECTORA DOECYEC",
    },
    {
      id: 101,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "DICTAMEN NUEVA ALIANZA TLAXCALA",
      titleAnexo1: "ANEXO ÚNICO",
    },
    {
      id: 102,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "READECUACIÓN AL PRESUPUESTO 2018 ISR",
      titleAnexo1: "ANEXO ÚNICO",
    },
  ],

  2017: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN DEL PRESUPUESTO 2017",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "DESIGNACIÓN DEL RESPONSABLE DE ARCHIVOS E INTEGRACIÓN DEL COMITÉ TÉCNICO DE ARCHIVOS",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "APROBACIÓN DE LINEAMIENTOS PARA LA DESTRUCCIÓN DE MATERIAL ELECTORAL",
      titleAnexo1:
        "ANEXO 1 LINEAMIENTOS PARA LA DESTRUCCIÓN DE MATERIAL ELECTORAL",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "DESIGNACIÓN DEL PERSONAL AUTORIZADO PARA ACCEDER A BODEGA ELECTORAL",
      titleAnexo1: "ANEXO ÚNICO PERSONAL AUTORIZADO",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DESIGNACIÓN DEL DIRECTOR DE ASUNTOS JURÍDICOS",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "CUMPLIMIENTO A LO DICTADO EN EL EXPEDIENTE TET JE 002 2017 Y ACUMULADOS",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CALENDARIO PARA EL PROCESO ELECTORAL EXTRAORDINARIO 2017",
      titleAnexo1: "ANEXO CALENDARIO PROCESO ELECTORAL EXTRAORDINARIO 2017",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "PRORROGA DE VIGENCIA DE ACUERDOS PARA EL PROCESO ELECTORAL EXTRAORDINARIO 2017",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "COMISIONES TEMPORALES SEGUIMIENTO A SISTEMAS INFORMÁTICOS Y DEBATES",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "CONVOCATORIA INDEPENDIENTES PROCESO ELECTORAL EXTRAORDINARIO 2017",
      titleAnexo1: "ANEXO CONVOCATORIA INDEPENDIENTES 2017",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "MULTAS PARTIDO ALIANZA CIUDADANA",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN PARA FORMACIÓN DE PARTIDO POLÍTICO",
      titleAnexo1: "ANEXO DICTAMEN CPPPAYF",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN PARTIDO JOVEN",
      titleAnexo1: "ANEXO DICTAMEN CPPPAYF",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN PARTIDO LIBERAL DE TLAXCALA",
      titleAnexo1: "ANEXO DICTAMEN CPPPAYF",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN PARTIDO AUTÉNTICO DE LA REVOLUCIÓN MEXICANA",
      titleAnexo1: "ANEXO DICTAMEN CPPPAYF",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN IMPACTO SOCIAL SI",
      titleAnexo1: "ANEXO DICTAMEN CPPPAYF",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PARA CRITERIOS DE PARIDAD DE GÉNERO",
      titleAnexo1: "ANEXO VOTO RAZONADO",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "LISTADO ADICIONAL LA PROVIDENCIA SANCTORUM DE LÁZARO CÁRDENAS",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "DOCUMENTACIÓN Y MATERIAL ELECTORAL PROCESO ELECTORAL EXTRAORDINARIO 2017.",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "EN EL QUE SE DETERMINA OMITIR LA INTEGRACIÓN DE CONSEJOS MUNICIPALES",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PROGRAMA DE PROMOCIÓN DEL VOTO ELECCIONES EXTRAORDINARIAS 2017",
      titleAnexo1:
        "ANEXO PROGRAMA DE PROMOCIÓN DEL VOTO EN LAS ELECCIONES EXTRAORDINARIAS 2017.",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PROCEDIMIENTOS Y PLAZOS PREP",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
      titleAnexo3: "ANEXO 3",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "DETERMINACIÓN FECHAS Y HORAS DE INICIO Y CIERRE DE PUBLICACIÓN PREP",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc:
        "TOPE DE GASTOS DE CAMPAÑA PROCESO ELECTORAL EXTRAORDINARIO 2017.",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MANUAL DE REGISTRO DE CANDIDATOS PROCESO EXTRAORDINARIO 2017",
      titleAnexo1: "ANEXO MANUAL DE REGISTRO DE CANDIDATOS P.E.E. 2017",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PARA DESIGNACIÓN DE AUTORIDADES PARA SERVICIO PROFESIONAL",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PAN",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PRI",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PRD",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PT",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PAC",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN PS",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE GOBIERNO COMÚN MORENA",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PREP PROCESO ELECTORAL EXTRAORDINARIO 2017",
      titleAnexo1: "ANEXO DICTAMEN SISTEMAS INFORMÁTICOS",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MODELO OPERATIVO REMISIÓN Y RECEPCIÓN PAQUETES ELECTORALES",
      titleAnexo1: "ANEXO MODELO OPERATIVO",
      titleAnexo2: "ANEXO RECIBO DE ENTREGA DEL PAQUETE ELECTORAL AL CG",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ESTRATEGIA DE DISTRIBUCIÓN DE DOCUMENTACIÓN Y MATERIALES ELECTORALES",
      titleAnexo1:
        "ANEXO ESTRATEGIA DOCUMENTACIÓN ELECTORAL PROCESO EXTRAORDINARIO 2017",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO PARIDAD PAN",
      titleAnexo1: "ANEXO VOTO CONCURRENTE",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO PARIDAD PT",
    },
    {
      id: 39,
      typeDoc: "",
      monthDoc: "MAY",
      nameDoc: "REQUERIMIENTO PARIDAD PAC",
      titleAnexo1: "ANEXO VOTOS PARTICULARES",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PRI",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PRD",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PS",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD MORENA",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PAN",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PARTIDO DEL TRABAJO",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PAC",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MEDIDAS DE SEGURIDAD",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO SENTENCIA SCM JRC 12 2017",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RECUENTO BARRIO DE SANTIAGO",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RECUENTO SAN JOSÉ TOXOPA",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PERSONAL AUXILIAR PARA CÓMPUTO PEE 2017",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD SAN CRISTÓBAL ZACACALCO",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD LA PROVIDENCIA",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD SAN MIGUEL BUENAVISTA",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD SECCIÓN TERCERA SANTA MARTHA",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD SAN JOSÉ TEXOPA",
      titleAnexo1:
        "ANEXO 1 VALIDEZ DE ELECCIÓN DE LA COMUNIDAD SAN JOSÉ TEXOPA",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD BARRIO DE SANTIAGO",
      titleAnexo1:
        "ANEXO VALIDEZ DE ELECCIÓN DE LA COMUNIDAD DE BARRIO DE SANTIAGO",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "VALIDEZ DE ELECCIÓN DE LA COMUNIDAD LA GARITA",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "LINEAMIENTOS DE RETIRO DE PROPAGANDA ELECTORAL PEE 2017",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "FORMATOS PARA ORGANIZACIONES DE CIUDADANOS",
      titleAnexo1: "ANEXO 1 FORMATO ITE 01 RPPL",
      titleAnexo2: "ANEXO 2 FORMATO ITE 02 RPPL",
      titleAnexo3: "ANEXO 3 FORMATO ITE 03 RPPL",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "FISCALIZACIÓN ORGANIZACIONES DE CIUDADANOS",
      titleAnexo1: "ANEXO 1 LINEAMIENTOS",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "CUMPLIMIENTO A LA DICTADA DENTRO DEL EXPEDIENTE TET JDC 026 2017",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "ADECUACIÓN AL PRESUPUESTO 2017",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "REMANENTES 2017",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "MULTAS 2017",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DESTRUCCIÓN DE MATERIAL ELECTORAL",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "MULTA INE CG810 2017",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PERSONAL HABILITADO PARA ASAMBLEAS",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "FIRMA DE CONVENIOS",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "LINEAMIENTOS CÓMPUTOS DISTRITALES 2017 2018",
      titleAnexo1:
        "ANEXO 1 CUADERNILLO DE CONSULTA VOTOS VÁLIDOS Y NULOS ITE 2018",
      titleAnexo2: "ANEXO 2 LINEAMIENTOS CÓMPUTOS LOCALES ITE 2018",
    },
    {
      id: 71,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "PARTIDO ALIANZA CIUDADANA",
      titleAnexo1: "ANEXO 1 PARTIDO ALIANZA CIUDADANA",
    },
    {
      id: 72,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "PARTIDO SOCIALISTA",
      titleAnexo1: "ANEXO 1 PARTIDO SOCIALISTA",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ADECUACIÓN PRESUPUESTO 2017",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PROYECTO DE PRESUPUESTO DE EGRESOS 2018",
      titleAnexo1: "ANEXO 1 PROYECTO DE PRESUPUESTO DE EGRESOS 2018",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DESIGNACIÓN DE DIRECTORES Y TITULARES",
      titleAnexo1:
        "ANEXO 1 VOTO PARTICULAR CONSEJERA ELECTORAL DORA RODRÍGUEZ SORIANO",
      titleAnexo2:
        "ANEXO 2 VOTO PARTICULAR CONSEJERA ELECTORAL YARELI ÁLVAREZ MEZA",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "ADECUACIÓN E INTEGRACIÓN DE COMISIONES PERMANENTES Y TEMPORALES",
      titleAnexo1:
        "ANEXO 1VOTO RAZONADO CONSEJERA ELECTORAL YARELI ÁLVAREZ MEZA",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CALENDARIO PROCESO ELECTORAL ORDINARIO 2018",
      titleAnexo1: "ANEXO 1 CALENDARIO PROCESO ELECTORAL ORDINARIO 2018",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVOCATORIA PROCESO ELECTORAL ORDINARIO 2018",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MANUAL DE PROCEDIMIENTO LABORAL DISCIPLINARIO",
      titleAnexo1: "ANEXO MANUAL DEL PROCEDIMIENTO LABORAL DISCIPLINARIO",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SERVICIO PROFESIONAL ELECTORAL NACIONAL OPLE",
      titleAnexo1:
        "ANEXO 1 VOTO CONCURRENTE CONSEJERA ELECTORAL DORA RODRÍGUEZ SORIANO",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MODELO ÚNICO ESTATUTO CANDIDATOS INDEPENDIENTES",
      titleAnexo1: "ANEXO ESTATUTO CANDIDATOS INDEPENDIENTES 2018",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PERSONAL HABILITADO PARA ASAMBLEA ESTATAL IMPACTO SOCIAL SI",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "METODOLOGÍA DE MONITOREO DE MEDIOS",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CONVOCATORIA CANDIDATOS INDEPENDIENTES",
      titleAnexo1: "ANEXO CONVOCATORIA CANDIDATOS INDEPENDIENTES",
      titleAnexo2: "ANEXO UNO FORMATO DE MANIFESTACIÓN DE INTENCIÓN ITE",
      titleAnexo3:
        "ANEXO DOS FORMATO DE SOLICITUD DE REGISTRO DE CANDIDATURA INDEPENDIENTE ITE",
      titleAnexo4: "ANEXO TRES FORMATO DE MANIFESTACIÓN DE VOLUNTAD ITE",
      titleAnexo5: "ANEXO CUATRO FORMATO DE NO ACEPTACIÓN DE RECURSOS ILÍCITOS",
      titleAnexo6:
        "ANEXO CINCO FORMATO DE ESCRITO DE CONFORMIDAD PARA FISCALIZACIÓN INE",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PAUTAS RADIO Y TELEVISIÓN",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "TITULAR ÁREA TÉCNICA DE TRANSPARENCIA",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DESIGNACIÓN DE LA INSTANCIA INTERNA PREP",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "POR EL QUE SE DA CUMPLIMIENTO A LA SENTENCIA DEL EXPEDIENTE TET JDC 054 2017",
      titleAnexo1:
        "ANEXO CONVOCATORIA CUMPLIMIENTO A SENTENCIA DEL EXPEDIENTE TET JDC 054 2017",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PROYECTO DE TOPES DE PRECAMPAÑA 2018",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "LINEAMIENTOS GENERALES DE PARIDAD DE GÉNERO",
      titleAnexo1: "ANEXO LINEAMIENTOS PARIDAD DE GÉNERO DIPUTADOS",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "LINEAMIENTOS DE VERIFICACIÓN DE APOYO CIUDADANO",
      titleAnexo1:
        "ANEXO LINEAMIENTOS PARA LA VERIFICACIÓN DEL PORCENTAJE DE APOYO CIUDADANO",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "INTEGRACIÓN COTAPREP 2018",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DESIGNACIÓN DEL TITULAR DEL ÁREA TÉCNICA DE INFORMÁTICA",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "LINEAMIENTOS RADIO Y TELEVISIÓN",
      titleAnexo1: "ANEXO LINEAMIENTOS RADIO Y TELEVISIÓN",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "REFORMA REGLAMENTO DE QUEJAS Y DENUNCIAS",
      titleAnexo1: "ANEXO REFORMA REGLAMENTO DE QUEJAS Y DENUNCIAS",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DESIGNACIÓN DEL PERSONAL APERTURA DE BODEGA PEE 2017",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "METODOLOGÍA MONITOREO",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SERVICIO PROFESIONAL ELECTORAL NACIONAL OPLE",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ADECUACIÓN A PRESUPUESTO",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DOCUMENTOS Y MATERIALES ELECTORALES",
      titleAnexo1:
        "ANEXOS DICTAMEN Y ESPECIFICACIONES TÉCNICAS DE DOCUMENTACIÓN ELECTORAL",
    },
  ],

  2016: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CUMPLIMIENTO SALA SUPERIOR CANDIDATOS INDEPENDIENTES",
      titleAnexo1: "ANEXO 1 FORMATOS DE CANDIDATURAS INDEPENDIENTES",
      titleAnexo2:
        "ANEXO 2 REGLAMENTO PARA EL REGISTRO DE CANDIDATURAS INDEPENDIENTES",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "COALICIÓN PRI PVEM PNA PS PARA LA ELECCIÓN DE GOBERNADOR",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN PRESUPUESTO",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ASPIRANTES CANDIDATOS INDEPENDIENTES",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "RESOLUCIÓN PAC",
      titleAnexo1: "ANEXO DICTAMEN PAC",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CONVOCATORIA CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo1: "ANEXO 1 CONVOCATORIA CONSEJOS DISTRITALES Y MUNICIPALES",
      titleAnexo2: "ANEXO 2 MANIFIESTO BAJO PROTESTA CDyM",
      titleAnexo3: "ANEXO 3 SOLICITUD",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "COMITÉ DE ADQUISICIONES",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "NOMBRAMIENTO DIRECTOR JURÍDICO",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CANDIDATOS INDEPENDIENTES PRESIDENTES DE COMUNIDAD",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DE APOYO CIUDADANO DE AYUNTAMIENTOS Y DIPUTADOS",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PAN",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PRI",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PRD",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PVEM",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA MC",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA NA",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PS",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA MORENA.",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA ENC SOC",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PT",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CANDIDATURA COMÚN PRI",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CANDIDATURA PRD PT",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PRESIDENTE PAC",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PLATAFORMA PAC",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "AMPLIACIÓN DE VERIFICACIÓN DE PORCENTAJE A GOBERNADOR",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "VERIFICACIÓN DE PORCENTAJE A GOBERNADOR",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PAN",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PRI",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PRD",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PT",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PVEM",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA MC",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PANAL",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PAC",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PS",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA MORENA",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PROGRAMA PES",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "BENITO Y MELISSA CANDIDATOS INDEPENDIENTES",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PAC BERNARDINO",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PRI PVEM PANAL Y PS",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PRD PT",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PVEM PS",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ALFONSO CANO",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CUMPLIMIENTO SUP JDC 1181 2016 DE JORGE MORENO DURAN",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DESIGNACIÓN DEL COMITÉ PREP",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DOCUMENTACIÓN Y MATERIAL ELECTORAL",
      subows: "",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "BOLETAS",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CUMPLIMIENTO JOSÉ EFRÉN SANTACRUZ MOCTEZUMA BUENO",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR COALICIÓN PRI PVEM NA PS",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR PAN",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR PRD",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR MOVIMIENTO CIUDADANO",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR PAC",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR MORENA",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR PES",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "GOBERNADOR INDEPENDIENTE JACOB",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP PAN",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP DEL PARTIDO DEL TRABAJO",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP MOVIMIENTO CIUDADANO",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP PAC",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP PARTIDO SOCIALISTA",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP MORENA",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADOS MR Y RP ENCUENTRO SOCIAL",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADA INDEPENDIENTE MELISA IRASEMA VAZQUEZ MOLINA",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADO INDEPENDIENTE BENITO SALDÍVAR SANCHEZ",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIPUTADO INDEPENDIENTE BENEBERTO SANCHEZ VAZQUEZ",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO CANDIDATOS INDEPENDIENTES",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MONUMENTOS Y ZONAS ARQUEOLÓGICAS",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CANDIDATURA COMÚN DIPUTADOS",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: " TOPES DE CAMPAÑA",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN CONSEJOS",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CANDIDATOS COMUNES DIPUTADOS PRI PVEM Y PANAL",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CANDIDATOS COMUNES DIPUTADOS PRI Y PANAL",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PVEM MAYORÍA Y RP",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MC MAYORÍA Y RP",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PRI RP",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PANAL RP",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ADQUISICIÓN",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CUMPLIMIENTO SUP JDC 1481 2016 DE JORGE MORENO DURAN",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PREP",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MEDIDAS DE SEGURIDAD",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN MC",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "EMBLEMA INDEPENDIENTES",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIONES CONSEJOS",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "PROMOCIÓN DEL VOTO",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ALFONSO CANO",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ADENDA PRI",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "JORGE MORENO",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AYUNTAM. CANDIDATURA COMÚN PRI PVEM NUEVA ALIANZA PS",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AYUNTAM. CANDIDATURA COMÚN PRI PVEM NUEVA ALIANZA",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO AYUNTAMIENTOS PT",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: " AYUNTAM. CANDIDATURA COMÚN PRI PVEM",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AYUNTAM. CANDIDATURA COMÚN PRI NUEVA ALIANZA PS",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CANDIDATURA COMÚN PRI NUEVA ALIANZA",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AYUNTAM. CANDIDATURA COMÚN PRI PS",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO AYUNTAMIENTOS CANDIDATURA PRD PT",
    },
    {
      id: 101,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "AYUNTAM. CANDIDATURA COMÚN PVEM PS",
    },
    {
      id: 102,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REGISTRO AYUNTAM. PAN",
    },
    {
      id: 103,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REGISTRO AYUNTAM. PRI",
    },
    {
      id: 104,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REGISTRO AYUNTAM. PVEM",
    },
    {
      id: 105,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO AYUNTAMIENTO MOVIMIENTO CIUDADANO",
    },
    {
      id: 106,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA NUEVA ALIANZA",
    },
    {
      id: 107,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA AYUNTAMIENTOS PAC",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REGISTRO AYUNTAM. PS",
    },
    {
      id: 109,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MORENA AYUNTAMIENTOS",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO AYUNTAMIENTO PES",
    },
    {
      id: 111,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MUNICIPIOS INDEPENDIENTES",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO AYUNTAMIENTOS PRD",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN DIPUTADO PAN",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO AYUNTAMIENTOS",
    },
    {
      id: 116,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "COMUNIDADES INDEPENDIENTES",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PAN",
    },
    {
      id: 118,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PRI",
    },
    {
      id: 119,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PRD",
    },
    {
      id: 120,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PT",
    },
    {
      id: 121,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PVEM",
    },
    {
      id: 122,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA DE COMUNIDAD MC",
    },
    {
      id: 123,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD NUEVA ALIANZA",
    },
    {
      id: 124,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PAC",
    },
    {
      id: 125,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PS",
    },
    {
      id: 126,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD MORENA",
    },
    {
      id: 127,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RESERVA REGISTRO PRESIDENCIAS DE COMUNIDAD PES",
    },
    {
      id: 128,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "TOPES AYUNTAMIENTOS",
    },
    {
      id: 129,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DISTRIBUCIÓN A CADA CANDIDATO",
    },
    {
      id: 130,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIRIGENCIA PAC",
    },
    {
      id: 131,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN DIPUTADO PT",
    },
    {
      id: 132,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN DIPUTADO PAC",
    },
    {
      id: 133,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN DIPUTADO PAN",
    },
    {
      id: 134,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO SANTA CRUZ TLAXCALA PAN",
    },
    {
      id: 135,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TEPETITLA DE LARDIZÁBAL PAN",
    },
    {
      id: 136,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CÓMPUTOS",
    },
    {
      id: 137,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SUSTITUCIÓN PVEM AYUNTAMIENTOS",
    },
    {
      id: 138,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REGISTRO AYUNTAM. PAC",
    },
    {
      id: 139,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO APETATITLÁN PAN",
    },
    {
      id: 140,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REGISTRO AYUNTAMIENTOS DEFINITIVO MC",
    },
    {
      id: 141,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "AYUNTAMIENTOS PRD PT",
    },
    {
      id: 142,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "AYUNTAMIENTOS PRD",
    },
    {
      id: 143,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MOVIMIENTO CIUDADANO PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 144,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PVEM COMUNIDADES",
    },
    {
      id: 145,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADO PAC",
    },
    {
      id: 146,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "AYUNTAMIENTOS PES FINAL",
    },
    {
      id: 147,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "AYUNTAM. NUEVA ALIANZA",
    },
    {
      id: 148,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "AYUNTAMIENTOS PT",
    },
    {
      id: 149,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DE PRESIDENCIAS DE COMUNIDAD PAN",
    },
    {
      id: 150,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DE PRESIDENCIAS DE COMUNIDAD PRI",
    },
    {
      id: 151,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PAC",
    },
    {
      id: 152,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA COMUNIDAD MORENA",
    },
    {
      id: 153,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA COMUNIDAD PES",
    },
    {
      id: 154,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA PRESIDENCIAS DE COMUNIDAD PRD",
    },
    {
      id: 155,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA PT",
    },
    {
      id: 156,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA PRESIDENCIAS DE COMUNIDAD PNA",
    },
    {
      id: 157,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "RESERVA PRESIDENCIAS DE COMUNIDAD PS",
    },
    {
      id: 158,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADOS DISTRITO 12 Y 3 FORMULA",
    },
    {
      id: 159,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES PRD",
    },
    {
      id: 160,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PRESIDENCIAS DE COMUNIDAD PNA",
    },
    {
      id: 161,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PAC PRESIDENCIAS DE COMUNIDAD DEFINITIVO",
    },
    {
      id: 162,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN GOBERNADOR PES",
    },
    {
      id: 163,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADO DISTRITO 03 PVEM",
    },
    {
      id: 164,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES PT",
    },
    {
      id: 165,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES FINAL PES",
    },
    {
      id: 166,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES FINAL PS",
    },
    {
      id: 167,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES FINAL MORENA",
    },
    {
      id: 168,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO SAN FRANCISCO TETLANOHCAN PRD",
    },
    {
      id: 169,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO APETATITLÁN MC PENDIENTE",
    },
    {
      id: 170,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TZOMPANTEPEC PRD",
    },
    {
      id: 171,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO MUÑOZ DE DOMINGO ARENAS PNA",
    },
    {
      id: 172,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOBRENOMBRES 2016",
    },
    {
      id: 173,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO RESOLUCIÓN INE CG299 2016",
    },
    {
      id: 174,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO DE EMILIANO ZAPATA MORENA",
    },
    {
      id: 176,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADA 15 PS",
    },
    {
      id: 177,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA OPERACIÓN SIJE",
      titleAnexo1: "ANEXO 1 F1_01",
      titleAnexo2: "AMEXO 2 PROGRAMA OPERACIÓN SIJE",
      titleAnexo3: "ANEXO 3 SIJE_LMD_F2",
    },
    {
      id: 178,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PT",
    },
    {
      id: 179,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC SANTA CRUZ PORVENIR IXTACUIXTA NA",
    },
    {
      id: 180,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC COLONIA REFORMA CHIAUTEMPAN PS",
    },
    {
      id: 181,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION PC SUPLENTE ACUITLAPILCO PS",
    },
    {
      id: 182,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC TLALTEMPAN MORENA",
    },
    {
      id: 183,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ACUERDO SUSTITUCIÓN PRI DTO 01 MAY RELAT",
    },
    {
      id: 184,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "BODEGAS CUMPLIMIENTO ACUERDO INE CG122 2016",
    },
    {
      id: 185,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION DE INTEGRANTES DE CONSEJO",
    },
    {
      id: 186,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CANDIDATURA COMÚN PRI PVEM NUEVA ALIANZA PS",
    },
    {
      id: 187,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CANDIDATURA COMÚN PRI PVEM",
    },
    {
      id: 188,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CANDIDATURA COMÚN PRI NUEVA ALIANZA PS",
    },
    {
      id: 189,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CANDIDATURA COMUN PRD PT",
    },
    {
      id: 190,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REGISTRO AYUNTAM PRI",
    },
    {
      id: 191,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CASILLAS ESPECIALES",
    },
    {
      id: 192,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO XILOXOXTLA PAN",
    },
    {
      id: 193,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO BENITO JUÁREZ PRI",
    },
    {
      id: 194,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC ATLANGATEPEC PAN",
    },
    {
      id: 195,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN CONSEJOS MUNICIPALES",
    },
    {
      id: 196,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN SEGUNDA REGIDORA ATLANGATEPEC PRI",
    },
    {
      id: 197,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN LÁZARO CARDENAS PRI",
    },
    {
      id: 198,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PRIMERA REGIDORA SANTA CRUZ TLAXCALA PT",
    },
    {
      id: 199,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC TLAXCO PRI",
    },
    {
      id: 200,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC SANTA CARINA AYOMETLA PT",
    },
    {
      id: 201,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CAMBIO DE DOMICILIO DE CATD",
    },
    {
      id: 202,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADO MR 4 MORENA",
    },
    {
      id: 203,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN DIPUTADO RPP 3 MORENA",
    },
    {
      id: 204,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN SANTA ISABEL XILOXOXTLA PRD PT",
    },
    {
      id: 205,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR HUAMANTLA PVEM",
    },
    {
      id: 206,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "SUSTITUCIÓN PC RANCHERIA ALTAMIRA DE GUADALUPE HUAMANTLA MORENA",
    },
    {
      id: 207,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC SAN JORGE TEXOQUIPAN PANOTLA MORENA",
    },
    {
      id: 208,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION PRESIDENTE COMUNIDAD ACUITLAPILCO PT",
    },
    {
      id: 209,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION DE COMUNIDAD PT",
    },
    {
      id: 210,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PAC",
    },
    {
      id: 211,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR CALPULALPAN PES",
    },
    {
      id: 212,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MORENA SORTEO",
    },
    {
      id: 213,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PAC SORTEO",
    },
    {
      id: 214,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PES SORTEO",
    },
    {
      id: 215,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PNA SORTEO",
    },
    {
      id: 216,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO SALA REGIONAL PRD PANOTLA",
    },
    {
      id: 217,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CUMPLIMIENTO SALA REGIONAL PRD APIZACO",
    },
    {
      id: 218,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES PARTIDO DEL TRABAJO",
    },
    {
      id: 219,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES MOVIMIENTO CIUDADANO",
    },
    {
      id: 220,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES PRD",
    },
    {
      id: 221,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMUNIDADES MORENA",
    },
    {
      id: 222,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PREP PLAN DE SEGURIDAD Y CONTINUIDAD",
    },
    {
      id: 223,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PAN DTTO. 10 PAN",
    },
    {
      id: 224,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PC XALOZTOC PRI",
    },
    {
      id: 225,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PRI SEXTA REGIDORA EL CARMEN TEQUEXQUITLA PRI",
    },
    {
      id: 226,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN SINDICO Y SEGUNDO REGIDOR CALPULALPAN",
    },
    {
      id: 227,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROYECTO SUSTITUCIÓN AYUNTAMIENTO TETLATLAHUCA PAC.",
    },
    {
      id: 228,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "SUSTITUCIÓN PRESIDENCIA DE COM PS BARRIO SAN ANTONIO ATLTZAYANCA",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCION DIPUTADO SUPLENTE PAN MR OCTAVIO ALEJANDRO ESPEJEL",
    },
    {
      id: 230,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SORTEO PT",
    },
    {
      id: 232,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "REGISTRO MC CUMPLIMIENTO",
    },
    {
      id: 233,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO YAUHQUEMEHCAN PRI",
    },
    {
      id: 234,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN SÍNDICO PROPIETARIO AYOMETLA PRI",
    },
    {
      id: 235,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PRESIDENTE SUPLENTE TETLA PRD",
    },
    {
      id: 236,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN PT LÁZARO CÁRDENAS",
    },
    {
      id: 237,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PNA 01 06 16 2 ",
    },
    {
      id: 238,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ENTE AUDITOR PREP",
    },
    {
      id: 239,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PS TETLA",
    },
    {
      id: 240,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PS PTE SUP Y 1REG PROP MUÑOZ DE DOMINGO ARENAS",
    },
    {
      id: 241,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PROYECTO SUSTITUCIÓN LAZARO CARDENAS PRI",
    },
    {
      id: 242,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN PRESIDENCIA DE COM BARRIO LA PRECIOSA HUAMANTLA PNA",
    },
    {
      id: 243,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN SEGUNDA REGIDORA PROP AYUNT PNA",
    },
    {
      id: 244,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN DIPUTADA SUPLENTE MR MARIA DEL ROCIO RAMIREZ DIEGUEZ PAN",
    },
    {
      id: 245,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN DE PAN NANACAMILPA",
    },
    {
      id: 246,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PAN LA MAGDALENA",
      subRow: "",
    },
    {
      id: 247,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PRESIDENTA SUPLENTE TZOMPANTEPEC PES",
    },
    {
      id: 248,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN FORMULA DIPUTADA MR TLAXCO PES",
    },
    {
      id: 249,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN 2DA SECCIÓN TEOTLALPAN TETLA PT",
    },
    {
      id: 250,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PT 29 05 2016",
    },
    {
      id: 251,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PRESIDENCIA DE COM PS CHIMALPA TLAXCALA",
    },
    {
      id: 252,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN  CONSEJO DISTRITAL Y MUNICIPALES3",
    },
    {
      id: 253,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PAN CALPULALPAN DIPUTADO LOCAL SUPLENTE DITO. 01",
    },
    {
      id: 254,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO NA 02 06 16",
    },
    {
      id: 255,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CUMPLIMIENTO SENTENCIA TET PT",
    },
    {
      id: 256,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO LA MAGDALENA TLALTELULCO PAN",
    },
    {
      id: 257,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN 1ER. REGIDOR AYUNTAMIENTO ZACATELCO PAN",
    },
    {
      id: 258,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO DE APIZACO PRI",
    },
    {
      id: 259,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO 2DO. REGIDOR PRD",
    },
    {
      id: 260,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PTE. MPAL APIZACO VERDE",
    },
    {
      id: 261,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN AYUNTAMIENTO YAHUQUEMEHCAN Y PRIMER REGIDOR PROP Y SUP VERDE",
    },
    {
      id: 262,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TETLATLAHUCA PT",
    },
    {
      id: 263,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TERRENATE 2 REGIDOR PANAL",
    },
    {
      id: 264,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TLAXCO PANAL",
    },
    {
      id: 265,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO DE ATLTZAYANCA MORENA",
    },
    {
      id: 266,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO NATIVITAS MORENA",
    },
    {
      id: 267,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO TLAXCALA MORENA",
    },
    {
      id: 268,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN PRIMER REGIDORA PROPIETARIA Y SUPLENTE TETLA DE LA SOLIDARIDAD MORENA",
    },
    {
      id: 269,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN TERCER REGIDORA SUPLENTE TOTOLAC MORENA",
    },
    {
      id: 270,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO HUAMANTLA 1REGIDOR PAC",
    },
    {
      id: 271,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PRESIDENCIA DE COMUNIDAD DE COLHUACA CONTLA PAC",
    },
    {
      id: 272,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN  CONSEJERO ELECTORAL CONSEJO DISTRITAL 10 HUAMANTLA",
    },
    {
      id: 273,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CELULARES",
    },
    {
      id: 274,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN 1ER. REGIDOR MUNICIPAL APIZACO PRD",
    },
    {
      id: 275,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN PRIMER REGIDORA PROPIETARIA SAN JUAN HUACTZINCO PVEM",
    },
    {
      id: 276,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN AYUNTAMIENTO SAN FRANCISCO TETLANOHCAN Y AMAXAC DE GUERRERO PVEM",
    },
    {
      id: 277,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PVEM MUN CONTLA DE JUAN C 1ER REGIDOR",
    },
    {
      id: 278,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN PC SAN HIPÓLITO CHIMALPA PT",
    },
    {
      id: 279,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "SUSTITUCIÓN PRIMER REGIDORA PROPIETARIA APETATITLÁN DE ANTONIO CARVAJAL  PES",
    },
    {
      id: 280,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PES 1 REGIDOR TEACALCO SPM",
    },
    {
      id: 281,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO PES 1ER Y4TO REGIDOR SPM",
    },
    {
      id: 283,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "MODELO OPERATIVO PAQUETES",
    },
    {
      id: 284,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SUTITUCIÓN AYUNTAMIENTO TENANCINGO Y SAN DAMIÁN TEXOLOC MORENA",
    },
    {
      id: 285,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PROCEDIMIENTO CONTINUACIÓN COMPUTO DISTRITAL 14",
    },
    {
      id: 286,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CÓMPUTO Y DECLARACIÓN DE VALIDEZ GOBERNADOR",
    },
    {
      id: 287,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "CANCELACIÓN DE REGISTRO POR NO ALCANZAR EL 325 SECRETARÍA 16 06 2016",
    },
    {
      id: 288,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASIGNACIÓN DIPUTADOS DE REPRESENTACIÓN PROPORCIONAL",
    },
    {
      id: 289,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ASIGNACIÓN REGIDURÍAS",
    },
    {
      id: 290,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc:
        "CUMPLIMIENTO TRIBUNAL ELECTORAL DE TLAXCALA AYUNTAMIENTO DE APIZACO PRI",
    },
    {
      id: 291,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RETIRO PROPAGANDA POLÍTICA",
    },
    {
      id: 292,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SERVICIO PROFESIONAL ELECTORAL NACIONAL",
    },
    {
      id: 293,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "REGIDURÍAS ITE CUMPLIMIENTO DE SENTENCIA TET JDC 250 2016",
    },
    {
      id: 294,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "ADECUACIÓN COMISIONES PERMANENTES",
    },
    {
      id: 295,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "COMITÉ DE TRANSPARENCIA",
    },
    {
      id: 296,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SANCIÓN PAC",
    },
    {
      id: 297,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SANCIÓN-PAC",
      titleAnexo1: "ANEXO PRESUPUESTO AGOSTO",
    },
    {
      id: 298,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGIDORA AMAXAC DE GUERRERO PVEM",
    },
    {
      id: 299,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "DESIGNACIÓN DE PERSONAL PARA RECUENTO DE VOTOS MUNICIPIO TZOMPANTEPEC",
    },
    {
      id: 300,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DECLARACIÓN DE VALIDEZ TZOMPANTEPEC",
    },
    {
      id: 301,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DESIGNACIÓN DE PERSONAL PARA RECUENTO DE VOTOS NANACAMILPA",
    },
    {
      id: 302,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "DESIGNACIÓN DE PERSONAL PARA RECUENTO DE VOTOS DISTRITO 12 TEOLOCHOLCO",
    },
    {
      id: 303,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DECLARACIÓN DE VALIDEZ DISTRITO 12 TEOLOCHOLCO PRI PVEM PNA",
    },
    {
      id: 304,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "VIOLENCIA POLÍTICA",
      titleAnexo1: "DICTAMEN PROTOCOLO VIOLENCIA CONTRA MUJERES",
      titleAnexo2: "ANEXO ÚNICO DE DICTAMEN PROTOCOLO VIOLENCIA CONTRA MUJERES",
    },
    {
      id: 305,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "MODIFICACIÓN SERVICIO PROFESIONAL ELECTORAL NACIONAL",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 306,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ACUERDO PRESUPUESTO 2017",
      titleAnexo1: "ANEXO 1",
    },
    {
      id: 307,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MULTAS PREVISTAS EN LA RESOLUCIÓN INE CG598 2016",
    },
    {
      id: 308,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRACIÓN DE SANTA CRUZ QUILEHTLA",
    },
    {
      id: 309,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "INTEGRACIÓN AYUNTAMIENTO CONTLA",
    },
    {
      id: 311,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "DE EJECUCIÓN DE MULTAS EN LA RESOLUCIÓN INE CG598 2016 Y EL ACUERDO INE CG700 2016 MOVIMIENTO CIUDADANO",
    },
    {
      id: 312,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "FINANCIAMIENTO PÚBLICO",
    },
    {
      id: 313,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CIENCIA Y TECNOLOGÍA",
    },
    {
      id: 314,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MULTAS PARTIDOS PRI Y MORENA",
    },
    {
      id: 315,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "DONDE SE READECUA EL PRESUPUESTO DE EGRESOS PARA EL EJERCICIO FISCAL 2016",
    },
    {
      id: 316,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc:
        "DECLARACIÓN DE LA VALIDEZ E INTEGRACIÓN DE LA LXII LEGISLATURA DEL CONGRESO",
    },
  ],

  2015: {
    iet: [
      // ACUERDOS IET 2015 (del 1 al 33)
      {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "INTEGRACIÓN DE JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ADECUACIÓN PRESUPUESTO DE EGRESOS EJERCICIO FISCAL 2015",
      titleAnexo1: "ANEXO 1 PRESUPUESTO 2015 IET",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PROGRAMA ANUAL IET",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CUMPLIMIENTO SALA ELECTORAL PANAL 2015",
      titleAnexo1: "ANEXO 1 RESOLUCIÓN PANAL 2015",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "REMISIÓN DE INFORMES ANUALES 2014",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PAN",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PRI",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PRD",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PT",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PVEM",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL MC",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PANAL",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PAC",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL PS",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL MORENA",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL HUMANISTA",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN INFORME ANUAL ENCUENTRO SOCIAL",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "QUEJA 001",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "QUEJA 003",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "MODIFICACIÓN SANCIÓN PANAL",
      titleAnexo1: "ANEXO RESOLUCIÓN PANAL 2015",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PAN",
      titleAnexo1: "ANEXO RESOLUCIÓN PAN",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PRD",
      titleAnexo1: "ANEXO 1 RESOLUCIÓN SANCIÓN PRD",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PAC",
      titleAnexo1: "ANEXO 1 RESOLUCIÓN SANCIÓN PAC",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 MORENA",
      titleAnexo1: "ANEXO 1 RESOLUCIÓN SANCIÓN MORENA",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "QUEJA CQYDIET-002-2015",
      titleAnexo1: "ANEXO 1 RESOLUCIÓN QUEJA CQYDIET-002-2015",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PRI",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PT",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PVEM",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 MC",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PNA",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 PS",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN INFORME ANUAL 2014 ENCUENTRO SOCIAL",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "AJUSTE SANCIÓN FISCALIZACIÓN PT",
    }
  ],
  ite: [
    // ACUERDOS ITE 2015 (del "01" al "047")
    {
      id: "01",
      typeDoc: "ACUERDO",
      numDoc: "CG0 01-2015",
      monthDoc: "SEP",
      nameDoc: "INTEGRACIÓN DE COMISIONES ITE",
    },
    {
      id: "03",
      typeDoc: "ACUERDO",
      numDoc: "CG0 03-2015",
      monthDoc: "SEP",
      nameDoc: "INTEGRACIÓN DE JUNTA GENERAL EJECUTIVA",
    },
    {
      id: "04",
      typeDoc: "ACUERDO",
      numDoc: "CG0 04-2015",
      monthDoc: "SEP",
      nameDoc: "LOGO ITE",
    },
    {
      id: "05",
      typeDoc: "ACUERDO",
      numDoc: "CG0 05-2015",
      monthDoc: "SEP",
      nameDoc: "LINEAMIENTOS PERDIDA Y CANCELACIÓN DE REGISTRO",
    },
    {
      id: "06",
      typeDoc: "ACUERDO",
      numDoc: "CG0 06-2015",
      monthDoc: "SEP",
      nameDoc: "PERDIDA DE ACREDITACIÓN PARTIDO DEL TRABAJO",
    },
    {
      id: "07",
      typeDoc: "ACUERDO",
      numDoc: "CG0 07-2015",
      monthDoc: "SEP",
      nameDoc: "PERDIDA DE ACREDITACIÓN PARTIDO HUMANISTA",
    },
    {
      id: "08",
      typeDoc: "ACUERDO",
      numDoc: "CG0 08-2015",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO DE EGRESOS 2016",
    },
    {
      id: "010",
      typeDoc: "ACUERDO",
      numDoc: "CG0 010-2015",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO SALA ELECTORAL PAC",
    },
    {
      id: "011",
      typeDoc: "ACUERDO",
      numDoc: "CG0 011-2015",
      monthDoc: "OCT",
      nameDoc: "METODOLOGÍA MONITOREO",
    },
    {
      id: "012",
      typeDoc: "ACUERDO",
      numDoc: "CG0 012-2015",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS DEBATES",
    },
    {
      id: "013",
      typeDoc: "ACUERDO",
      numDoc: "CG0 013-2015",
      monthDoc: "OCT",
      nameDoc: "CRITERIOS CIERRES DE CAMPAÑA",
    },
    {
      id: "014",
      typeDoc: "ACUERDO",
      numDoc: "CG0 014-2015",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS CONSULTA CIUDADANA",
    },
    {
      id: "015",
      typeDoc: "ACUERDO",
      numDoc: "CG0 015-2015",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS PROTECCIÓN DE DATOS",
    },
    {
      id: "016",
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS REGISTRO DE CANDIDATOS",
      titleAnexo1: "ANEXO FORMATOS DE REGISTRO DE CANDIDATOS",
    },
    {
      id: "017",
      typeDoc: "ACIERDO",
      monthDoc: "OCT",
      nameDoc: "",
      titleAnexo1: "ANEXO CALENDARIO ELECTORAL LEGAL 2015-2016",
    },
    {
      id: "019",
      typeDoc: "ACUERDO",
      numDoc: "CG0 019-2015",
      monthDoc: "NOV",
      nameDoc: "PRESUPUESTO FINAL",
    },
    {
      id: "020",
      typeDoc: "ACUERDO",
      numDoc: "CG0 020-2015",
      monthDoc: "NOV",
      nameDoc: "REGLAMENTO USOS Y COSTUMBRES",
    },
    {
      id: "021",
      typeDoc: "ACUERDO",
      numDoc: "CG0 021-2015",
      monthDoc: "NOV",
      nameDoc: "PAUTAS RADIO Y TELEVISIÓN",
    },
    {
      id: "022",
      typeDoc: "ACUERDO",
      numDoc: "CG0 022-2015",
      monthDoc: "NOV",
      nameDoc: "DEL REGLAMENTO DE CANDIDATOS INDEPENDIENTES",
    },
    {
      id: "023",
      typeDoc: "ACUERDO",
      numDoc: "CG0 023-2015",
      monthDoc: "NOV",
      nameDoc: "DEL ESTATUTO A.C. CANDIDATOS INDEPENDIENTES",
    },
    {
      id: "028",
      typeDoc: "ACUERDO",
      numDoc: "CG0 028-2015",
      monthDoc: "NOV",
      nameDoc: "REGLAMENTO CONSTITUCIÓN DE PARTIDOS",
    },
    {
      id: "030",
      typeDoc: "ACUERDO",
      numDoc: "CG0 030-2015",
      monthDoc: "NOV",
      nameDoc: "SECRETARIO Y DIRECTORES",
    },
    {
      id: "035",
      typeDoc: "ACUERDO",
      numDoc: "CG0 035-2015",
      monthDoc: "DIC",
      nameDoc: "TOPES DE PRECAMPAÑA",
    },
    {
      id: "040",
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "",
      titleAnexo1: "ANEXO CONVOCATORIA",
    },
    {
      id: "041",
      typeDoc: "ACUERDO",
      numDoc: "CG0 041-2015",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO FISCALIZACIÓN PAN",
    },
    {
      id: "042",
      typeDoc: "ACUERDO",
      numDoc: "CG0 042-2015",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO FISCALIZACIÓN PT",
    },
    {
      id: "044",
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO SALA DF CONVOCATORIA CANDIDATOS INDEPENDIENTES",
      titleAnexo1: "ANEXO CONVOCATORIA CANDIDATOS INDEPENDIENTES",
    },
    {
      id: "045",
      typeDoc: "ACUERDO",
      numDoc: "CG0 045-2015",
      monthDoc: "DIC",
      nameDoc: "MODIFICACIÓN NÚMERO DE APOYO CIUDADANO",
    },
    {
      id: "046",
      typeDoc: "ACUERDO",
      numDoc: "CG0 046-2015",
      monthDoc: "DIC",
      nameDoc: "RADIO Y TV",
    },
    {
      id: "047",
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PLÁSTICOS",
      titleAnexo1: "ANEXO PLÁSTICOS",
    }
  ]
},

  2012: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SE ADECUA EL PROYECTO DE PRESUPUESTO",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "INFORMES ANUALES 2011",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PAN",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PRI",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PRD",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PT",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PVEM",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PMC",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PANAL",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PAC",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN DEL PS",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PAN",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PRI",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PRD",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PT",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PVEM",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL MC",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PANAL",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PAC",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "RESOLUCIÓN DEL PS",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ACUERDO PRESUPUESTO 2013",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RESOLUCIÓN DE LA SALA UNITARIA ADMINISTRATIVA ELECTORAL",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CALENDARIO ELECTORAL 2013",
      titleAnexo1: "ANEXO CALENDARIO ELECTORAL 2013",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVOCATORIA A ELECCIONES 2013",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ACUERDO DE ENCUESTAS Y ESTUDIOS DE OPINIÓN 2013",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO DE LA INTEGRACIÓN DE LAS COMISIONES",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO MONITOREO 2012",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO SECCIONAMIENTO 2012",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO AUTORIZA FIRMA DE CONVENIO 2012",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO TOPES DE PRECAMPAÑAS 2013",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO DE RADIO Y TV FINAL",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO DE FISCALIZACIÓN DE MEDIOS",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO DE PAUTADO",
    },
  ],

  2009: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACUERDO CONSEJO GENERAL METODOLOGIA PARTIDO POPULAR",
      titleAnexo1: "ANEXO 1 DICTAMEN COMISION QUE PRESENTA METODOLOGIA",
      titleAnexo2: "ANEXO 2 METODOLOGIA PARTIDO POPULAR",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ACUERDO INFORME LABORES 2008",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "ACUERDO POR EL QUE SE DECLARA NO PROCEDENTE REGISTRO DEL PLT",
      titleAnexo1: "ANEXO 1 DICTAMEN REGISTRO PARTIDO LIBERAL TLAXCALTECA",
      titleAnexo2: "ANEXO 2 VOTO PARTICULAR LIC. MAXIMINO HERNÁNDEZ PULIDO",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ACUERDO CUMPLIMIENTO ART. 114 CIPEET",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "ACUERDO POR EL QUE SE DECLARA NO PROCEDENTE REGISTRO DEL PARTIDO POPULAR",
      titleAnexo1: "ANEXO 1 DICTAMEN DEL REGISTRO DEL PARTIDO POPULAR",
      titleAnexo2: "ANEXO 2 VOTO PARTICULAR LIC. MAXIMINO HERNÁNDEZ PULIDO",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "CUMPLIMENTACION_PCDT TOCA 137-2008",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ACUERDO JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "ACUERDO POR EL QUE SE DECLARA NO PROCEDENTE REGISTRO DEL PUEBLO TLAXCALTECA",
      titleAnexo1: "ANEXO 1 DICTAMEN DEL PUEBLO TLAXCALTECA",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAN",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRI",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRD",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PT",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN VERDE",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN CONVERGENCIA",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN NUEVA ALIANZA",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PSD",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAC",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PS",
    },
    {
      id: 19,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PAN",
    },
    {
      id: 20,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRI",
    },
    {
      id: 21,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRD",
    },
    {
      id: 22,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PT",
    },
    {
      id: 23,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PVEM",
    },
    {
      id: 24,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN NUEVA ALIANZA",
    },
    {
      id: 25,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN SD",
    },
    {
      id: 26,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PAC",
    },
    {
      id: 27,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PS",
    },
    {
      id: 28,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "ACUERDO READECUACIÓN DE COMISIONES",
    },
    {
      id: 29,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "RESOLUCIÓN CUMPLIMIENTO PARTIDO POPULAR",
    },
    {
      id: 30,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "RESOLUCIÓN REGISTRO PARTIDO POPULAR",
    },
    {
      id: 31,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "RESOLUCIÓN REGISTRO PARTIDO LIBERAL TLAXCALTECA",
    },
    {
      id: 32,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "AGO",
      nameDoc: "RESOLUCIÓN REGISTRO PARTIDO DEL PUEBLO TLAXCALTECA",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "ACUERDO REESTRUCTURA COMITÉ DE INFORMACIÓN",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "ACUERDO READECUACIÓN PRESUPUESTO FINANCIAMIENTO PP 2009",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ACUERDO INICIO DE PÉRDIDA DE SOCIALDEMOCRÁTA",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "READECUACIÓN FINANCIAMIENTO",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2010",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CALENDARIO Y FECHA DE INICIO PROCESO ELECTORAL",
      titleAnexo1: "ANEXO 1 CALENDARIO ELECTORAL 2010",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVOCATORIA 2010",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DICTAMEN SOCIALDEMÓCRATA",
      titleAnexo1: "ANEXO 1 DICTAMEN QUE PRESENTA LA CPPPAyF PSD",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "INFORME 2009",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SECCIONAMIENTO",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MONITOREO 2010",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ENCUESTAS Y ESTUDIOS DE OPINION 2010",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "INTEGRACIÓN DE COMISIONES 2009",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AUTORIZA FIRMA DE CONVENIO",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "REGLAMENTO PARA EL CONOCIMIENTO DE LAS FALTAS Y SANCIONES",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "FISCALIZACION MONITOREO",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      numDoc: "CG0 49-2009",
      monthDoc: "DIC",
      nameDoc: "READECUACIÓN COMISIONES",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      numDoc: "CG0 50-2009",
      monthDoc: "DIC",
      nameDoc: "REGLAMENTO INFORMACION",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      numDoc: "CG0 51-2009",
      monthDoc: "DIC",
      nameDoc: "TOPES DE PRECAMPAÑAS",
    },
  ],

  2008: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 02-07",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 07-07",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 16-07",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 17-07",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 18-07",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 19-07",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 20-07",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 21-07",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 22-07",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 24-07",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 25-07",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 27-07",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 28-07",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 29-07",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 30-07",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 31-07",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 32-07",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 34-07",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 40-07",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 41-07",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 42-07",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 43-07",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 47-07",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 49-07",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 50-07",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 51-07",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 52-07",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 53-07",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 54-07",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 56-07",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 57-07",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 58-07",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 59-07",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 60-07",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 64-07",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 69-07",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 70-07",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 71-07",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 73-07",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 74-07",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 75-07",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 78-07",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 80-07",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 81-07",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 83-07",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 84-07",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 85-07",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 87-07",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 88-07",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 90-07",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 93-07",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 94-07",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 96-07",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 97-07",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 99-07",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 101-07",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 103-07",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 104-07",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 105-07",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 106-07",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 109-07",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 01-07",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 08-07",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 26-07",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 48-07",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 72-07",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 77-07",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 79-07",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 108-07",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 102-07",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 91-07",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 95-07",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 44-07",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 45-07",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 92-07",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 68-07",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 98-07",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "INTEGRACIÓN LIX LEGISLATURA",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "ELEGIBILIDAD AYUNTAMIENTO DE CHIAUTEMPAN",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 04-07",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 05-07",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 06-07",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 23-07",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 33-07",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 36-07",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 37-07",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 38-07",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 39-07",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 46-07",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 55-07",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 61-07",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 62-07",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 63-07",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 65-07",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 67-07",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 76-07",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 82-07",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 86-07",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 89-07",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 100-07",
    },
    {
      id: 101,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 107-07",
    },
    {
      id: 102,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 110-07",
    },
    {
      id: 103,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "QUEJA 66-07",
    },
    {
      id: 104,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "NORMATIVIDAD REGLAMENTO 2008 ULTIMO",
    },
    {
      id: 105,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CALENDARIO ELECCIÓN EXTRAORDINARIA",
      titleAnexo1: "ANEXO 1 CALENDARIO ELECCIÓN EXTRAORDINARIA",
    },
    {
      id: 106,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc:
        "LINEAMIENTOS, METODOLOGÍA Y MECANISMOS APLICADOS EN EL PROCESO 2007",
    },
    {
      id: 107,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "PLATAFORMA PS",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "REGISTRO CANDIDATOS PARTIDO SOCIALISTA POCITOS",
    },
    {
      id: 109,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "REGISTRO CANDIDATOS CIUDADANIA POCITOS",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "APROBACIÓN INFORME GENERAL 2007",
    },
    {
      id: 111,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "RENOVACIÓN COMISIÓN DEMARCACIÓN DISTRITAL",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SELECCIÓN MATERIAL Y DOCUMENTACIÓN ELECTORAL",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "REGIMEN DE FINANCIAMIENTO Y FISCALIZACIÓN 2008",
      titleAnexo1: "ANEXO 1 NORMATIVIDAD 04 ABRIL 08",
      titleAnexo2: "ANEXO 2 FORMATOS NORMATIVIDAD 2008",
      titleAnexo3: "ANEXO 3 FORMATO IA 2008",
      titleAnexo4: "ANEXO 4 FORMATO PRECAM",
      titleAnexo5: "ANEXO 5 BITACORA DE GASOLINA",
    },
    {
      id: 114,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ACCESO A LA INFORMACION 29-05-08",
    },
    {
      id: 116,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAN",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRI",
    },
    {
      id: 118,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRD",
    },
    {
      id: 119,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PT",
    },
    {
      id: 120,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PVEM",
    },
    {
      id: 121,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN CONVERGENCIA",
    },
    {
      id: 122,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PNA",
    },
    {
      id: 123,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAS",
    },
    {
      id: 124,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAC",
    },
    {
      id: 125,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PS-1",
    },
    {
      id: 126,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PCDT",
    },
    {
      id: 127,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "ACUERDO CUMPL. RESOLUCIÓN NORMATIVIDAD",
    },
    {
      id: 128,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PAN",
    },
    {
      id: 129,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRI",
    },
    {
      id: 130,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRD",
    },
    {
      id: 131,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PT",
    },
    {
      id: 132,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PVEM",
    },
    {
      id: 133,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PAS",
    },
    {
      id: 134,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PS",
    },
    {
      id: 135,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PCDT",
    },
    {
      id: 136,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "REDISTRITACIÓN 2",
    },
    {
      id: 137,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2009",
    },
    {
      id: 138,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRERROGATIVAS PS",
    },
    {
      id: 139,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "METODOLOGIA LIBERAL TLAXCALTECA",
      titleAnexo1: "ANEXO 1 DICTAMEN METODOLOGÍA LIBERAL TLAXCALTECA",
      titleAnexo2: "ANEXO 2 METODOLOGÍA LIBERAL TLAXCALTECA",
    },
    {
      id: 140,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "ACUERDO JAVIER HERNANDEZ ATLAHAPA",
    },
  ],

  2007: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CUMPLIMIENTO RESOLUCIÓN TOCA 96-2006 PCDT-1",
    },
    {
      id: 2,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "TOCA 55-2006 PCDT",
    },
    {
      id: 3,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "TOCA 56-2006 PCDT",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CALENDARIO ELECTORAL",
      titleAnexo1: "ANEXO 1 CALENDARIO ELECTORAL 2007",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CONVOCATORIA JURÍDICO Y ADMINISTRATIVO",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "DETERMINACIÓN REGIDORES 2007 1",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "FECHA INICIO PROCESO ELECTORAL 2007-1",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "PCDT",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DICTAMEN PS",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "ADOPCIÓN SECCIONAMIENTO ELECTORAL",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DE INFORMÁTICA",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DE COMUNICACION SOCIAL",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CATALOGO USOS Y COSTUMBRES",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "RATIFICACIÓN DE VIGENCIA NORMATIVIDAD",
      titleAnexo1:
        "ANEXO 1 NORMATIVIDAD DE LOS LINEAMIENTOS Y CRITERIOS METODOLÓGICOS RELATIVOS A ENCUESTAS",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MONITOREO",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIRECTOR DE ASUNTOS JURÍDICOS",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DIRECTOR DE PRERROGATIVAS",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CONVOCATORIA ELECCIONES",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMITÉ DE ADQUISICIONES",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "NUEVO LOGOTIPO",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PAN",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PRI",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PRD",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PT",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PVEM",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL CONVERGENCIA",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL PCDT",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DEL NUEVA ALIANZA",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN DE ALTERNATIVA SOCIAL DEMÓCRATA Y CAMPESINA",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "LINEAMIENTOS Y CONVOCATORIA PARA OBSERVADORES",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "IFE-IET",
    },
    {
      id: 32,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 33,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 34,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 36,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 37,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 38,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAY",
      nameDoc: "SANCIÓN ",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "AMPLIACIÓN PLAZO OBSERVADORES 2007",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CONVOCATORIA CONSEJOS DISTRITALES Y MUNICIPALES 2007",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PT",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DIRECTOR DE ORGANIZACIÓN",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "AMPLIACIÓN PLAZO CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "INSACULACIÓN",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CUMPLIMIENTO PT",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "PROTECCIÓN DE ZONAS",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN ALIANZA PROGRESO PARA TLAXCALA",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN ALIANZA SIGLO XXI",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "RESULTADO INSACULACIÓN",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "INTEGRACIÓN DE LOS CONSEJOS DISTRITALES",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "CRITERIOS CANDIDATOS",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMAS PRD",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PROYECTO DE ACUERDO PLATAFORMA ELECTORAL PT",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMA CONVERGENCIA",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMA PCDT",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMA NUEVA ALIANZA",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMA ALTERNATIVA SOCIAL DEMÓCRATA",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PROYECTO DE ACUERDO PLATAFORMA ELECTORAL PS",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "DE COMISIÓN DE MEDIOS DE COMUNICACIÓN",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COMISIONES BOLETAS Y REGISTRO CANDIDATOS",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN APIZACO",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN ALIANZA SIGLO XXI",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COAL.AYUN. PRI, VERDE,PS. MPIO. TENANCINGO Y TLAX",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN PAN-PAC AYUNTAMIENTOS",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "COALICIÓN PAN-PAN PRESIDENTES DE COMUNIDAD",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PLATAFORMA PRD",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PARTIDO DEL TRABAJO",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS ALTERNATIVA SOCIALDEMÓCRATA",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS CONVERGENCIA",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS PAN-PAC",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS PRI-PVEM",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS PARTIDO NUEVA ALIANZA",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "REGISTRO DE CANDIDATOS A DIPUTADOS PARTIDO DEL CENTRO DEMOCRÁTICO DE TLAXCALA",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE CANDIDATOS A DIPUTADOS PARTIDO SOCIALISTA",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "REGISTRO DE CANDIDATOS A DIPUTADOS PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DISTRIBUCIÓN FINANCIAMIENTO OBTENCIÓN DEL VOTO",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "TOPES DE CAMPAÑA 2007",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ASIGNACIÓN TIEMPOS Y ESPACIOS",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CARACTERÍSTICAS BASES LICITACIÓN Y ANEXO",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INTEGRACIÓN DE CONSEJOS MUNICIPALES 2007",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PARTIDO REVOLUCIONARIO INSTITUCIONAL",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PVEM",
    },
    {
      id: 83,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "MODIFICACIÓN COALICIÓN PRI-PVEM-CONV",
    },
    {
      id: 84,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA CONVERGENCIA AY PC",
    },
    {
      id: 85,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA NUEVA ALIANZA AY PC",
    },
    {
      id: 86,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA ALTERNATIVA SOCIAL DEMÓCRATA MUNICIPIOS",
    },
    {
      id: 87,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN CONVERGENCIA DIP PROP DTTO. XV",
    },
    {
      id: 88,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO DIP PROP DISTRITO XVI CONVERGENCIA",
    },
    {
      id: 89,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO IV CONVERGENCIA",
    },
    {
      id: 90,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO DIPUTADO SUPLENTE I - PRD",
    },
    {
      id: 91,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc:
        "SUSTITUCIÓN DE CANDIDATO SUPLENTE FORMULA 4 ALIANZA PROGRESO PARA TLAXCALA",
    },
    {
      id: 92,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN NUEVA ALIANZA PROPIETARIO FORMULA",
    },
    {
      id: 93,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN SUPLENTE NUEVA ALIANZA 1",
    },
    {
      id: 94,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN SUPLENTE DTTO V ALIANZA PROGRESO P TLAX",
    },
    {
      id: 95,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE PRD DISTRITO XIX, PROPIETARIOS",
    },
    {
      id: 96,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "MODIFICACIÓN DEL ACUERDO CG 51-2007",
    },
    {
      id: 97,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "LICITACIÓN ADQUISIÓN MATERIAL ELECTORAL",
      titleAnexo1: "ANEXO 1 DICTAMEN LICITACIÓN ADQUISIÓN MATERIAL ELECTORAL",
    },
    {
      id: 98,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2008",
    },
    {
      id: 99,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUST. DIP. PS DTTO. XIV",
    },
    {
      id: 100,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUST. DIP. PS DTTO. II",
    },
    {
      id: 102,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO DIP PT DTTO",
    },
    {
      id: 103,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO PROPIETARIO DISTRITO XVIII PRD",
    },
    {
      id: 104,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO SUPLENTE DISTRITO XVIII PRD",
    },
    {
      id: 105,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO SUPLENTE FORMULA 13 CONVERGENCIA",
    },
    {
      id: 106,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUST DIP PROP. DTTO XIX PS",
    },
    {
      id: 107,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN ALTERNATIVA VI PROPIETARIO",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN ALTERNATIVA VI SUPLENTE",
    },
    {
      id: 109,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN CONSEJEROS DISTRITALES 30 SEPTIEMBRE ULTIMO",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN CONSEJEROS MUNICIPALES 30 SEPTIEMBRE",
    },
    {
      id: 111,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO RESOLUCIÓN TOCA 182-2007 PRD",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REG. AYTO. Y PTES COM. PAN-PAC2007",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ALIANZA SIGLO XXI INTEGRANTES DE AYUNTAMIENTO",
    },
    {
      id: 114,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "AYUNTAMIENTOS DE TLAXCALA, TENANCINGO. ALIANZA SIGLO XXI",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ALIANZA POR APIZACO",
    },
    {
      id: 116,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRI INTEGRANTES DE AYUNTAMIENTO",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO AYUNTAMIENTOS PRD",
    },
    {
      id: 118,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO AYTOS Y PCS PT (DE ACDO A MODELO)",
    },
    {
      id: 119,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PVEM INTEGRANTES DE AYUNTAMIENTO",
    },
    {
      id: 120,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRANTES AYUNTAMIENTO CONVERGENCIA",
    },
    {
      id: 121,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRANTES AYUNTAMIENTO PCDT",
    },
    {
      id: 122,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRANTES AYUNTAMIENTO NUEVA ALIANZA",
    },
    {
      id: 123,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRANTES AYUNTAMIENTO ALTERNATIVA",
    },
    {
      id: 124,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "INTEGRANTES AYUNTAMIENTO PARTIDO SOCIALISTA",
    },
    {
      id: 125,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO PRESIDENTES DE COMUNIDAD",
    },
    {
      id: 126,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DE PARTIDO DEL TRABAJO DISTRITO XIX PROPIETARIO",
    },
    {
      id: 127,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRESIDENTE COMUNIDAD JOSÉ NAZARIO RAFAEL MONTIEL ESTRELLA",
    },
    {
      id: 128,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRESIDENTE COMUNIDAD JOSE PEDRO TRINIDAD BARRIO SAN ANTONIO",
    },
    {
      id: 129,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NEGACIÓN DE REGISTRO DEL SR. OLIMPO-ATÍPICO",
    },
    {
      id: 130,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "FALTA CREDENCIAL PARA VOTAR VÍCTOR FERNANDO GALLEGOS",
    },
    {
      id: 131,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "FALTA CREDENCIAL PARA VOTAR CASO ATÍPICO",
    },
    {
      id: 132,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DISTRIBUCIÓN DE FINANCIAMIENTO PÚBLICO PARA AYUNTAMIENTOS",
    },
    {
      id: 133,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "ACREDITACIÓN REPRESENTANTES MESAS DIRECTIVAS DE CASILLA GENERALES 2007",
    },
    {
      id: 134,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPL. RESOLUCIÓN TOCA 184-2007 ACUERDO CG 75-2007 PRD",
    },
    {
      id: 135,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: " SE AUTORIZA LA COMPRA DE BOLETAS Y ACTAS 2007",
    },
    {
      id: 136,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "CARACTERÍSTICAS, MODELOS Y MEDIDAS DE SEGURIDAD DE BOLETAS Y ACTAS",
    },
    {
      id: 137,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN REGISTRO SINDICO PANAL",
    },
    {
      id: 138,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN REGIDOR QUINTO Y SEXTO DEL PT, TEPETITLA",
    },
    {
      id: 139,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN PAPALOTLA SEGUNDO REGIDOR ALIANZA PROGRESO PARA TLAXCALA",
    },
    {
      id: 140,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DIP. SUPL. PARTIDO DEL TRABAJO DISTRITO IV",
    },
    {
      id: 141,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN SINDICO PT SANTA CRUZ TLAX",
    },
    {
      id: 142,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN DE PRESIDENTE DE COMUNIDAD COL.EL MIRADOR CALP. TLAX",
    },
    {
      id: 143,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN QUINTO REGIDOR ALIANZA PROGRESO PARA TLAXCALA",
    },
    {
      id: 144,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR PARTIDO SOCIALISTA",
    },
    {
      id: 145,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN CANDIDATO A PC DE TLATEMPA, MUNICIPIO DE APETATITLÁN",
    },
    {
      id: 146,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PC TEPATLAXCO, PARTIDO SOCIALISTA",
    },
    {
      id: 147,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. REGIDORES.AYTO. CALPULALPAN.07",
    },
    {
      id: 148,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN TETLATLAHUACA CASTRO",
    },
    {
      id: 149,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO SUPLENTE FORMULA 4 ALIANZA",
    },
    {
      id: 150,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RENUNCIA DE PRES.DE COM.TEXCALAC TLAX",
    },
    {
      id: 151,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TOCA 185-2007. LIC. EZEQUIEL",
    },
    {
      id: 152,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NUMERO E INTEGRACION DE CASILLAS 2007",
    },
    {
      id: 153,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "RENUNCIA DE PRES.DE COM.TEXCALAC TLAX",
    },
    {
      id: 154,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. SINDICO AYTO. NATIVITAS",
    },
    {
      id: 155,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN REGISTRO SINDICO PARTIDO CONVERGENCIA TEOLOCH LIC OLVERA",
    },
    {
      id: 156,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR ALTZAYANCAHERLINDO",
    },
    {
      id: 157,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. DTO. XVI ALTERNATIVA",
    },
    {
      id: 158,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. SINDICO, PROP Y SUPLET. ATLANGATEPEC",
    },
    {
      id: 159,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN SR TEPETITLA DE LARDIZABAL CASTRO",
    },
    {
      id: 160,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN QUINTO REGIDOR PARTIDO DEL TRABAJO",
    },
    {
      id: 161,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TERCER REGIDOR SN. PABLO MONTE",
    },
    {
      id: 162,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST.DIP. XV.ALTER.SOCIALDEM",
    },
    {
      id: 163,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN TETLATLAHUACA CASTRO",
    },
    {
      id: 164,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PRIMER REGIDOR PROPIETARIO HUAMANTLA PT",
    },
    {
      id: 165,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. PC SEGUNDA SECCION MAZATECOCHCO PT",
    },
    {
      id: 166,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PARTIDO SOCIALISTA SUSTITUCION PRIMERO Y SEGUNDO REGIDOR",
    },
    {
      id: 167,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN CONSEJEROS ELECTORALES MUNICIPALES 19 OCTUBRE",
    },
    {
      id: 168,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PT SINDICO TERRENATE",
    },
    {
      id: 169,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TOCA 202-2007 VÍCTOR FERNANDO GALLEGOS",
    },
    {
      id: 170,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "COMUNIDAD ZARAGOZA SECCIÓN 0335",
    },
    {
      id: 171,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SANTA CRUZ TETELA SECCIÓN 0150-1",
    },
    {
      id: 172,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "DISTINCION LISTA NOMINAL COMUNIDADES 2007-1",
    },
    {
      id: 173,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CIERRE CAMPAÑAS 2007",
    },
    {
      id: 174,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTIT.PRIM. REGIDOR. DEL PAN-PAC, SAN J. ZACUALPAN",
    },
    {
      id: 175,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TERCER REGIDOR PROPIETARIO NANACAMILPA PAN-PAC",
    },
    {
      id: 176,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. PRESIDENTE DE COM. TERRENATE PRD",
    },
    {
      id: 177,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTICIÓN REGISTRO SINDICO PROPIETARIO PRD HUAMANTLA",
    },
    {
      id: 178,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. SEGUNDA SECCION MAZATECOCHCO PT",
    },
    {
      id: 179,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUST. SR SAN PABLO DEL MONTE PT",
    },
    {
      id: 180,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRESIDENTE MUNICIPAL PARTIDO DEL TRABAJO",
    },
    {
      id: 181,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TERCER REGIDOR TERRENATE PT",
    },
    {
      id: 182,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN REGISTRO TERCER REGIDOR PANOTLA PNA",
    },
    {
      id: 183,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN NUEVA ALIANZA SINDICO",
    },
    {
      id: 184,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCION TERCER, CUARTO Y QUINTO SEXTOREGIDOR STACRUZ TLAXCALA ALTERNATIVA",
    },
    {
      id: 185,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DIPUTADO SUPLENTE ALTERNATIVA",
    },
    {
      id: 186,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCION ALTERNATIVA SOCIAL DEMOCRATA DIPUTADO DISTRITO X",
    },
    {
      id: 187,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "QUINTO REGIDOR SAN JUAN HUACTZINCO PS",
    },
    {
      id: 188,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PDTE.COMUNIDADTLAXCO PS",
    },
    {
      id: 189,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN REGIDOR ALIANZA SIGLO XXI",
    },
    {
      id: 190,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN regiDOR ALIANZA siglo xxI xicotzigo",
    },
    {
      id: 191,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTI. PRI-VERDE TEPEYANCO PRIMER REGIDOR",
    },
    {
      id: 192,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTI. PRI-VERDE ACUAMANALA DE MIGUEL HIDALGO SEGUNDO REGIDOR",
    },
    {
      id: 193,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN ACUAMANALA EA",
    },
    {
      id: 194,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN TEPEYANCO EA",
    },
    {
      id: 195,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR Psocialista HUAMANTLA",
    },
    {
      id: 196,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN Tercer Regidor Calpulalapan Alianza Siglo XXI",
    },
    {
      id: 197,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR ALIANZA SIGLO XXI SANCTORUM",
    },
    {
      id: 198,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN Segundo Regidor Yauhquemecan Alianza Siglo XXI",
    },
    {
      id: 199,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DE CANDIDATO SUPLENTE FORMULA 4 alianza SIGLO XXI",
    },
    {
      id: 200,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN CONTLA PARTIDOSOCIALISTA",
    },
    {
      id: 201,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN CONSEJEROS ELECTORALES MUNICIPALES 21 OCTUBRE",
    },
    {
      id: 202,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN REGIDORES.AYTO, TEPEYANCO. P.R.D.07",
    },
    {
      id: 203,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NÚMERO Y UBICACIÓN DE CASILLAS",
    },
    {
      id: 204,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SANTA CRUZ TETELA SECCIÓN 0150",
    },
    {
      id: 205,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN XALTOCAN ALIANZA PROGRESO P TLAX",
    },
    {
      id: 206,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CONVERGENCIA SEXTO SUPLENTE",
    },
    {
      id: 207,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SEGUNDO REGIDOR YAUHQUEMECAN PCDT",
    },
    {
      id: 208,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN SEGUNDO REGIDOR PSOCIALISTA HUAMANTLA",
    },
    {
      id: 209,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN 1ER.REGIDOR PROPIETARIO Y SUPLENTE CALPULA. PART. SOCIALISTA",
    },
    {
      id: 210,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN SINDICO AYUNTAMIENTO APIZACO PS",
    },
    {
      id: 211,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN DE SEGUNDO REGIDOR PROPIETARIO ESPAÑITA ALIANZA SIGLO XXI",
    },
    {
      id: 212,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN TERCER REGIDOR SUPLENTE TZOMPANTEPEC PT",
    },
    {
      id: 213,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN SINDICO MUNICIPAL PROPIETARIO TLAXCO PS",
    },
    {
      id: 214,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR SAN PABLO DEL MONTE PCDT",
    },
    {
      id: 215,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SEGUNDO REGIDOR TLAXCALA PAN-PAC",
    },
    {
      id: 216,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DIPUTADO SUPLENTE XVI. ALTERNATIVA",
    },
    {
      id: 217,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN CONSEJEROS DISTRITALES Y MUNICIPALES 30 OCTUBRE",
    },
    {
      id: 218,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 223-2007 OLIMPO",
    },
    {
      id: 219,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 215-2007",
    },
    {
      id: 220,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 219-2007",
    },
    {
      id: 221,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN FUNCIONARIOS CASILLA 2007",
    },
    {
      id: 222,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CONSEJERO MUNICIPAL DE ZACATELCO",
    },
    {
      id: 223,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO QUEJA TOCA 217.2007",
      titleAnexo1: "DICTAMEN",
    },
    {
      id: 224,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DICTAMEN CUMPLIMIENTO QUEJA TOCA 217.2007",
    },
    {
      id: 225,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 218-2007 PRD",
    },
    {
      id: 226,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 207-2007",
    },
    {
      id: 227,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "COMISION INFORMÁTICA Y RESULTADOS ELECTORALES",
    },
    {
      id: 228,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SARJE 2007",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CONSEJERO ELECTORAL PROPIETARIO DISTRITO XI",
    },
    {
      id: 230,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TOCA 216-2007 ESPAÑITA PRD",
    },
    {
      id: 231,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN SEGUNDO Y TERCER REGIDOR XALTOCAN PRD",
    },
    {
      id: 232,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN SÍNDICO PROPIETARIO CUAPIAXTLA PRD",
    },
    {
      id: 233,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN TERCER REGIDOR PROP Y SUPSAN PABLO MONTE PRD",
    },
    {
      id: 234,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR PROPIETARIO XALOZTOC PT",
    },
    {
      id: 235,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PRIMER Y TERCER REGIDOR CUAPIAXTLA PT",
    },
    {
      id: 236,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR SUPLENTE TERRENATE PAS",
    },
    {
      id: 237,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SUSTITUCIÓN SEGUNDO REGIDOR PROPIETARIO Y SUPLENTE Y TERCER REGIDOR SUPLENTE TLAXCO PAS",
    },
    {
      id: 238,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN DIPUTADO SUPLENTE DISTRITO III PAS",
    },
    {
      id: 239,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CUARTO REGIDOR XICOHTZINCO PS",
    },
    {
      id: 240,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PRIMER REGIDOR YAUQUEMEHCAN PS",
    },
    {
      id: 241,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN SEGUNDO REGIDOR PROPIETARIO TLAXCO PS",
    },
    {
      id: 242,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SUSTITUCIÓN SÍNDICO, SEGUNDO REGIDOR, SEXTO REGIDOR, ZACATELCO PS",
    },
    {
      id: 243,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN DIPUTADO SUPLENTE DISTRITO XIII PS",
    },
    {
      id: 244,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "INTEGRACIÓN DE LA COMISION MEMORIA PROCESO ORDINARIA2007",
    },
    {
      id: 245,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "MODIFICACIÓN CONVENIO ALIANZA PROGRESO PARA TLAXCALA CASTRO MODIFICADO",
    },
    {
      id: 246,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CONSEJERO PROP. MPAL TZOMPANTEPEC",
    },
    {
      id: 247,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES DE FUNCIONARIOS DE MDC",
      titleAnexo1: "ANEXO 1",
      titleAnexo2: "ANEXO 2",
    },
    {
      id: 248,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CAMBIO UBICACIÓN DE CASILLA BÁSICA SECCIÓN 0021 APIZACO",
    },
    {
      id: 249,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "TOCA 213-2007 SANCTUORUM PRI",
    },
    {
      id: 250,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CHIAUTEMPAN PCDT",
    },
    {
      id: 251,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN ALIANZA PROGRESO TLAXCALA",
    },
    {
      id: 252,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PARTIDO DEL TRABAJO ",
    },
    {
      id: 253,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN 2DO.REG. PT. TLAXCALA",
    },
    {
      id: 254,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN SINDICO SEGUNDO Y TERCER REGIDOR APIZACO PT CASTRO",
    },
    {
      id: 255,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES PARTIDO DEL PT",
    },
    {
      id: 256,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PARTIDO VERDE NANACAMILPA",
    },
    {
      id: 257,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN REGIDORES CALPULALPAN PCDT",
    },
    {
      id: 258,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN REGIDORES ESPAÑITA NUEVA ALIANZA",
    },
    {
      id: 259,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN ALTERNATIVA APETATITLAN PAS",
    },
    {
      id: 260,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN ALTERNATIVA SAN PABLO DEL MONTE DAVID",
    },
    {
      id: 261,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN REGIDORES TETLATLAHUCA PS",
    },
    {
      id: 262,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN DIPUTADO FORMULA E PS",
    },
    {
      id: 263,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "OBSERVADORES ELECTORALES",
    },
    {
      id: 264,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN DIP. SUPL. TEPEYANCO PT",
    },
    {
      id: 265,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN AYUNTAMIENTO ZITLATEPEC PAN-PAC",
    },
    {
      id: 266,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN CONSEJERO PROPIETARIO DISTRITO II",
    },
    {
      id: 267,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc:
        "SUSTITUCIÓN SEGUNDO REGIDOR PROPIETARIO CALPULALPAN ALIANZA SIGLO XXI",
    },
    {
      id: 268,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PLURIS 2007",
    },
    {
      id: 269,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ASIGNACIÓN REGIDURIAS AYUNTAMIENTOS",
    },
    {
      id: 270,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "MODIFICACIÓN ACUERDO 269 ASIGNACIÓN REGIDURIAS 2007",
    },
    {
      id: 271,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO PLURINOMINALES",
    },
    {
      id: 272,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO DISTRITO V",
    },
    {
      id: 273,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PÉRDIDA DE REGISTRO PCDT",
      titleAnexo1: "DICTAMEN PÉRDIDA DE REGISTRO PCDT",
    },
  ],

  2006: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "INTEGRACIÓN JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "INFORME ANUAL 2005",
    },
    {
      id: 3,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "01-06",
    },
    {
      id: 4,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "MAR",
      nameDoc: "04-06",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "COBAT, IFE, IET",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PAN",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PRI",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PRD",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PT",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PVEM",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc:
        "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 CONVERGENCIA",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PCDT",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PNA",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "INFORME ANUAL RELATIVO A LOS INGRESOS Y EGRESOS DEL 2005 PASDC",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ESTATUTOS",
      titleAnexo1: "DICTAMEN ESTATUTOS PCDT",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "COMISION EJECUTIVA",
      titleAnexo1: "DICTAMEN COMISIÓN EJECUTIVA",
    },
    {
      id: 17,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN PAN",
    },
    {
      id: 18,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN PRI",
    },
    {
      id: 19,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN PRD",
    },
    {
      id: 20,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN PT",
    },
    {
      id: 21,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN CONVERGENCIA",
    },
    {
      id: 22,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN PCDT",
    },
    {
      id: 23,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "SANCIÓN NA",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "REESTRUCTURA COMISIÓN DEMARCACIÓN DISTRITAL",
    },
    {
      id: 25,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "QUEJA 05-2006",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2006",
    },
    {
      id: 27,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO SANCIÓN PCDT",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NORMATIVIDAD CONSULTA CIUDADANA 2006",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "LINEAMIENTOS NUEVOS PARTIDOS",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "INFORME 2006",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CUMPLIMIENTO TOCA 962006 CG 152006 PCDT",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SOBRE CREACION DE COMISIONES",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "COMISIÓN JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PAC",
    },
    {
      id: 35,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "PS",
      titleAnexo1: "DICTAMEN PS",
    },
  ],

  2005: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "INTEGRACION LVIII LEGISLATURA",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SELECCIÓN Y SEPARACIÓN DE MATERIAL ELECTORAL",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PÉRDIDA DE REGISTRO PJS",
    },
    {
      id: 4,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "EXPEDIENTE 114-2004",
    },
    {
      id: 5,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "EXPEDIENTE 129-2004",
    },
    {
      id: 6,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "ENE",
      nameDoc: "EXPEDIENTE 140-2004",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "INTEGRACIÓN JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "INFORME ANUAL",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "AMPLIACIÓN PRESUPUESTO",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DESTRUCCIÓN O RECICLAJE DE LA DOCUMENTACIÓN ELECTORAL",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "COMISION GOBIERNO INTERNO",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "COMISIÓN EDITORIAL",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PAN",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRI",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PRD",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PT",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PVEM",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN CONVERGENCIA",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PCDT",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DICTAMEN PJS",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "REDISTRITACIÓN",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "CONVENIO IET-UAM-UAT",
    },
    {
      id: 23,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PAN",
    },
    {
      id: 24,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRI",
    },
    {
      id: 25,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PT",
    },
    {
      id: 26,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN VERDE ECOLOGISTA",
    },
    {
      id: 27,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN CONVERGENCIA",
    },
    {
      id: 28,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN CENTRO DEMOCRATICO",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PJS",
    },
    {
      id: 30,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "CONVENIO INSTITUTOS",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SANCIÓN PRD",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "APLICACION DE MINISTRACIONES",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "INFORMACIÓN",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "IET-AYUNTAMIENTOS",
      titleAnexo1: "CONVENIO IET-AYUNTAMIENTOS",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "MODIFICATORIO",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PRESUPUESTO 2006",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DESINCORPORACIÓN VEHÍCULOS",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ACREDITACIÓN NUEVA ALIANZA",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ACREDITACIÓN ALTERNATIVA SOCIALDEMÓCRATA Y CAMPESINA",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "FINANCIAMIENTO NUEVOS PARTIDOS",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PRD",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "APLICACIÓN MINISTRACIONES PRD",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "REGISTRO PAC",
    },
  ],

  2004: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "SOBRE CREACION DE COMISIONES",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "INTEGRAR LA JUNTA GENERAL EJECUTIVA",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "RETRIBUCION CONSEJEROS",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "PROCEDIMIENTOS PJS",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ENE",
      nameDoc: "CONVOCATORIA DIRECCIONES",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "SUSPENSIÓN DEMARCACIÓN DISTRITAL",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "FECHA DE INICIO DEL PROCESO ELECTORAL",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "REGIDORES 04",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "NOMBRAMIENTO DIRECTOR DE ORGANIZACION",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "NOMBRAMIENTO DIRECTOR DEL SERVICIO PROFESIONAL",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "NOMBRAMIENTO DIRECTOS ASUNTOS JURIDICOS",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CONVENIO UVT",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DE SECCIONAMIENTO",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "RATIFICACIÓN DE VIGENCIA NORMATIVIDAD",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "COMITE ADQUISICIONES",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "REGLAMENTO INTERIOR DEL INSTITUTO ELECTORAL DE TLAXCALA. EN LO GENERAL",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "REGLAMENTO DE SESIONES DEL CONSEJO GENERAL. EN LO GENERAL",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "REGLAMENTO SESIONES CONSEJOS DISTRITALES Y MUNICIPALES. EN LO GENERAL",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "REGLAMENTO SESIONES JUNTA GENERAL EJECUTIVA. EN LO GENERAL",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "DEL ESTATUTO DEL SERVICIO PROFESIONAL ELECTORAL. EN LO GENERAL",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "REGLAMENTO DE ASISTENCIA TECNICA, ELECCIÓNES POR USOS Y COSTUMBRES. EN LO GENERAL",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "REGLAMENTO PARA EL CONOCIMIENTO DE LAS FALTAS Y APLICACIÓN DE SANCIONES ADMINISTRATIVAS. EN LO GENERAL",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "REGLAMENTO DEL PROCEDIMIENTO PARA LA PÉRDIDA DE REGISTRO O CANCELACIÓN DE ACREDITACIÓN. EN LO GENERAL",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc: "REGLAMENTO DE PRECAMPAÑAS. EN LO GENERAL",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "NORMATIVIDAD RELATIVA A LA FISCALIZACIÓN DEL ORIGEN.... EN LO GENERAL",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "MAR",
      nameDoc:
        "LINEAMIENTOS Y CRITERIOS A ENCUENTAS, SONDEOS Y ESTUDIOS DE OPINIÓN PUBLICA. EN LO GENERAL",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ESTRUCTURA OPERATIVA ORGANIZACIÓN",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CONVOCATORIA COORDINADORES",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "QUE AUTORIZA AL PRESIDENTE CONVENIO IFE",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "INICIATIVA",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CONVOCATORIA ELECCIONES",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "CALENDARIO",
      titleAnexo1: "CALENDARIO ELECTORAL 2004",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "ACUERDO-CRITERIO- REGIDOR ACUAMANALA-PDTE",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "DE OBSERVACIÓN ELECTORAL",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "MONITOREO",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ACCIÓN NACIONAL",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PARTIDO REVOLUCIONARIO INSTITUCIONAL",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PARTIDO DE LA REVOLUCIÓN DEMOCRÁTICA",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PARTIDO DEL TRABAJO",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "VERDE ECOLOGISTA",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CONVERGENCIA",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CENTRO DEMOCRATICO",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "JUSTICIA SOCIAL",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "SOCIEDAD NACIONALISTA",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "ALIANZA SOCIAL",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "LIBERAL MEXICANO",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "MÉXICO POSIBLE",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "FUERZA CIUDADANA",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "OBSERVADORES",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PROGRAMA DE TRABAJO DIR. ORG",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "EQUIDAD DE GÉNERO",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "PARTIDO JUSTICIA SOCIAL SUP-JDC-809-2002",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "CONVOCATORIA CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "REESTRUCTURACION DE PRESUPUESTO 2004",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "INSACULACIÓN MES BASE",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "PROTECCIÓN DE LA ZONAS Y MONUMENTOS HISTÓRICOS",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "ADICIÓN OBSERVACION ELECTORAL",
    },
    {
      id: 58,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PAN",
    },
    {
      id: 59,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PRI",
    },
    {
      id: 60,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PRD",
    },
    {
      id: 61,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PT",
    },
    {
      id: 62,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "CONVERGENCIA",
    },
    {
      id: 63,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PJS",
    },
    {
      id: 64,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PSN",
    },
    {
      id: 65,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "PLM",
    },
    {
      id: 66,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "MEX POSIBLE",
    },
    {
      id: 67,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUN",
      nameDoc: "FUERZ CIUD",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "INTEGRACIÓN CONSEJOS DISTRITALES",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SE FACULTA AL PRESIDENTE",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "ACREDITACIÓN PRI",
    },
    {
      id: 71,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "JUL",
      nameDoc: "DE LA QUEJA 001-2004",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "EXCLUSIÓN POBLACIONES DE LA CONVOCATORIA",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "INCLUSIÓN POBLACIONES",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CATÁLOGO DE COMUNIDADES USOS Y COSTUMBRES",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "INTEGRACIÓN CONSEJOS MUNICIPALES",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "ACREDITACIÓN PAN",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "ACREDITACIÓN PT",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "LICITACIÓN MATERIAL ELECTORAL",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "SUSTITUCIÓN CONSEJEROS DISTRITALES",
    },
    {
      id: 108,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO GOBERNADOR MARIANO",
    },
    {
      id: 109,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO GOBERNADOR HECTOR",
    },
    {
      id: 110,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO GOBERNADOR GELACIO",
    },
    {
      id: 111,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PAN",
    },
    {
      id: 112,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PRI-PVEM",
    },
    {
      id: 113,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PRD",
    },
    {
      id: 114,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PT",
    },
    {
      id: 115,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS CONVERG",
    },
    {
      id: 116,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PCDT",
    },
    {
      id: 117,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DIPUTADOS PJS",
    },
    {
      id: 118,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ASIGNACIÓN PRERROGATIVAS PRESI",
    },
    {
      id: 119,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "LINEAMIENTOS FISC MEDIOS",
    },
    {
      id: 120,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "TIEMPOS Y ESPACIOS FORMA IGUALITARIA",
    },
    {
      id: 121,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DE SANTIAGO TLACOCHCALCO",
    },
    {
      id: 122,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "FECHA LÍMITE PRES. COM",
    },
    {
      id: 123,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "COMISION CONSULTA INFANTIL 2004",
    },
    {
      id: 124,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCION D. XIX, CUAXOMULCO Y TLAXCALA",
    },
    {
      id: 125,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PRES D COM PAN",
    },
    {
      id: 126,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PRI MUNIC",
    },
    {
      id: 127,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PRES D COM PT",
    },
    {
      id: 128,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA AYUNT Y P.C.PVEM",
    },
    {
      id: 129,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PLATAFORMA PJS MUNICIP",
    },
    {
      id: 130,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DIPUTADOS PCDT 2004",
    },
    {
      id: 131,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CRITERIOS LEY MUNICIPAL",
    },
    {
      id: 134,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "COM BOLET Y REG CAND",
    },
    {
      id: 135,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIONES CONSEJOS DISTRITALES Y MUNICIPALES",
    },
    {
      id: 136,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DICTAMEN OBSERVADORES 2a LISTA OK",
    },
    {
      id: 137,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SECCIÓN SÉPTIMA DE CONTLA",
    },
    {
      id: 138,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DEFINICIÓN SITUACIÓN JURÍDICA P",
    },
    {
      id: 139,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIONES DIPUTADOS CONVERGENCIA",
    },
    {
      id: 140,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "SEP",
      nameDoc: "QUEJA EXP. 10-2004",
    },
    {
      id: 141,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO GOBERNADOR MARÍA DEL CARMEN",
    },
    {
      id: 142,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DIPUT PT",
    },
    {
      id: 143,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTIT DIP PJS",
    },
    {
      id: 144,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "TERCERA ETAPA OBSERVADORES",
    },
    {
      id: 145,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUTITUCIÓN ATLAGATEPEC 20-09-04",
    },
    {
      id: 146,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCION DIPUT PAN",
    },
    {
      id: 147,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ESCRITO DE PROTESTA",
    },
    {
      id: 148,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INCLUIR A TERRENATE CHIPILO VOTO CONST",
    },
    {
      id: 149,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INCLUIR SANTA MARTHA SECCIÓN TERCERA DE XALOZTOC VOTO CONST",
    },
    {
      id: 155,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "REGISTRO AYUNTAMIENTOS",
      titleAnexo1: "ANEXO 1 REGISTROS DE AYUNTAMIENTOS",
    },
    {
      id: 156,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO PRESIDENTES DE COMUNIDAD",
      titleAnexo1: "ANEXO REGISTROS DE PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 157,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NO REGISTRO AYUNTAMIENTOS",
    },
    {
      id: 158,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NO REGISTRO PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 159,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ASIGNACIÓN PRERROGATIVAS AYUNTAMIENTOS",
    },
    {
      id: 160,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DIPUTADOS PCDT y PC",
    },
    {
      id: 161,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN TOTAL DE AYUNTAMIENTOS",
    },
    {
      id: 162,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "NO APRUEBA SUSTITUCIÓN AYUNTAMIENTOS",
    },
    {
      id: 163,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "SUSTITUCIÓN CONSEJOS DISTRITALES VII,XVI y XIX y CONSEJOS MUNICIPALES",
    },
    {
      id: 164,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TRANSFERENCIA PARA PROGRAMAS DEL IET",
    },
    {
      id: 165,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO VICTOR CESAR",
    },
    {
      id: 166,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "QUEJA EXP. 011-2004",
    },
    {
      id: 167,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO EJECUTORIA",
    },
    {
      id: 168,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO CESAR TETLA",
    },
    {
      id: 169,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO DE BOLETAS ELECTORALES",
    },
    {
      id: 170,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TZOMPANTEPEC",
    },
    {
      id: 171,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "REGISTRO SECCION SEXTA BARRIO GRANDE ok",
    },
    {
      id: 172,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTIT. DIP SUPLEN ok",
    },
    {
      id: 173,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCION AYUNTS COMPLETO",
    },
    {
      id: 174,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "004-2004",
    },
    {
      id: 175,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "008-2004",
    },
    {
      id: 176,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "EGRESOS 2005",
    },
    {
      id: 177,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "EXCLUSIÓN SANTA MARTHA",
    },
    {
      id: 178,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO CALPULALPAN",
    },
    {
      id: 179,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TLAXCO",
    },
    {
      id: 180,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TZOMPANTEPEC XALTOCANok",
    },
    {
      id: 181,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO PAPALOTLA NATIVITAS",
    },
    {
      id: 182,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "APETATITLAN VICTOR HUGO",
    },
    {
      id: 183,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TZOMPANTEPEC CUMPLIMIENTO",
    },
    {
      id: 184,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO TENANCINGO",
    },
    {
      id: 185,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMIENTO JOSE ANTONIO AGUILAR DURAN",
    },
    {
      id: 186,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CUMPLIMENTO REGIDOR",
    },
    {
      id: 187,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc:
        "AMPLIACIÓN DEL CG 105/2004 ACREDITACIÓN REPRESENTANTES GENERALES",
    },
    {
      id: 188,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "INTEGRACION,NUMERO Y UBICACION DECASILLAS",
    },
    {
      id: 189,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "LISTA NOMINAL-PRESIDENCIAS DE COMUNIDAD",
    },
    {
      id: 190,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "CIERRE DE CAMPAÑA",
    },
    {
      id: 191,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES DE AYUNTAMIENTOS",
    },
    {
      id: 192,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES CONSEJOS DIST Y MUNIC",
    },
    {
      id: 193,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MEDIDAS DE SEGURIDAD DE LAS BOLETAS ELECTORALES",
    },
    {
      id: 194,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "SUP-JDC-554-2004",
    },
    {
      id: 195,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SARJE",
    },
    {
      id: 196,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DIP SUP Y 1 REG PROP PRD PRESENTADO EN SESIÓN",
    },
    {
      id: 197,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "LISTA 3 OBSERVADORES",
    },
    {
      id: 198,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "TOPES DE CAMPAÑA COMUNIDADES",
    },
    {
      id: 199,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "REC.REV. 01",
    },
    {
      id: 200,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "REC.REV. 02",
    },
    {
      id: 201,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "QUEJA 025-04",
    },
    {
      id: 202,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "OCT",
      nameDoc: "QUEJA 036-04",
    },
    {
      id: 203,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MODIFICACIÓN DE ACTAS",
    },
    {
      id: 204,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO PJS II T173-04",
    },
    {
      id: 205,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCION CONSEJOS DTALES. Y MPALES",
    },
    {
      id: 206,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CONVENIO IET-IEDF",
    },
    {
      id: 207,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 031-04",
    },
    {
      id: 208,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 099-04",
    },
    {
      id: 209,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 101-2004",
    },
    {
      id: 210,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES PVEM SESION 03-11-04",
    },
    {
      id: 211,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO APETATI VICTOR",
    },
    {
      id: 212,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN INTEGRANTES DE MESAS DIR. DE CAS",
    },
    {
      id: 213,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO ISABEL",
    },
    {
      id: 214,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO REGIDOR PCDT",
    },
    {
      id: 215,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES PCDT Y PRD",
    },
    {
      id: 216,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES DE FUNCIONARIOS MESAS DIRECTIVAS DE CASILLA",
    },
    {
      id: 217,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIONES CONSEJOS MUNICIPALES",
    },
    {
      id: 218,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REC.REV.03-2004",
    },
    {
      id: 219,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REC.REV.04-2004",
    },
    {
      id: 220,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 26-04",
    },
    {
      id: 221,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 65-04",
    },
    {
      id: 222,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "EXPEDIENTE 096-04",
    },
    {
      id: 223,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "QUEJA 102-04",
    },
    {
      id: 224,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO TLAXCO PAN",
    },
    {
      id: 225,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO SANTA MARTHA XALOSTOC",
    },
    {
      id: 226,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "NOV",
      nameDoc: "REC.REV.05-2004",
    },
    {
      id: 227,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO SUP-JRC-623-2004 MARCO EDGARDO SÁNCHEZ ORTEGA",
    },
    {
      id: 228,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CUMPLIMIENTO SARJE",
    },
    {
      id: 229,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUST REGIDORES 13-11-04",
    },
    {
      id: 230,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUST DIPUTADOS 13-11-04",
    },
    {
      id: 231,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "IMPRESION BOLETAS EXTRAS",
    },
    {
      id: 232,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "REVOCACIÓN MARCO EDGARDO",
    },
    {
      id: 233,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "GOBERNADOR",
    },
    {
      id: 234,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ASIGNACIÓN DIPUTADOS RP",
    },
    {
      id: 235,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "ESCRUT Y COMPUT TETLANOHCAN,LA MAGDALENA,ESPAÑITA",
    },
    {
      id: 236,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "CÓMPUTO TETLANOHCAN, TLALTELULCO Y ESPAÑITA",
    },
    {
      id: 237,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "INTEGRACIÓN AYUNTAMIENTOS 2004 XALOZTOC",
    },
    {
      id: 238,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 005-2004",
    },
    {
      id: 239,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 006-2004",
    },
    {
      id: 240,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 015-2004",
    },
    {
      id: 241,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 022-2004",
    },
    {
      id: 242,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 027-2004",
    },
    {
      id: 243,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 029-2004",
    },
    {
      id: 244,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 030-2004",
    },
    {
      id: 245,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 034-2004",
    },
    {
      id: 246,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 057-2004",
    },
    {
      id: 247,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 075-2004",
    },
    {
      id: 249,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 077-2004",
    },
    {
      id: 250,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 092-2004",
    },
    {
      id: 251,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 094-2004",
    },
    {
      id: 252,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 098-2004",
    },
    {
      id: 253,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 104-2004",
    },
    {
      id: 254,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 105-2004",
    },
    {
      id: 255,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 108-2004",
    },
    {
      id: 256,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 113-2004",
    },
    {
      id: 257,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 116-2004",
    },
    {
      id: 258,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 118-2004",
    },
    {
      id: 259,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 119-2004",
    },
    {
      id: 260,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 122-2004",
    },
    {
      id: 261,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE-124-2004",
    },
    {
      id: 262,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 127-2004",
    },
    {
      id: 263,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 132-2004",
    },
    {
      id: 264,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 133-2004",
    },
    {
      id: 265,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 135-2004",
    },
    {
      id: 266,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 144-2004",
    },
    {
      id: 267,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 145-2004",
    },
    {
      id: 268,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 146-2004",
    },
    {
      id: 269,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 150-2004",
    },
    {
      id: 270,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 152-2004",
    },
    {
      id: 271,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "EXPEDIENTE 153-2004",
    },
    {
      id: 272,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "RECURSO DE RESOLUCIÓN 06-2004",
    },
    {
      id: 273,
      typeDoc: "RESOLUCIÓN",
      monthDoc: "DIC",
      nameDoc: "REC.REV.07-2004 Y ACUMULADO",
    },
    {
      id: 274,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "READECUACIÓN PRESUPUESTO 2005",
    },
  ],

  2003: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "FEB",
      nameDoc: "CALENDARIO DE SESIONES ORDINARIAS 2003",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO CONVERGENCIA",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO FC",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO MP",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PAN",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PAS",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PCDT",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PJS",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PLM",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PRD",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PRI",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PSN",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PT",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "FINANCIAMIENTO PVEM",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PT",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN CONVERGENCIA",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PCDT",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PJS",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PRD",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PRI",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "MAY",
      nameDoc: "DICTAMEN PVEM",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "TRANSFERENCIA",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DECLARACIÓN CONSTITUCIONAL PCDT",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc:
        "METODOLOGÍA Y PROCEDIMIENTO, NUEVOS PARTIDOS POLÍEDTICOS ESTATALES",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PRESUPUESTO DE EGRESOS 2003",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "COMISIÓN DEMARCACIÓN",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "PJS",
    },
  ],

  1998: [
    {
      id: 1,
      typeDoc: "ACUERDO",
      monthDoc: "ABR",
      nameDoc: "SE CREAN LINEAMIENTOS PARA ACTOS DE PROSELITISMO",
    },
    {
      id: 2,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "POR EL CUAL SE CREA LA NORMATIVIDAD",
    },
    {
      id: 3,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "LINEAMIENTOS PARA REALIZAR ENCUESTAS Y SONDEOS DE OPINIÓN.",
    },
    {
      id: 4,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "LINEAMIENTOS PARA REALIZAR ENCUESTAS Y SONDEOS DE OPINIÓN.",
    },
    {
      id: 5,
      typeDoc: "ACUERDO",
      monthDoc: "JUN",
      nameDoc: "DEL ANEXO DE LA CONV.",
    },
    {
      id: 6,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DE REGISTRO DE CANDIDATURA A GOBERNADOR",
    },
    {
      id: 7,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "TOPES DE CAMPAÑA",
    },
    {
      id: 8,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DE REGISTRO DE DIPUTADOS",
    },
    {
      id: 9,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "DESIGNACION POR INSACULACIÓN PDTE. Y SECRE. CONCEJOS DIST",
    },
    {
      id: 10,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "POR EL QUE SE DESIGNAN A LOS CONSEJALES DISTRITALES",
    },
    {
      id: 11,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc: "CRITERIOS Y LINEAMIENTOS PARA LA CONTRATACIÓN DE AUX. MUN.DOC",
    },
    {
      id: 12,
      typeDoc: "ACUERDO",
      monthDoc: "JUL",
      nameDoc:
        "CRITERIOS Y LINEAMIENTOS PARA LA CONV. DE PRESIDENTES Y SECRETARIOS.",
    },
    {
      id: 13,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "POR EL QUE SE DETERMINA EL MES BASE",
    },
    {
      id: 14,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PARA LA CREACIÓN DE LA COM. A CARGO DE L",
    },
    {
      id: 15,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "POR EL CUAL SE INSTRUMENTA EL PROG. DE R",
    },
    {
      id: 16,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE SUSTITUYE DIP. P.M.R DTO. VI",
    },
    {
      id: 17,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SE SUTITUYE DIP. P.M.R. DTO. XVII",
    },
    {
      id: 18,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PROYECTO DE PUBLICACIÓN DE POBLACIONES",
    },
    {
      id: 19,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PUBLICACIÓN DE LA LISTA DE POBLACIONES QUE ELEGIR",
    },
    {
      id: 20,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "POR EL QUE SE FACULTA AL SECRETARIO EJE",
    },
    {
      id: 21,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "PUBLICACIÓN DE LISTA DE PMA",
    },
    {
      id: 22,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "SUSTITUCIÓN DE CANDIDATURAS A DIPUTADO",
    },
    {
      id: 23,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "ADO. POR EL QUE SE APRUEBA LA INCLUSIÓN DE LA FOTO",
    },
    {
      id: 24,
      typeDoc: "ACUERDO",
      monthDoc: "AGO",
      nameDoc: "ADO. SE FACULTA A LOS CONSEJOS MUNICIPALES PARA RE",
    },
    {
      id: 25,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "ELECCIONES INFANTILES",
    },
    {
      id: 26,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE DESIGNAN PDTES. Y SRIOS. DE LOS C. MUN",
    },
    {
      id: 27,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PARA LA SUSTITUCIÓN DE CANDIDATO DIPUTADO PT",
    },
    {
      id: 28,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "INCLUCIÓN DE POBLACIONES QUE ELEGIRÁN A SU PMA. X VOTO DIRECTO",
    },
    {
      id: 29,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE NOMBRAN CONSEJALES MUNICIPALES",
    },
    {
      id: 30,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE SE INCLUYE LA POB. DE STA. CRUZ AQUIAHUAC",
    },
    {
      id: 31,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE INCLUYE LA POB. DE STA. CRUZ QUILETHTLA Y GUADALUPE TLACHCO",
    },
    {
      id: 32,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN CANDIDATO DIPUTADO PRI",
    },
    {
      id: 33,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE SORTEAN LOS LUGARES PARA PROPAGANDA",
    },
    {
      id: 34,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE DESIGNA PDTE. Y SRIO. EN QUILEHTLA",
    },
    {
      id: 35,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "FORMULAS DE P.M",
    },
    {
      id: 36,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "REGISTRO DE AYUNT",
    },
    {
      id: 37,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN DIP. PAN",
    },
    {
      id: 38,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SE FACULTA AL LIC. GONZALO FLORES",
    },
    {
      id: 39,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "LINEAMIENTOS PARA PROPAGANDA A P.M.A POR C",
    },
    {
      id: 40,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "DISTRIBUCIÓN DE MATERIAL ELECTORAL",
    },
    {
      id: 41,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CONV. DE OBSERVADORES ELECTORALES",
    },
    {
      id: 42,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "APROBACIÓN DE ACTAS DE LA JORN. ELEC",
    },
    {
      id: 43,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "CONTRATACIÓN CON EMPRESA LITHO FORMAS",
    },
    {
      id: 44,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "SUSTITUCIÓN CANDIDATOS DE PLANILLAS DEL PT",
    },
    {
      id: 45,
      typeDoc: "ACUERDO",
      monthDoc: "SEP",
      nameDoc: "PARA LA SUBSTITUCIÓN DIP. VERDE",
    },
    {
      id: 46,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE ESTABLECEN CRITERIOS PARA M. D.CASILLA",
    },
    {
      id: 47,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PAN",
    },
    {
      id: 48,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN POR CIUDADANÁ DE LA LOC. DE TLATEMPA",
    },
    {
      id: 49,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN PT",
    },
    {
      id: 50,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES PT",
    },
    {
      id: 51,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN DEL PDM",
    },
    {
      id: 52,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE APRUEBA EL NUM. Y UBIC. DE MDC",
    },
    {
      id: 53,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIÓN POR CIUDADANÍA",
    },
    {
      id: 54,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES PAN",
    },
    {
      id: 55,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES PRD",
    },
    {
      id: 56,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES PRI",
    },
    {
      id: 57,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SUSTITUCIONES PVEM",
    },
    {
      id: 58,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ANEXO DEL DE UBICACIÓN DE CASILLAS",
    },
    {
      id: 59,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "APLICACIÓN DEL LÍQUIDO INDELEBLE",
    },
    {
      id: 60,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ENCUESTAS DE SALIDA",
    },
    {
      id: 61,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MODIFICACIONES A INTEGRACIÓN CASILLAS",
    },
    {
      id: 62,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "METODOLOGÍA QUE SEGUIRAN LAS MDC PARA LA ENTR. BOLETAS",
    },
    {
      id: 63,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "SE ORDENA LA PUBLICACIÓN DE INT. DE MDC",
    },
    {
      id: 64,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "ACREDITACIÓN DE OBSERVADORES ELECTORALES",
    },
    {
      id: 65,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "PARA EL SELLADO DE LAS BOLETAS",
    },
    {
      id: 66,
      typeDoc: "ACUERDO",
      monthDoc: "OCT",
      nameDoc: "MANEJO DEL PAQUETE ELECTORAL",
    },
    {
      id: 67,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSPENCIÓN DE VOTACIÓN EN CON. MUN",
    },
    {
      id: 68,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "DETERMINA EL RESUL. DE DIP. MAY. REL.",
    },
    {
      id: 69,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SE FACULTA LGFM PARA DAR A CONOCER LOS PREP",
    },
    {
      id: 70,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PORCENTAJES FINANCIAMIENTO",
    },
    {
      id: 71,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "RETIRO DE PROPAGANDA",
    },
    {
      id: 72,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "SUSTITUCIÓN PT POR DEFUNCIÓN",
    },
    {
      id: 73,
      typeDoc: "ACUERDO",
      monthDoc: "NOV",
      nameDoc: "PROYECTO DE PRESUPUESTO 1999",
    },
    {
      id: 74,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE ACUAMANAL DE MIGUEL HIDALGO",
    },
    {
      id: 75,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE SAN LORENZO AXOCOMANITLA",
    },
    {
      id: 76,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE TEPEYANCO",
    },
    {
      id: 77,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE TETLATLAHUCA",
    },
    {
      id: 78,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE TOTOLAC",
    },
    {
      id: 79,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "AYUNTAMIENTO DE ZACATELCO",
    },
    {
      id: 80,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "CALIFICACIÓN PMA 15-12-98",
    },
    {
      id: 81,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "SE DEFINE PROCEDIMIENTO PARA CALIFICACIÓN",
    },
    {
      id: 82,
      typeDoc: "ACUERDO",
      monthDoc: "DIC",
      nameDoc: "DE LA INTEGRACIÓN DE DIPUTADOS",
    },
  ],
};

export const dataAcuerdos2002 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "CALIFICACIÓN SAN MIGUEL ANALCO DE NATIVITAS",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "CALIFICACIÓN XAXALA DE CHIAUTEMPAN",
  },
  {
    id: 3,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "INTEGRACIÓN DE DIPUTADOS",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "DE PRESUPUESTO ELECCIÓN EXTRAORDINARIA 2002",
  },
  {
    id: 5,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "CALENDARIO ELECCIÓN EXTRAORDINARIA",
  },
  {
    id: 6,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "CONVOCATORIA PRESIDENTES, SECRETARIOS, AUXILIARES Y CAPACITADORES ELECCIONES EXTRAORDINARIAS",
  },
  {
    id: 7,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "CRITERIOS DE INTEGRACIÓN Y DESIGNACIÓN DE CONSEJOS DISTRITALES Y MUNICIPALES",
  },
  {
    id: 8,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "CALENDARIO PROCESO EXTRAORDINARIAS 2002",
  },
  {
    id: 9,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "MES BASE ELECCIONES EXTRAORDINARIAS",
  },
  {
    id: 10,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "PLATAFORMAS ELECTORALES 2002",
  },
  {
    id: 11,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "REGISTRO DE COALICIÓN IXTENCO PJS, PAS Y PT",
  },
  {
    id: 12,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "REGISTRO DE COALICIÓN ZACATELCO PAS, PCDT Y PJS",
  },
  {
    id: 13,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "ACUERDOS TOPES DE CAMPAÑA ELECCIONES EXTRAORDINARIAS",
  },
  {
    id: 14,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "PADRÓN ELECTORAL ELECCIONES EXTRAORDINARIAS 2002",
  },
  {
    id: 15,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "DISEÑO DE LA DOCUMENTACIÓN Y MATERIAL ELECTORAL 2002",
  },
  {
    id: 16,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "LUGARES DE USO COMÚN 2002",
  },
  {
    id: 17,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "PTES, SRIOS Y CONCEJALES ELECCIONES EXTRAORDINARIAS 2002",
  },
  {
    id: 18,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "REGISTRO AYUNTAMIENTOS",
  },
  {
    id: 19,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "REGISTRO PRESIDENTES DE COMUNIDAD",
  },
  {
    id: 20,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "OBSERVADORES ELECTORALES Y CONVOCATORIA",
  },
  {
    id: 21,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "PRODUCCIÓN DOCUMENTACIÓN Y MATERIAL ELECTORAL 2002",
  },
  {
    id: 22,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "CONVOCATORIA OBSERVADORES ELECTORALES 2002",
  },
  {
    id: 23,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "REASIGNACIÓN DE PRERROGATIVAS 2002 BUENO",
  },
  {
    id: 24,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "ACREDITACIÓN OBSERVADORES ELECTORALES 2002",
  },
  {
    id: 25,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "EXCLUSIÓN DE BOLETAS ELECTORALES DE LA VENTA MUNICIPIO DE CALPULALPAN",
  },
  {
    id: 26,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "RETIRO DE PROPAGANDA MDC 2002",
  },
  {
    id: 27,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SARJE 7 DE ABRIL 2002",
  },
  {
    id: 28,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CALIFICACIÓN 9 PRESIDENCIAS DE COMUNIDAD",
  },
  {
    id: 29,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CALIFICACIÓN AYUNTAMIENTO IXTENCO",
  },
  {
    id: 30,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CALIFICACIÓN AYUNTAMIENTO ZACATELCO",
  },
  {
    id: 31,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "APROBACIÓN DE DICTÁMENES P.P. 2001",
  },
  {
    id: 32,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CALENDARIO DE SESIONES ORDINARIAS 2002",
  },
  {
    id: 33,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "TRANSFERENCIA DE PARTIDA DESTINADA AL 20 % 2002",
  },
  {
    id: 34,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "DESTRUCCIÓN DE PAQUETERIA 2001 Y 2002",
  },
  {
    id: 35,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "ACREDITACIÓN PARTIDO LIBERAL PROGRESISTA",
  },
  {
    id: 36,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "CONVOCATORIA DIRECCIÓN DE CAPACITACIÓN, ORGANIZACIÓN Y JURÍDICA",
  },
];

export const dataAA2002 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "DICTAMEN FINAL SANCIÓN PARTIDOS POLÍTICOS",
    link: "a.pdf",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "DESTRUCCIÓN DE PAQUETERIA 2001 Y 2002",
    link: "b.pdf",
  },
];

export const dataAcuerdos2001 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    nameDoc: "CALENDARIO ELECTORAL 2001",
    link: "1.pdf",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    nameDoc: "REGISTRO DE DIPUTADOS",
    link: "2.pdf",
  },
  {
    id: 3,
    typeDoc: "ACUERDO",
    nameDoc: "INTEGRACIÓN CONSEJOS MUNICIPALES",
    link: "3.pdf",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    nameDoc: "CALIFICACION DE DIPUTADOS",
    link: "4.pdf",
  },
];

export const dataAA12001 = [
  {
    id: "1a",
    nameMunicipio: "ACUAMANALA DE MIGUEL HIDALGO",
    link: "1a.pdf",
  },
  {
    id: 2,
    nameMunicipio: "ALTZAYANCA",
    link: "1b.pdf",
  },
  {
    id: 3,
    nameMunicipio: "AMAXAC DE GUERRERO",
    link: "1c.pdf",
  },
  {
    id: 4,
    nameMunicipio: "APETATITLÁN DE ANTONIO CARVAJAL",
    link: "1d.pdf",
  },
  {
    id: 5,
    nameMunicipio: "APIZACO",
    link: "1e.pdf",
  },
  {
    id: 6,
    nameMunicipio: "ATLANGATEPEC",
    link: "1f.pdf",
  },
  {
    id: 7,
    nameMunicipio: "BENITO JUÁREZ",
    link: "1g.pdf",
  },
  {
    id: 8,
    nameMunicipio: "CALPULALPAN",
    link: "1h.pdf",
  },
  {
    id: 9,
    nameMunicipio: "EL CARMEN TEQUEXQUITLA TET",
    link: "1i.pdf",
  },
  {
    id: 10,
    nameMunicipio: "EMILIANO ZAPATA",
    link: "1j.pdf",
  },
  {
    id: 11,
    nameMunicipio: "ESPAÑITA",
    link: "1k.pdf",
  },
  {
    id: 12,
    nameMunicipio: "HUAMANTLA",
    link: "1l.pdf",
  },
  {
    id: 13,
    nameMunicipio: "HUEYOTLIPAN",
    link: "1m.pdf",
  },
  {
    id: 14,
    nameMunicipio: "IXTACUIXTLA DE MARIANO MATAMOROS",
    link: "1n.pdf",
  },
  {
    id: 15,
    nameMunicipio: "LA MAGDALENA TLALTELULCO",
    link: "1o.pdf",
  },
  {
    id: 16,
    nameMunicipio: "LÁZARO CÁRDENAS",
    link: "1p.pdf",
  },
  {
    id: 17,
    nameMunicipio: "MAZATECOCHCO DE JOSÉ MARÍA MORELOS",
    link: "1q.pdf",
  },
  {
    id: 18,
    nameMunicipio: "NANACAMILPA DE MARIANO ARISTA",
    link: "1r.pdf",
  },
  {
    id: 19,
    nameMunicipio: "NATIVITAS",
    link: "1s.pdf",
  },
  {
    id: 20,
    nameMunicipio: "PAPALOTLA DE XICOHTÉNCATL",
    link: "1t.pdf",
  },
  {
    id: 21,
    nameMunicipio: "SAN DAMIÁN TEXOLOC",
    link: "1u.pdf",
  },
  {
    id: 22,
    nameMunicipio: "SAN FRANCISCO TETLANOHCAN",
    link: "1v.pdf",
  },
  {
    id: 23,
    nameMunicipio: "SAN JERÓNIMO ZACUALPAN",
    link: "1w.pdf",
  },
  {
    id: 24,
    nameMunicipio: "SAN JOSÉ TEACALCO",
    link: "1x.pdf",
  },
  {
    id: 25,
    nameMunicipio: "SAN JUAN HUACTZINCO",
    link: "1y.pdf",
  },
  {
    id: 26,
    nameMunicipio: "SAN LORENZO AXOCOMANITLA",
    link: "1z.pdf",
  },
  {
    id: 27,
    nameMunicipio: "SAN LUCAS TECOPILCO",
    link: "1.pdf",
  },
  {
    id: 28,
    nameMunicipio: "SAN LUIS TEOLOCHOLCO",
    link: "1aa.pdf",
  },
  {
    id: 29,
    nameMunicipio: "SANCTÓRUM",
    link: "1bb.pdf",
  },
  {
    id: 30,
    nameMunicipio: "SANTA ANA NOPALUCAN",
    link: "1cc.pdf",
  },
  {
    id: 31,
    nameMunicipio: "SANTA CATARINA AYOMETLA",
    link: "1dd.pdf",
  },
  {
    id: 32,
    nameMunicipio: "SANTA CRUZ QUILEHTLA",
    link: "1ee.pdf",
  },
  {
    id: 33,
    nameMunicipio: "SANTA CRUZ TLAXCALA",
    link: "1ff.pdf",
  },
  {
    id: 34,
    nameMunicipio: "TENANCINGO",
    link: "1gg.pdf",
  },
  {
    id: 35,
    nameMunicipio: "TEPETITLA DE LARDIZÁBAL",
    link: "1hh.pdf",
  },
  {
    id: 36,
    nameMunicipio: "TEPEYANCO",
    link: "1ii.pdf",
  },
  {
    id: 37,
    nameMunicipio: "TERRENATE",
    link: "1jj.pdf",
  },
  {
    id: 38,
    nameMunicipio: "TETLA DE LA SOLIDARIDAD",
    link: "1kk.pdf",
  },
  {
    id: 39,
    nameMunicipio: "TETLATLAHUCA",
    link: "1ll.pdf",
  },
  {
    id: 40,
    nameMunicipio: "TLAXCO",
    link: "1mm.pdf",
  },
  {
    id: 41,
    nameMunicipio: "TOCATLÁN",
    link: "1nn.pdf",
  },
  {
    id: 42,
    nameMunicipio: "TOTOLAC",
    link: "1oo.pdf",
  },
  {
    id: 43,
    nameMunicipio: "TZOMPANTEPEC",
    link: "1pp.pdf",
  },
  {
    id: 44,
    nameMunicipio: "XALOZTOC TET",
    link: "1qq.pdf",
  },
  {
    id: 45,
    nameMunicipio: "XICOHTZINCO",
    link: "1rr.pdf",
  },
  {
    id: 46,
    nameMunicipio: "ZITLALTEPEC DE TRINIDAD SÁNCHEZ SANTOS",
    link: "1ss.pdf",
  },
];

export const dataAA22001 = [
  {
    id: 1,
    nameDoc:
      "SE MODIFICA EL PUNTO DE ACUERDO VIGÉSIMO SEXTO DEL ACUERDO DEL CG POR EL QUE SE CALIFICA LA ELECCIÓN DE 253 PRESIDENTES MUNICIPALES",
    link: "2a.pdf",
  },
  {
    id: 2,
    nameDoc: "ACUERDO PMA",
    link: "2b.pdf",
  },
  {
    id: 3,
    nameDoc: "ACUERDO PMA",
    link: "2c.pdf",
  },
  {
    id: 4,
    nameDoc: "ALTZAYANCA TET",
    link: "2d.pdf",
  },
  {
    id: 5,
    nameDoc: "CHIAUTEMPAN TET SIN RESOLVER",
    link: "2e.pdf",
  },
  {
    id: 6,
    nameDoc: "CONTLA DE JUAN CUAMATZI TET SIN RESOLVER",
    link: "2f.pdf",
  },
  {
    id: 7,
    nameDoc: "CUAPIAXTLA TET SIN RESOLVER",
    link: "2g.pdf",
  },
  {
    id: 8,
    nameDoc: "CUAXOMULCO TET SIN RESOLVER",
    link: "2h.pdf",
  },
  {
    id: 9,
    nameDoc: "EL CARMEN TEQUEXQUITLA TET",
    link: "2i.pdf",
  },
  {
    id: 10,
    nameDoc: "MUÑOZ DE DOMINGO ARENAS TET SIN RESOLVER",
    link: "2j.pdf",
  },
  {
    id: 11,
    nameDoc: "PANOTLA",
    link: "2k.pdf",
  },
  {
    id: 12,
    nameDoc: "SAN PABLO DEL MONTE TET SIN RESOLVER",
    link: "2l.pdf",
  },
  {
    id: 13,
    nameDoc: "SANTA APOLONIA TEACALCO TET SIN RESOLVER",
    link: "2m.pdf",
  },
  {
    id: 14,
    nameDoc: "SANTA ISABEL XILOXOXTLA TET SIN RESOLVER",
    link: "2n.pdf",
  },
  {
    id: 15,
    nameDoc: "TLAXCALA TET SIN RESOLVER",
    link: "2o.pdf",
  },
  {
    id: 16,
    nameDoc: "XALOZTOC TET",
    link: "2p.pdf",
  },
  {
    id: 17,
    nameDoc: "XALTOCAN TET SIN RESOLVER",
    link: "2q.pdf",
  },
  {
    id: 18,
    nameDoc: "YAUHQUEMECAN TET SIN RESOLVER",
    link: "2r.pdf",
  },
  {
    id: 19,
    nameDoc: "ZACATELCO TET SIN RESOLVER",
    link: "2s.pdf",
  },
];
